import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat7 from "../../images/EdMaterial/EdMat7.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic7() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 7;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Dehydration? Better safe than sorry! Adequate hydration is
                      the basis of our body's wellbeing, in fact water nourishes
                      and quenches our thirst, but despite this it is an often
                      neglected component of our diet. In all conditions, one of
                      the main needs of our body is a proper hydration and this
                      is even more true when it is very hot, such as in summer,
                      if you do physical activity or when you are sick. In
                      addition, water is also very important because it is rich
                      in mineral salts that help to meet our daily needs, for
                      example calcium, magnesium, potassium, phosphorus, ...
                    </p>
                    <p>
                      The advice is to drink about 8 glasses of water every day
                      on average, for a total of 2 litres, corresponding to the
                      minimum recommended dose to meet our body's needs. A small
                      trick that can help is getting used to drink small sips
                      during the day even when you do not feel the thirst
                      stimulus, which in fact always arrives late with respect
                      to our body's needs. A glass of water can also be an
                      excellent solution to take a short break during the day.
                      Furthermore, it is important to remember that even food is
                      able to hydrate us... water is not the only resource at
                      our disposal. In fact, the total balance of hydration
                      sources is not only made up of the well-known 8 glasses of
                      water but also of what we eat every day. Fruits and
                      vegetables in particular are the most water- rich foods,
                      but you can also find water in legumes, milk and dairy,
                      cooked cereals, meat, fish and eggs.
                    </p>
                  </p>
                  <img
                    src={EdMat7}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Water is always the best choice... but occasionally you
                      can also drink a freshly home-made fruit juice, an herbal
                      tea or a fruit infusion, the important thing is not to add
                      sugar! On the other hand, packaged fruit juices are rich
                      in sugar, as well as other drinks, such as coke,
                      orangeade, fruit syrups and energy drinks, which you
                      should drink only from time to time.
                    </p>
                    <p>Water, practical tips:</p>
                    <ol>
                      <li>Always bring water with you when you go out!</li>
                      <li>
                        The best choice among the waters is natural water, i.e.,
                        the water that also flows in most taps of our homes.
                      </li>
                      <li>
                        Always indulge your thirst... or rather, anticipate it!
                      </li>
                      <li>
                        Drink frequently, in small quantities, and slowly!
                      </li>
                      <li>
                        Eat big salads and vegetable soups! Fruit and vegetables
                        are rich in water and help to satisfy water
                        requirements.
                      </li>
                      <li>
                        Try waters flavored with fresh fruit and aromatic herbs;
                        they are a good alternative to natural water, especially
                        for those people who have trouble drinking water
                        regularly throughout the day. They can easily be
                        prepared at home by infusing in 1 liter of water
                        carefully washed fresh fruit and vegetables and spices
                        all night long.
                      </li>
                      <li>
                        Avoid carbonated and sugary drinks, including bottled
                        tea and fruit juices that contain lots of sugar. On the
                        other hand, herbal teas, infusions, coffee, and barley
                        coffee are welcome.
                      </li>
                    </ol>
                  </p>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      ¿Deshidratación? ¡Más vale prevenir que curar! Una
                      hidratación adecuada es la base del bienestar de nuestro
                      organismo, de hecho, el agua nutre y calma nuestra sed,
                      pero a pesar de ello es un componente a menudo descuidado
                      de nuestra dieta. En cualquier contexto, una de las
                      principales necesidades de nuestro cuerpo es una adecuada
                      hidratación y esto es aún más cierto cuando hace mucho
                      calor, como en verano, si realizas actividad física o
                      cuando estás enfermo. Además, el agua también es muy
                      importante porque es rica en sales minerales que ayudan a
                      cubrir nuestras necesidades diarias, por ejemplo, calcio,
                      magnesio, potasio, fósforo, …
                    </p>
                    <p>
                      El consejo es beber una media de unos 8 vasos de agua al
                      día, para llegar a un total de 2 litros, correspondiente a
                      la dosis mínima recomendada para cubrir las necesidades de
                      nuestro organismo. Un pequeño truco que puede ayudar es
                      acostumbrarse a beber pequeños sorbos durante el día
                      incluso cuando no se siente el estímulo de la sed, que de
                      hecho siempre llega tarde con respecto a las necesidades
                      de nuestro organismo. Un vaso de agua también puede ser
                      una excelente solución para hacer un pequeño descanso
                      durante el día. Además, es importante recordar que incluso
                      los alimentos son capaces de hidratarnos... el agua no es
                      el único recurso a nuestra disposición. De hecho, el
                      balance total de fuentes de hidratación no sólo lo
                      componen los conocidos 8 vasos de agua sino también lo que
                      comemos cada día. Las frutas y verduras en particular son
                      los alimentos más ricos en agua, pero también puedes
                      encontrar agua en las legumbres, la leche y lácteos,
                      cereales cocinados, carne, pescado y huevos.
                    </p>
                  </p>
                  <img
                    src={EdMat7}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      El agua siempre es la mejor opción… pero de vez en cuando
                      también puedes beber un zumo de frutas recién hecho en
                      casa, una infusión o una infusión de frutas, ¡En todo
                      caso, lo importante es no añadir azúcar! Por otro lado,
                      los zumos de frutas envasados son ricos en azúcar, al
                      igual que otras bebidas, como la coca cola, la naranjada,
                      los siropes de frutas y las bebidas energéticas, que
                      conviene beber sólo de vez en cuando.
                    </p>
                    <p>
                      En resumen, aquí tienes algunos consejos en relación con
                      el agua y la hidratación:
                    </p>
                    <ol>
                      <li>¡Lleva siempre agua contigo cuando salgas!</li>
                      <li>
                        La mejor elección entre las aguas es el agua natural, es
                        decir, el agua que también fluye por la mayoría de los
                        grifos de nuestros hogares.
                      </li>
                      <li>
                        Satisface siempre su sed... o, mejor dicho, ¡anticípala!
                      </li>
                      <li>
                        ¡Bebe con frecuencia, en pequeñas cantidades y
                        lentamente!
                      </li>
                      <li>
                        ¡Come grandes ensaladas y sopas de verduras! Las frutas
                        y verduras son ricas en agua y ayudan a satisfacer las
                        necesidades de hidratación.
                      </li>
                      <li>
                        Prueba aguas aromatizadas con fruta fresca y hierbas
                        aromáticas, son una buena alternativa al agua natural,
                        especialmente para aquellas personas que tienen
                        problemas para beber agua de forma habitual a lo largo
                        del día. Se pueden preparar fácilmente en casa
                        infusionando en 1 litro de agua frutas y verduras
                        frescas, cuidadosamente lavadas, y especias durante toda
                        la noche.
                      </li>
                      <li>
                        Evita las bebidas carbonatadas y azucaradas, incluido el
                        té embotellado y los zumos de frutas que contienen mucho
                        azúcar. Por otro lado, son bienvenidas los tes,
                        infusiones, café y sucedáneos.
                      </li>
                    </ol>
                  </p>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Dehidrasyon? Eşeği sağlam kazığa bağlamak! Yeterli sıvı
                      alımı vücudumuzun sağlığının temelidir, aslında su
                      susuzluğumuzu besler ve giderir ancak buna rağmen
                      beslenmemizin sıklıkla ihmal edilen bir bileşenidir. Her
                      koşulda vücudumuzun temel ihtiyaçlarından biri uygun bir
                      sıvı alımıdır ve bu durum yazın olduğu gibi havanın çok
                      sıcak olduğu zamanlarda, fiziksel aktivite yaptığınızda
                      veya hasta olduğunuzda daha da geçerlidir. Ayrıca su,
                      kalsiyum, magnezyum, potasyum, fosfor gibi günlük
                      ihtiyacımızı karşılamaya yardımcı olan mineral tuzlar
                      açısından da zengin olduğu için oldukça önemlidir. Kişiden
                      kişiye değişkenlik gösterse de bir yetişkin için tavsiye
                      edilen, her gün ortalama 8 bardak kadar su içilmesidir.
                    </p>
                    <p>
                      Yardımcı olabilecek küçük bir bilgi: aslında vücudumuzun
                      ihtiyaçlarına göre her zaman geç gelen susama uyarısını
                      hissetmeseniz bile gün içinde küçük yudumlarla içmeye
                      alışabilirsiniz. Bir bardak su da gün içinde kısa bir mola
                      vermek için mükemmel bir çözüm olabilir. Dahası, gıdaların
                      da su içerdiğini unutmamak önemlidir. Elimizdeki tek
                      kaynak su değildir. Aslında sıvı alımı kaynaklarının
                      toplam dengesi sadece bilinen 8 bardak sudan değil aynı
                      zamanda her gün yediğimiz yiyeceklerden de oluşur.
                      Özellikle meyve ve sebzeler su içeriği en zengin
                      besinlerdir ancak baklagiller, süt ve süt ürünleri, pişmiş
                      tahıllar, et, balık ve yumurtada da su bulabilirsiniz.
                    </p>
                  </p>
                  <img
                    src={EdMat7}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Su her zaman en iyi seçimdir ama ara sıra taze ev yapımı
                      meyve suyu, bitki çayı veya meyve karışımı da
                      içebilirsiniz, önemli olan şeker eklememek! Öte yandan,
                      kola, portakal suyu, meyve şurupları, enerji içecekleri
                      gibi uzak durmanız gereken içeceklerin yanı sıra,
                      paketlenmiş meyve suları da şeker açısından zengindir.
                    </p>
                    <p>Su için pratik ipuçları:</p>
                    <ol>
                      <li>Dışarı çıkarken mutlaka yanınızda su taşıyın!</li>
                      <li>
                        Sular arasında en iyi seçim doğal sudur, yani eğer
                        içilebiliyorsa evlerimizin çoğu musluğundan da akan
                        sudur.
                      </li>
                      <li>
                        Her zaman susuzluğunuzu giderin daha doğrusu, onu
                        önceden tahmin edin!
                      </li>
                      <li>Sık sık, küçük miktarlarda ve yavaş yavaş için!</li>
                      <li>
                        Büyük salatalar ve sebze çorbaları yiyin! Meyve ve
                        sebzeler su açısından zengindir ve su alımına yardımcı
                        olur.
                      </li>
                      <li>
                        Taze meyve ve aromatik bitkilerle tatlandırılmış suları
                        deneyin, özellikle gün boyu düzenli su içmekte zorluk
                        çeken kişiler için doğal suya iyi bir alternatiftir. 1
                        litre suya özenle yıkanmış taze meyve ve sebzeler ile
                        baharatların gece boyunca demlenmesiyle evde kolaylıkla
                        hazırlanabilir.
                      </li>
                      <li>
                        Çok fazla şeker içeren şişelenmiş çay ve meyve suları da
                        dahil olmak üzere gazlı ve şekerli içeceklerden kaçının.
                        Öte yandan bitki çayları ve kahve de kabul edilir.
                      </li>
                    </ol>
                  </p>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic7;
