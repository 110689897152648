import React, { useEffect, useState } from "react";
import logo from "../images/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { Alert } from "react-bootstrap";
import Footer from "../components/footer.js";
import Footer2 from "../components/footer2.js";
import "./netflixPage.css";
import i18next from "i18next";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

import userProfileImg1 from "../images/userProfile/default.png";
import userProfileImg2 from "../images/userProfile/cat.png";
import userProfileImg3 from "../images/userProfile/dog.png";
import userProfileImg4 from "../images/userProfile/sheep.png";
import userProfileImg5 from "../images/userProfile/bear.png";
import userProfileImg6 from "../images/userProfile/wolf.png";
import userProfileImg7 from "../images/userProfile/tiger.png";
import userProfileImg8 from "../images/userProfile/frog.png";
import userProfileImg9 from "../images/userProfile/pig.png";

// import UserProfileSelection from "./profileSelection.js"; // Import the UserProfileSelection component

function NetflixPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [userId, setUserId] = useState(null);
  // const [authTokens, setAuthTokens] = useState(null);
  // const [user, setUser] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [adults, setAdults] = useState();
  const [children, setChildren] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  const imageMap = {
    apim1: userProfileImg1,
    apim2: userProfileImg2,
    apim3: userProfileImg3,
    apim4: userProfileImg4,
    apim5: userProfileImg5,
    apim6: userProfileImg6,
    apim7: userProfileImg7,
    apim8: userProfileImg8,
    apim9: userProfileImg9,
  };

  const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-globe-europe-africa"
      viewBox="0 0 16 16"
    >
      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z" />
    </svg>
  );

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },

    {
      code: "fr",
      name: "Français",
      country_code: "fr",
    },
    {
      code: "es",
      name: "Español",
      country_code: "es",
    },

    {
      code: "tr",
      name: "Türkçe",
      country_code: "tr",
    },
  ];

  useEffect(() => {
    if (user == null || user.is_active == false) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (user == null || user.is_active == false) {
      navigate("/");
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(user.user_id + "/profiles");
          // console.log(response.data.adults);
          // console.log(response.data.children);
          setAdults(response.data.adults);
          setChildren(response.data.children);
        } catch {}
      };
      fetchData();
    }
  }, []);

  const Logout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`blacklist_token`, {
        authTokens,
      });
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      localStorage.removeItem("adult");
      localStorage.removeItem("child");
      navigate("/");
    } catch (error) {
      // console.error(error);
    }
  };

  const handleAdultSelect = (adultId) => {
    // console.log(`Selected profile ID: ${adultId}`);
    localStorage.setItem("adult", adultId);
    const action = axios.post("userProfileActions", {
      userid: adultId,
      action: "Home",
    });
    navigate("/home");
  };

  const handleChildSelect = (childId) => {
    // console.log(`Selected profile ID: ${childId}`);
    localStorage.setItem("child", childId);
    navigate("/childhome");
  };

  const handleAddAdult = () => {
    // console.log(adults.length);
    // console.log(children.length);
    // if (adults.length >= 2 && children.length >= 8) {
    //   setMessage(t("max_adults_children_message"));
    //   setTimeout(() => {
    //     setMessage("");
    //   }, 5000);
    // } else if (adults.length < 2 || children.length < 8) {
    //   navigate("/adultsForm");
    //   // setIsModalOpen(true);
    // }

    if (adults.length >= 1) {
      setMessage(t("adults_message"));
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } else {
      navigate("/form");
      // setIsModalOpen(true);
    }
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [pin, setPin] = useState("");

  // const handlePinChange = (e) => {
  //   const enteredPin = e.target.value.replace(/\D/, ""); // Allowing only digits
  //   setPin(enteredPin.slice(0, 4)); // Allowing only up to 4 digits
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="back-color"
    >
      <div className="App">
        <header className="App-header">
          {message && <Alert variant="danger">{message}</Alert>}
          <div className="image-container">
            <img src={logo} alt="logo" />
          </div>
          <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            className="countries-dropdown"
          >
            <button
              className="btn btn-link dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => setIsOpen(!isOpen)}
            >
              <GlobeIcon />
            </button>
            <motion.ul
              variants={{
                open: {
                  clipPath: "inset(0% 0% 0% 0% round 10px)",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.7,
                    delayChildren: 0.3,
                    staggerChildren: 0.05,
                  },
                },
                closed: {
                  clipPath: "inset(10% 50% 90% 50% round 10px)",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.3,
                  },
                },
              }}
              style={{ pointerEvents: isOpen ? "auto" : "none" }}
              className="dropdown-menu"
            >
              {languages.map(({ code, name, country_code }) => (
                <motion.li variants={itemVariants} key={country_code}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      i18next.changeLanguage(code);
                      setIsOpen(false);
                    }}
                  >
                    <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                    ></span>

                    {name}
                  </button>
                </motion.li>
              ))}
            </motion.ul>
          </motion.div>
          <div className="user-profile-selection">
            <p>{t("select_profile")}</p>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 1,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                  type: "spring",
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
              className="profile-icons"
            >
              {adults &&
                adults.map((adult) => (
                  <motion.div
                    key={adults.id}
                    className="profile-icon"
                    onClick={() => handleAdultSelect(adult.id, adult)}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <img
                      src={imageMap[adult.image]}
                      alt={`Profile ${adult.username}`}
                      className="profile-icon img"
                    />
                    <div>{adult.username}</div>
                  </motion.div>
                ))}
              {children &&
                children.map((child) => (
                  <motion.div
                    key={child.id}
                    className="profile-icon"
                    onClick={() => handleChildSelect(child.id)}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <img
                      src={imageMap[child.image]}
                      alt={`Profile ${child.username}`}
                      className="profile-icon img"
                    />
                    <div>{child.username}</div>
                  </motion.div>
                ))}

              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                onClick={() => handleAddAdult()}
              >
                <div className="add-profile-adult-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div>{t("add_user")}</div>
              </motion.div>

              {/* <motion.div
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              <div className="add-profile-child-icon">
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div>Add child</div>
            </motion.div> */}
            </motion.div>
          </div>
          {/* <div className="manage-profiles">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate("/manageProfiles");
              }}
            >
              {t("manage_profiles")}
            </Button>
          </div> */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div>
            <Button
              variant="contained"
              color="error"
              startIcon={<LogoutIcon />}
              onClick={Logout}
            >
              {t("Logout")}
            </Button>
          </div>
        </header>
        {/* {isModalOpen && <Modal closeModal={setIsModalOpen} />} */}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="footer-fixed">{isMobile ? <Footer2 /> : <Footer />}</div>
    </motion.div>
  );
}

export default NetflixPage;
