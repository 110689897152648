import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";
import { motion } from "framer-motion";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "./material.css";
import Footer from "../components/footer";
import Topic1Image from "../images/EdMaterial/Topic1.jpg";
import Topic3Image from "../images/EdMaterial/Topic3.jpg";
import Topic6Image from "../images/EdMaterial/Topic6.png";
import Topic8Image from "../images/EdMaterial/Topic8.png";
import Topic9Image from "../images/EdMaterial/Topic9.png";
import { useNavigate } from "react-router-dom";
import LogoutAfterInactivity from "../components/logoutAfterInactivity";

import { Card } from "/home/ubuntu/s2h/s2h_seventh_test/frontend/src/components/Card";

import { useTranslation } from "react-i18next";

function MaterialPage() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  // const handleAction = async (actionName) => {
  //   try {
  //     const response = await axios.post("userProfileActions", {
  //       userid: userProfileId,
  //       action: actionName,
  //     });
  //     // console.log("Action successfully sent:", response.data);
  //   } catch (error) {
  //     // console.error("Error in handleAction:", error);
  //   }
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, ease: "easeOut" }}
      className="back-color"
    >
      <SideBar />
      <div className="material-page">
        <div className="title-font" style={{ textAlign: "center" }}>
          {t("Educational_Material_Text")}
        </div>
        <div className="card-page">
          <div className="row">
            <div className="col">
              <div className="col11">
                <Card
                  title={t("Topic0Title")}
                  subtitle={t("Topic0Subtitle")}
                  description={t("Topic0Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic0"
                />
                <Card
                  imgSrc={Topic1Image}
                  imgAlt="Topic 1"
                  title={t("Topic1Title")}
                  subtitle={t("Topic1Subtitle")}
                  description={t("Topic1Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic1"
                />
                <Card
                  title={t("Topic2Title")}
                  subtitle={t("Topic2Subtitle")}
                  description={t("Topic2Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic2"
                />
                <Card
                  imgSrc={Topic3Image}
                  imgAlt="Topic 3"
                  title={t("Topic3Title")}
                  subtitle={t("Topic3Subtitle")}
                  description={t("Topic3Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic3"
                />
                <Card
                  title={t("Topic4Title")}
                  subtitle={t("Topic4Subtitle")}
                  description={t("Topic4Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic4"
                />
                <Card
                  title={t("Topic5Title")}
                  subtitle={t("Topic5Subtitle")}
                  description={t("Topic5Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic5"
                />
              </div>
            </div>
            <div className="col">
              <div className="col11">
                <Card
                  imgSrc={Topic6Image}
                  imgAlt="Topic6"
                  title={t("Topic6Title")}
                  subtitle={t("Topic6Subtitle")}
                  description={t("Topic6Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic6"
                />
                <Card
                  title={t("Topic7Title")}
                  subtitle={t("Topic7Subtitle")}
                  description={t("Topic7Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic7"
                />
                <Card
                  imgSrc={Topic8Image}
                  imgAlt="Topic 8"
                  title={t("Topic8Title")}
                  subtitle={t("Topic8Subtitle")}
                  description={t("Topic8Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic8"
                />
                <Card
                  imgSrc={Topic9Image}
                  imgAlt="Topic 9"
                  title={t("Topic9Title")}
                  subtitle={t("Topic9Subtitle")}
                  description={t("Topic9Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic9"
                />
              </div>
            </div>

            <div className="col">
              <div className="col11">
                <Card
                  title={t("Topic10Title")}
                  subtitle={t("Topic10Subtitle")}
                  description={t("Topic10Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic10"
                />
                <Card
                  title={t("Topic11Title")}
                  subtitle={t("Topic11Subtitle")}
                  description={t("Topic11Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic11"
                />
                <Card
                  title={t("Topic12Title")}
                  subtitle={t("Topic12Subtitle")}
                  description={t("Topic12Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic12"
                />
                <Card
                  title={t("Topic13Title")}
                  subtitle={t("Topic13Subtitle")}
                  description={t("Topic13Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic13"
                />
                <Card
                  title={t("Topic14Title")}
                  subtitle={t("Topic14Subtitle")}
                  description={t("Topic14Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic14"
                />
                <Card
                  title={t("Topic15Title")}
                  subtitle={t("Topic15Subtitle")}
                  description={t("Topic15Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic15"
                />
                <Card
                  title={t("Topic16Title")}
                  subtitle={t("Topic16Subtitle")}
                  description={t("Topic16Description")}
                  userProfileId={userProfileId}
                  buttonText={t("learn_more")}
                  link="Topic16"
                />
              </div>
            </div>
          </div>
        </div>

        <div>{!isMobile && <Footer />} </div>
      </div>
    </motion.div>
  );
}

export default MaterialPage;
