import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat8 from "../../images/EdMaterial/EdMat8.png";
import EdMat8_tr from "../../images/EdMaterial/Topic8_tr.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic8() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 8;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      A healthy and balanced diet is made not only of "right"
                      ingredients but also of correct habits, such as the
                      consumption of balanced and regular meals. The
                      distribution of meals over the day and their consumption
                      at as regular a time as possible is in fact one of the
                      prerequisites for a proper eating behaviour, also helping
                      to reduce the risk of overweight and obesity.
                    </p>
                    <p>
                      An ideal day can be divided into 5 meals: breakfast,
                      lunch, dinner and two snacks, one in the middle of the
                      morning and one in the afternoon.
                    </p>
                    <p>
                      Breakfast is the first meal of the day with which about
                      15-20% of the daily energy requirement must be introduced.
                      In the morning our stomach is empty so before we start the
                      day we have to stock up on energy and nutrients with a
                      rich breakfast, which is one of the main meals. The foods
                      that should always be present at breakfast are milk or
                      yoghurt (sometimes eggs or fresh cheese) fruit or a
                      freshly squeezed fruit juice without added sugar, and a
                      source of carbohydrates (such as bread and cereals). In
                      the main meals of the day, represented by lunch and
                      dinner, we have to introduce most of the daily calories
                      (about 35-40% at lunch and 30-35% at dinner). These two
                      meals, from a nutritional point of view, must be both
                      balanced and complementary, so as to guarantee the daily
                      intake of all the nutrients and in the recommended
                      quantities. You can choose between pasta, rice or other
                      cereals to be served with legumes, fish, eggs or meat and
                      above all never forget vegetables and extra virgin olive
                      oil! In order not to overdo it, you can try to create
                      single dishes such as a good cereal soup, vegetables and
                      legumes or a big salad with rice and fish.
                    </p>
                    <p>
                      Unfortunately, however, it often happens, especially among
                      young people, that the choice of snack is not well
                      planned, leading to the consumption of whatever is
                      available (very energetic and sometimes low nutritional
                      content foods): small pizzas, snacks rich in fat and
                      simple sugars, chocolate, with a consequent lack of
                      appetite at the next meal, alteration of the rhythm of the
                      remaining meals and a greater likelihood of tooth decay.
                    </p>
                    <p>
                      The two snacks, in the morning and in the afternoon, must
                      each provide about 5-10% of the daily energy needs, so it
                      is obvious that they should not be too abundant but are
                      still very important to control the sense of hunger, thus
                      preventing binge eating at the next meal. The snack should
                      be chosen with great care: for example, you can eat a
                      portion of fresh fruit or nuts but also a yoghurt.
                    </p>
                    <p>
                      Therefore, an advice for a well-balanced diet can be to
                      arrange the meals during the day according to the scheme
                      presented in the table below.
                    </p>
                    <p>
                      This is how to divide the daily energy into the five
                      meals!
                    </p>
                  </p>

                  <img
                    src={EdMat8}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      By following these simple rules it is possible to obtain a
                      better distribution of the energy required by our body
                      during the day, with a more efficient use of the
                      introduced nutrients. The suggested subdivision must be
                      "adjusted" for each member of the family, considering age,
                      gender and lifestyle of each one, but above all the level
                      of physical activity, which deeply affects the daily
                      dietary patterns.
                    </p>
                  </p>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Una dieta sana y equilibrada se compone no sólo de
                      ingredientes "correctos" sino también de hábitos
                      correctos, como el consumo de comidas equilibradas y
                      regulares. La distribución de las comidas a lo largo del
                      día y su consumo en el horario más regular posible es, de
                      hecho, uno de los requisitos previos para una conducta
                      alimentaria adecuada, contribuyendo también a reducir el
                      riesgo de sobrepeso y obesidad. Un día ideal se puede
                      dividir en 5 comidas: desayuno, almuerzo, cena y dos
                      snacks, uno a media mañana y otro a media tarde.
                    </p>
                    <p>
                      El desayuno es la primera comida del día con la que se
                      debe aportar alrededor del 15-20% del requerimiento
                      energético diario. Por la mañana nuestro estómago está
                      vacío por lo que antes de empezar el día tenemos que
                      abastecernos de energía y nutrientes con un rico desayuno,
                      que es una de las comidas principales. Los alimentos que
                      siempre deben estar presentes en el desayuno son lácteos
                      como leche o yogur, fruta o un zumo de fruta recién
                      exprimido sin azúcares añadidos, y cereales como pan,
                      cereales de desayuno sin azúcares o bizcochos caseros. En
                      las comidas principales del día, representadas por el
                      almuerzo y la cena, tenemos que introducir la mayor parte
                      de las calorías diarias (un 35-40% en el almuerzo y un
                      30-35% en la cena). Estas dos comidas, desde el punto de
                      vista nutricional, deben ser equilibradas y
                      complementarias, para garantizar la ingesta diaria de
                      todos los nutrientes y en las cantidades recomendadas.
                      Podrás elegir entre pasta, arroz u otros cereales para
                      acompañar con legumbres, pescado, huevos o carne y, sobre
                      todo, ¡nunca te olvides de las verduras y el aceite de
                      oliva virgen extra! Para no pasarte con algunas comidas,
                      puedes intentar crear platos únicos como una buena sopa de
                      cereales, verduras y legumbres o una gran ensalada con
                      arroz y pescado.
                    </p>
                    <p>
                      Los dos snacks, de la mañana y de la tarde, deben aportar,
                      cada uno, alrededor del 5-10% de las necesidades
                      energéticas diarias, por lo que es obvio que no deben ser
                      demasiado abundantes, pero siguen siendo muy importantes
                      para controlar nuestra sensación de hambre, y en
                      consecuencia ayudándonos a evitar los atracones en la
                      siguiente comida. Un snack debe elegirse con mucho
                      cuidado: por ejemplo, se puede comer una ración de fruta
                      fresca o frutos secos, pero también un yogur.
                    </p>
                    <p>
                      Lamentablemente, sin embargo, sucede a menudo,
                      especialmente entre los jóvenes, que la elección de los
                      snacks no está bien planificada, lo que lleva al consumo
                      de cualquier cosa que esté disponible (alimentos muy
                      energéticos y a veces de bajo contenido nutricional):
                      pizzas pequeñas, snacks ricos en grasas y azúcares
                      simples, chocolate - con la consiguiente falta de apetito
                      en la siguiente comida, alteración del ritmo del resto de
                      comidas y mayor probabilidad de sufrir caries.
                    </p>
                    <p>
                      Por tanto, un consejo para una dieta bien equilibrada
                      puede ser organizar las comidas durante el día según el
                      esquema presentado en la siguiente tabla..
                    </p>
                    <p>
                      ¡Así se divide la energía diaria en las cinco comidas!
                    </p>
                  </p>

                  <img
                    src={EdMat8}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Siguiendo estas sencillas reglas es posible obtener una
                      mejor distribución de la energía que requiere nuestro
                      organismo durante el día, con un uso más eficiente de los
                      nutrientes introducidos. La subdivisión sugerida debe
                      "ajustarse" a cada miembro de la familia, considerando la
                      edad, el género y el estilo de vida de cada uno, pero
                      sobre todo el nivel de actividad física, que afecta
                      profundamente los patrones alimentarios diarios.
                    </p>
                  </p>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Sağlıklı ve dengeli bir beslenme yalnızca "doğru"
                      malzemelerden değil, aynı zamanda dengeli ve düzenli yemek
                      tüketimi gibi doğru alışkanlıklardan da oluşur. Öğünlerin
                      güne dağıtılması ve mümkün olduğu kadar düzenli bir saatte
                      tüketilmesi aslında doğru beslenme davranışının ön
                      koşullarından biri olup aşırı kilo ve obezite riskinin
                      azaltılmasına da yardımcı olur.
                    </p>
                    <p>
                      İdeal bir gün kişinin ihtiyacı olan kadar öğüne
                      bölünebilir: kahvaltı, öğle yemeği, akşam yemeği ve biri
                      sabahın ortasında, diğeri öğleden sonra olmak üzere iki
                      atıştırmalık gibi.
                    </p>
                    <p>
                      Kahvaltı, günlük enerji ihtiyacının yaklaşık %15-20'sinin
                      karşılanması gereken günün ilk öğünüdür. Sabahları midemiz
                      boş olduğundan, güne başlamadan önce ana öğünlerden biri
                      olan zengin bir kahvaltıyla enerji ve besin depolamalıyız.
                      Kahvaltıda mutlaka bulunması gereken besinler; süt veya
                      yoğurt (bazen yumurta veya taze peynir), meyve veya ilave
                      şeker içermeyen taze sıkılmış meyve suyu ve bir
                      karbonhidrat kaynağı (ekmek ve tahıllar gibi). Öğle ve
                      akşam yemeklerinden oluşan günün ana öğünlerinde günlük
                      kalorinin çoğunu (yaklaşık %35-40'ı öğle yemeğinde ve
                      %30-35'i akşam yemeğinde) vermemiz gerekir. Beslenme
                      açısından bu iki öğün, tüm besin öğelerinin günlük olarak
                      ve önerilen miktarlarda alınmasını garanti edecek şekilde
                      hem dengeli hem de tamamlayıcı olmalıdır. Baklagiller,
                      balık, yumurta veya et ile servis edilecek makarna, pirinç
                      veya diğer tahıllar arasında seçim yapabilirsiniz ve her
                      şeyden önce sebzeleri ve sızma zeytinyağını asla
                      unutmayın! Aşırıya kaçmamak için iyi bir tahıl çorbası,
                      sebze ve baklagiller ya da pilav ve balıktan oluşan büyük
                      bir salata gibi tek yemekler hazırlamayı deneyebilirsiniz
                    </p>
                    <p>
                      Sabah ve öğleden sonraki iki ara öğünün her biri günlük
                      enerji ihtiyacının yaklaşık %5-10'unu sağlamalıdır bu
                      nedenle çok fazla olmaması gerektiği açıktır ancak yine de
                      açlık hissini kontrol etmek için çok önemlidir. Bir
                      sonraki öğünde aşırı yemek yemeyi önler. Atıştırmalık çok
                      dikkatli seçilmelidir: örneğin bir porsiyon taze meyve
                      veya kuruyemişin yanında yoğurt da yiyebilirsiniz.
                    </p>
                    <p>
                      Ne yazık ki, özellikle gençler arasında, atıştırmalık
                      seçiminin iyi planlanmadığı ve mevcut olan her şeyin (çok
                      enerjik ve bazen düşük besin içeriğine sahip yiyecekler)
                      tüketilmesine yol açtığı sıklıkla görülür: küçük pizzalar,
                      yağ açısından zengin atıştırmalıklar ve basit şekerler,
                      çikolata, bunun sonucunda bir sonraki öğünde iştahsızlık,
                      kalan öğünlerin ritminin değişmesi ve diş çürümesi
                      olasılığının artması olası sonuçlardır.
                    </p>
                    <p>
                      Bu nedenle dengeli beslenme için bir tavsiye gün içindeki
                      öğünlerin aşağıdaki tabloda sunulan şemaya göre
                      düzenlenmesi olabilir.
                    </p>
                    <p>Günlük enerjiyi beş öğüne bu şekilde bölebilirsiniz!</p>
                  </p>

                  <img
                    src={EdMat8_tr}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Bu basit kurallara uyarak vücudumuzun gün içinde ihtiyaç
                      duyduğu enerjinin daha iyi dağılımını ve alınan besinlerin
                      daha verimli kullanılmasını sağlamak mümkündür. Önerilen
                      alt bölümleme, her birinin yaşı, cinsiyeti ve yaşam tarzı
                      dikkate alınarak, ancak her şeyden önce günlük beslenme
                      düzenini derinden etkileyen fiziksel aktivite düzeyi
                      dikkate alınarak ailenin her üyesi için "ayarlanmalıdır".
                    </p>
                  </p>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic8;
