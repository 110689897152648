import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import image3 from "../../images/EdMaterial/Topic3.jpg";
import image3_tr from "../../images/EdMaterial/Topic3_tr.jpg";
import imageEdMat3_1 from "../../images/EdMaterial/EdMat3_1.png";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic3() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  const topicNumber = 3; // Define the page number as a constant

  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };

  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };

  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Everybody can do something! As consumers and family
                      members, we can help to safeguard our health and that of
                      our planet by following healthy yet sustainable diets. The
                      food system affects not only our health but also the
                      environment and the society in which we live, therefore
                      the sustainability of our diets has been a major issue
                      since long time. The production, processing and
                      distribution of food can affect our planet in many ways...
                      that also depend on us directly. Helping the planet is
                      simple because the best food choices for you are also the
                      best choices for the environment in which you live. In
                      fact, the healthiest foods are also those with the least
                      environmental impact, while the least healthy foods are
                      those with the greatest environmental impact. For the
                      earth's sake it is also very important to reduce food
                      waste, through small changes in your habits that can
                      decrease the amount of food that is wasted every day.{" "}
                    </p>
                  </p>
                  <img
                    src={imageEdMat3_1}
                    style={{ maxWidth: "20%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Follow our advice for a "sustainable diet" and together
                      with your family you can make your contribution and make a
                      difference!
                    </p>
                    <ol>
                      <li>
                        Consume as much vegetable-based food as possible...
                        Fruit, vegetables, legumes, and cereals protect your
                        health and the environment in which you live. Always try
                        to prefer local and seasonal foods.
                      </li>
                      <li>
                        If you can drink tap water, it is excellent from a
                        nutritional point of view and is useful for the
                        environment.
                      </li>
                      <li>
                        Moderate consumption of products of animal origin. This
                        does not mean that they should be completely eliminated
                        from the diet, as they are necessary to avoid
                        deficiencies that should be compensated with
                        supplements, with the relative costs also in
                        environmental terms. In any case, try to moderate meat
                        consumption, preferring white and unprocessed meat,
                        following the recommendations and replacing it more
                        often with legumes.
                      </li>
                      <li>
                        At home, try in every way to help in the fight against
                        food waste... we can do much in the family too! Recycle
                        food leftovers to create new and tasty recipes, eating
                        what is left over at the next meal or the following day.
                        By not wasting and by teaching how not to waste, you can
                        transmit the culture of food value to the whole family.
                      </li>
                      <li>
                        Avoid buying food with unnecessary packaging. Whenever
                        you can try to prefer unpackaged food or food with the
                        minimum amount of packaging, always prefer packaging in
                        recyclable or reusable materials. To ensure that your
                        diet is both healthy and sustainable, you can refer to
                        the double pyramid model developed by the BCFN
                        Foundation (Barilla Centre For Food & Nutrition). This
                        model is the result from the study and the evolution of
                        the food pyramid, on which the Mediterranean Diet is
                        based, which is an example of a sustainable diet. The
                        double pyramid, alimentary and environmental, describes
                        the close relationship between the nutritional value of
                        food and its environmental impact, generated by both
                        production and consumption. In fact, as the food with
                        the lowest environmental impact is also the one most
                        recommended by nutritionists and experts in the field,
                        in the same way the food that leads to a higher
                        environmental impact is to be consumed in moderation
                        because of the "harmful" effects on health. The
                        invitation is to consume fruit and vegetables in
                        abundance, but also cereals, legumes, nuts, extra virgin
                        olive oil and fresh dairy products. On the contrary,
                        cheese, white meat, fish, eggs are foods whose
                        consumption should be limited, while red meat and sweets
                        should be considered for occasional consumption, these
                        foods offer important nutrients but their quantity must
                        be kept under control to avoid negative impacts on human
                        health and on the environment.
                      </li>
                    </ol>
                  </p>
                  <img
                    src={image3}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p>Source: Barilla Foundation 2013</p>
                  <br></br> <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      ¡Todo el mundo puede hacer algo! Como consumidores y
                      miembros de la familia, podemos ayudar a salvaguardar
                      nuestra salud y la de nuestro planeta siguiendo una dieta
                      saludable pero sostenible. El sistema alimentario afecta
                      no sólo a nuestra salud sino también al medio ambiente y a
                      la sociedad en la que vivimos, por lo que la
                      sostenibilidad de nuestras dietas ha sido un tema
                      importante desde hace mucho tiempo. La producción,
                      procesamiento y distribución de alimentos puede afectar a
                      nuestro planeta de muchas maneras... que también dependen
                      de nosotros directamente. Ayudar al planeta es sencillo
                      porque las mejores opciones de alimentos para usted son
                      también las mejores opciones para el medio ambiente en el
                      que vive. De hecho, los alimentos más saludables son
                      también los que tienen menor impacto ambiental, mientras
                      que los alimentos menos saludables son los que tienen
                      mayor impacto ambiental. Por el bien del planeta también
                      es muy importante reducir el desperdicio de alimentos, a
                      través de pequeños cambios en tus hábitos que puedan
                      disminuir la cantidad de alimentos que se desperdician
                      cada día.{" "}
                    </p>
                  </p>
                  <img
                    src={imageEdMat3_1}
                    style={{ maxWidth: "20%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      ¡Sigue nuestros consejos para una “dieta sustentable” y
                      junto a tu familia podrás hacer tu aporte y marcar la
                      diferencia!
                    </p>
                    <ol>
                      <li>
                        Consume la mayor cantidad posible de alimentos de origen
                        vegetal... Las frutas, verduras, legumbres y cereales
                        protegen tu salud y el medio ambiente en el que vives.
                        Intenta siempre preferir alimentos locales y de
                        temporada.
                      </li>
                      <li>
                        Si puedes beber agua del grifo, es excelente desde el
                        punto de vista nutricional y útil para el medio
                        ambiente.
                      </li>
                      <li>
                        Haz un consumo moderado de productos de origen animal.
                        Esto no significa que deban eliminarse por completo de
                        la dieta, ya que son necesarios para evitar carencias
                        que conviene compensar con suplementos, con los costes
                        relativos también en términos medioambientales. En
                        cualquier caso, intenta moderar el consumo de carne,
                        prefiriendo las carnes blancas y sin procesar, siguiendo
                        las recomendaciones y reemplazándola más a menudo por
                        legumbres.
                      </li>
                      <li>
                        En casa, intentad por todos los medios ayudar en la
                        lucha contra el desperdicio de alimentos... ¡en familia
                        también podemos hacer mucho! Recicla los restos de
                        comida para crear nuevas y sabrosas recetas, comiendo lo
                        que sobra en la siguiente comida o al día siguiente. No
                        desperdiciar y enseñar a no desperdiciar se puede
                        transmitir la cultura del valor alimentario a toda la
                        familia.
                      </li>
                      <li>
                        Evita comprar alimentos con envases innecesarios.
                        Siempre que puedas intenta preferir alimentos sin
                        envasar o alimentos con la mínima cantidad de envases,
                        prefiere siempre envases en materiales reciclables o
                        reutilizables.
                      </li>
                      <li>
                        Para asegurarse de que su dieta sea saludable y
                        sostenible, puede consultar el modelo de doble pirámide
                        desarrollado por la Fundación BCFN (Barilla Center For
                        Food & Nutrition). Este modelo es resultado del estudio
                        y evolución de la pirámide alimenticia, en la que se
                        basa la Dieta Mediterránea, que es un ejemplo de dieta
                        sostenible. La doble pirámide, alimentaria y ambiental,
                        describe la estrecha relación entre el valor nutricional
                        de los alimentos y su impacto ambiental, generado tanto
                        por su producción como por su consumo. De hecho, los
                        alimentos con menor impacto ambiental son también los
                        más recomendados por nutricionistas y expertos en la
                        materia, de la misma manera los alimentos que conllevan
                        un mayor impacto ambiental deben consumirse con
                        moderación por ser "nocivos" para la salud. La
                        invitación es a consumir frutas y verduras en
                        abundancia, pero también cereales, legumbres, frutos
                        secos, aceite de oliva virgen extra y lácteos frescos.
                        Por el contrario, el queso, las carnes blancas, el
                        pescado, los huevos y las galletas son alimentos cuyo
                        consumo se debe moderar, mientras que las carnes rojas y
                        los dulces se deben limitar a un consumo ocasional,
                        estos alimentos ofrecen nutrientes importantes, pero se
                        debe mantener bajo control su cantidad para evitar
                        efectos negativos en la salud humana y en el medio
                        ambiente.
                      </li>
                    </ol>
                  </p>
                  <img
                    src={image3}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p>Fuente: Barilla Foundation 2013</p>
                  <br></br> <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Herkes bir şeyler yapabilir! Tüketiciler ve aile üyeleri
                      olarak sağlıklı ve sürdürülebilir beslenmeyi takip ederek
                      hem kendimizin hem de gezegenimizin sağlığının korunmasına
                      yardımcı olabiliriz. Gıda sistemi sadece sağlığımızı değil
                      aynı zamanda çevreyi ve içinde yaşadığımız toplumu da
                      etkilediğinden, beslenmemizin sürdürülebilirliği uzun
                      zamandan beri önemli bir konu olmuştur. Gıdanın üretimi,
                      işlenmesi ve dağıtımı gezegenimizi pek çok açıdan
                      etkileyebilir ve bunlar aynı zamanda doğrudan bize de
                      bağlıdır. Gezegene yardım etmek çok basit çünkü sizin için
                      en iyi yiyecek seçenekleri aynı zamanda yaşadığınız çevre
                      için de en iyi seçimlerdir. Aslında en sağlıklı gıdalar
                      aynı zamanda çevreye en az etkiye sahip olanlardır; en az
                      sağlıklı gıdalar ise en fazla çevresel etkiye sahip
                      olanlardır. Dünyanın iyiliği için, alışkanlıklarınızda her
                      gün israf edilen yiyecek miktarını azaltabilecek küçük
                      değişiklikler yaparak gıda israfını azaltmak da çok
                      önemlidir.{" "}
                    </p>
                  </p>
                  <img
                    src={imageEdMat3_1}
                    style={{ maxWidth: "20%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      "Sürdürülebilir beslenme" için tavsiyelerimize uyun ve
                      ailenizle birlikte katkıda bulunup fark yaratın!
                    </p>
                    <ol>
                      <li>
                        Mümkün olduğu kadar sebze bazlı besin tüketin. Meyve,
                        sebze, baklagiller ve tahıllar sağlığınızı ve
                        yaşadığınız çevreyi korur. Her zaman yerel ve mevsimlik
                        yiyecekleri tercih etmeye çalışın.
                      </li>
                      <li>
                        Yaşadığınız bölgede musluk suyu içilebiliyorsa
                        mineraller bakımından zengin olması sebebiyle musluk
                        suyunu tercih edebilirsiniz.
                      </li>
                      <li>
                        Hayvansal kökenli ürünlerin orta düzeyde tüketimi. Bu,
                        bunların diyetten tamamen çıkarılması gerektiği anlamına
                        gelmez; çünkü bunlar, çevresel açıdan da göreceli
                        maliyetlerle birlikte, takviyelerle telafi edilmesi
                        gereken eksikliklerin önlenmesi için gereklidir. Her
                        durumda, et tüketimini ölçülü hale getirmeye çalışın,
                        beyaz ve işlenmemiş eti tercih edin, tavsiyelere uyun ve
                        et ürünlerini baklagillerle değiştirin.
                      </li>
                      <li>
                        Evde, gıda israfıyla mücadeleye yardımcı olmak için her
                        yolu deneyin. Aile içinde de çok şey yapabiliriz! Yeni
                        ve lezzetli tarifler oluşturmak için yiyecek artıklarını
                        geri dönüştürün, kalanları bir sonraki öğünde veya
                        ertesi gün yiyin. İsraf etmeyerek ve israf etmemeyi
                        öğreterek gıda değeri kültürünü tüm aileye
                        aktarabilirsiniz.
                      </li>
                      <li>
                        Gereksiz ambalajlı gıdaları satın almaktan kaçının.
                        Ambalajsız gıdaları veya minimum ambalajlı gıdaları
                        tercih edebildiğiniz durumlarda, daima geri
                        dönüştürülebilir veya tekrar kullanılabilir
                        malzemelerden yapılmış ambalajları tercih edin.
                      </li>
                      <li>
                        Beslenmenizin hem sağlıklı hem de sürdürülebilir
                        olmasını sağlamak için BCFN Vakfı (Barilla Gıda ve
                        Beslenme Merkezi) tarafından geliştirilen çift piramit
                        modeline başvurabilirsiniz. Bu model, sürdürülebilir bir
                        beslenme örneği olan Akdeniz Diyetinin dayandığı besin
                        piramidinin incelenmesi ve evrimi sonucunda ortaya
                        çıkmıştır. Beslenme ve çevre olmak üzere ikili piramit,
                        gıdanın besin değeri ile hem üretim hem de tüketimden
                        kaynaklanan çevresel etkisi arasındaki yakın ilişkiyi
                        tanımlamaktadır. Aslında çevresel etkisi en düşük olan
                        gıda aynı zamanda beslenme uzmanları ve alanında uzman
                        kişiler tarafından da en çok tavsiye edilen gıda olduğu
                        gibi, aynı şekilde çevresel etkisi daha fazla olan
                        gıdanın da sağlık üzerinde "olumsuz" etkileri olması
                        nedeniyle ölçülü tüketilmesi gerekiyor. Meyve ve
                        sebzelerin yanı sıra tahıllar, baklagiller,
                        kuruyemişler, sızma zeytinyağı ve taze süt ürünleri de
                        bol miktarda tüketilmektedir. Aksine, peynir, beyaz et,
                        balık, yumurta tüketimi sınırlandırılması gereken
                        yiyeceklerdir, kırmızı et ve tatlılar ise ara sıra
                        tüketilmesi gereken yiyeceklerdir, bu yiyecekler önemli
                        besin maddeleri sunar ancak insan sağlığı ve çevre
                        üzerinde olumsuz etkilere yol açmamak için miktarları
                        kontrol altında tutulmalıdır.
                      </li>
                    </ol>
                  </p>
                  <img
                    src={image3_tr}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p>Kaynak: Barilla Foundation 2013</p>
                  <br></br> <br></br>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic3;
