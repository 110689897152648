import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import imageEdMat2_1 from "../../images/EdMaterial/EdMat2_1.png";
import imageEdMat2_2 from "../../images/EdMaterial/EdMat2_2.png";
import imageEdMat2_3 from "../../images/EdMaterial/EdMat2_3.png";

import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic2() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 2;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };

  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />

          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>
            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Having a healthy lifestyle based on proper nutrition,
                      physical activity and a night's rest is essential for you
                      but also for your son/daughter to grow up strong and
                      healthy!{" "}
                    </p>

                    {/* Image container div */}
                    <div className="image-row">
                      <img src={imageEdMat2_1} />
                      <img src={imageEdMat2_2} />
                      <img src={imageEdMat2_3} />
                    </div>

                    <p>
                      Discover with us some fundamental aspects of a healthy
                      lifestyle through a few simple tips:
                    </p>
                    <ol>
                      <li>
                        <strong>Exercise regularly!</strong> Physical activity
                        is important for everyone, regardless of age, body
                        weight and health. Physical activity is important to
                        keep your heart healthy and your bones and muscles
                        strong, but above all it can also be fun. When you can
                        avoid using your car and move around on foot or by
                        bicycle, use the stairs instead of the lift and prefer
                        outdoor activities instead of sitting in front of the
                        television or computer.
                      </li>
                      <li>
                        <strong>Sleep!</strong> A proper night's sleep is
                        essential to recover your strength and be well rested
                        and relaxed even during the day. As well as the number
                        of hours dedicated to sleep, quality is also essential.
                        Try to create a correct sleep routine.
                      </li>
                      <li>
                        <strong>Vary the food you consume!</strong> For our
                        health, we need different nutrients, but no food
                        contains all of them. Try to vary as much as possible
                        what you eat every day.
                      </li>
                      <li>
                        <strong>Follow a diet rich in whole grains!</strong>{" "}
                        About half of the calories we eat should come from
                        carbohydrate-rich foods such as cereals, rice, pasta,
                        bread, and potatoes, preferably wholegrain. Include at
                        least one of them in every meal!
                      </li>
                      <li>
                        <strong>
                          Replace saturated fats with unsaturated fats!
                        </strong>{" "}
                        Fats are also important in a healthy diet and allow our
                        organism to function properly. But pay attention to
                        quality and quantity!
                      </li>
                      <li>
                        <strong>Eat plenty of fruit and vegetables!</strong>{" "}
                        Fruit and vegetables are among the most important foods
                        for our vitamin, mineral, and fibre requirements. That's
                        why you should consume at least 5 portions of fruit and
                        vegetables every day!
                      </li>
                      <li>
                        <strong>Reduce your intake of salt and sugar!</strong>{" "}
                        Excessive salt use often causes hypertension and
                        increases the risk of cardiovascular disease. Sugar, on
                        the other hand, provides many calories and should
                        therefore be consumed in moderation. In addition,
                        excessive and prolonged consumption of sugar can lead to
                        an unbalanced blood sugar regulation system (glycaemia),
                        which increases the risk of diabetes.
                      </li>
                      <li>
                        <strong>
                          Eat regularly and check the quantity of portions!
                        </strong>{" "}
                        For a healthy diet, it is important to vary foods
                        regularly as well as consume them in appropriate
                        portions.
                      </li>
                      <li>
                        <strong>Drink lots of water!</strong> Adults need at
                        least 2 litres of water per day..... And it's even
                        higher if it's very hot or if you do physical activity!
                      </li>
                      <li>
                        <strong>Stay in shape!</strong> The ideal weight is
                        different for each of us and depends on numerous factors
                        such as gender, height, age, and genetics. Overweight
                        increases the risks of a wide range of diseases,
                        including diabetes, heart disease, and cancer.
                      </li>
                    </ol>
                    <p>
                      And do you have a healthy lifestyle? Start now and change
                      little by little! Gradual lifestyle changes are easier to
                      maintain than the big breakthroughs applied all at once.
                      Continue with us on this journey of discovery of proper
                      nutrition and a healthy lifestyle!
                    </p>
                  </p>
                </div>
              )}

              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Llevar un estilo de vida saludable basado en una
                      alimentación adecuada, actividad física y descanso
                      nocturno es fundamental para ti, pero también para que tu
                      hijo/a crezca fuerte y sano.{" "}
                    </p>

                    {/* Image container div */}
                    <div className="image-row">
                      <img src={imageEdMat2_1} />
                      <img src={imageEdMat2_2} />
                      <img src={imageEdMat2_3} />
                    </div>

                    <p>
                      Descubre con nosotros algunos aspectos fundamentales de un
                      estilo de vida saludable a través de unos sencillos
                      consejos:
                    </p>
                    <ol>
                      <li>
                        <strong>¡Haz ejercicio regularmente!</strong> La
                        actividad física es importante para todos,
                        independientemente de la edad, el peso corporal y la
                        salud. La actividad física es importante para mantener
                        el corazón sano y los huesos y músculos fuertes, pero
                        sobre todo también puede ser divertida. Cuando puedas,
                        evita utilizar el coche y desplázate a pie o en
                        bicicleta, utiliza las escaleras en lugar del ascensor y
                        prefiere las actividades al aire libre en lugar de
                        sentarte frente al televisor o al ordenador.
                      </li>
                      <li>
                        <strong>¡Duerme!</strong> Un sueño nocturno adecuado es
                        fundamental para recuperar fuerzas y estar bien
                        descansado y relajado incluso durante el día. Además de
                        la cantidad de horas dedicadas al sueño, la calidad
                        también es fundamental. Intenta crear una rutina de
                        sueño correcta.
                      </li>
                      <li>
                        <strong>¡Varía los alimentos que consumes!</strong> Para
                        mantener nuestra salud necesitamos diferentes
                        nutrientes, pero ningún alimento los contiene todos.
                        Intenta variar lo máximo posible lo que comes cada día.
                      </li>
                      <li>
                        <strong>
                          ¡Sigue una dieta rica en cereales integrales!{" "}
                        </strong>{" "}
                        Aproximadamente la mitad de las calorías que ingerimos
                        deben provenir de alimentos ricos en carbohidratos como
                        cereales, arroz, pasta, pan y patatas, preferiblemente
                        integrales. ¡Incluye al menos uno de ellos en cada
                        comida!
                      </li>
                      <li>
                        <strong>
                          ¡Reemplaza las grasas saturadas por grasas
                          insaturadas!
                        </strong>{" "}
                        Las grasas también son importantes en una dieta
                        saludable y permiten que nuestro organismo funcione
                        correctamente. ¡Pero presta atención a la calidad y a la
                        cantidad!
                      </li>
                      <li>
                        <strong>¡Come muchas frutas y verduras!</strong> Las
                        frutas y verduras se encuentran entre los alimentos más
                        importantes para cubrir nuestras necesidades de
                        vitaminas, minerales y fibra. ¡Por eso debes consumir al
                        menos 5 porciones de frutas y verduras todos los días!
                      </li>
                      <li>
                        <strong>¡Reduce tu consumo de sal y azúcar!</strong> El
                        consumo excesivo de sal a menudo causa hipertensión y
                        aumenta el riesgo de enfermedades cardiovasculares. El
                        azúcar, por otro lado, aporta muchas calorías y, por
                        tanto, debe consumirse con moderación. Además, el
                        consumo excesivo y prolongado de azúcar puede provocar
                        un desequilibrio del sistema de regulación del azúcar en
                        sangre (glucemia), lo que aumenta el riesgo de diabetes.
                      </li>
                      <li>
                        <strong>
                          ¡Come regularmente y controla la cantidad de
                          porciones!
                        </strong>{" "}
                        Para mantener una dieta saludable es importante variar
                        los alimentos periódicamente, así como consumirlos en
                        porciones adecuadas.
                      </li>
                      <li>
                        <strong>¡Bebe mucha agua!</strong> Los adultos
                        necesitamos al menos 2 litros de agua al día... ¡La
                        necesidad es aún mayor si hace mucho calor o si haces
                        actividad física!
                      </li>
                      <li>
                        <strong>¡Mantente en forma!</strong> El peso ideal es
                        diferente para cada uno de nosotros y depende de
                        numerosos factores como el sexo, la altura, la edad y la
                        genética. El sobrepeso aumenta los riesgos de una amplia
                        gama de enfermedades, incluidas la diabetes, las
                        enfermedades cardíacas y el cáncer.
                      </li>
                    </ol>
                    <p>
                      ¿Y tú, tienes un estilo de vida saludable? ¡Empieza ahora
                      y cambia poco a poco! Los cambios graduales en el estilo
                      de vida son más fáciles de mantener que los grandes
                      avances aplicados todos a la vez. ¡Continúa con nosotros
                      en este viaje de descubrimiento de una nutrición adecuada
                      y un estilo de vida saludable!
                    </p>
                  </p>
                </div>
              )}

              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Doğru beslenmeye, fiziksel aktiviteye ve gece uykusuna
                      dayalı sağlıklı bir yaşam tarzına sahip olmak sizin için
                      olduğu kadar oğlunuzun/kızınızın da güçlü ve sağlıklı
                      büyümesi için çok önemlidir!{" "}
                    </p>

                    {/* Image container div */}
                    <div className="image-row">
                      <img src={imageEdMat2_1} />
                      <img src={imageEdMat2_2} />
                      <img src={imageEdMat2_3} />
                    </div>

                    <p>
                      Birkaç basit ipucuyla sağlıklı bir yaşam tarzının bazı
                      temel yönlerini bizimle keşfedin:
                    </p>
                    <ol>
                      <li>
                        <strong>Düzenli egzersiz!</strong> Fiziksel aktivite
                        yaş, vücut ağırlığı ve sağlık durumu ne olursa olsun
                        herkes için önemlidir. Fiziksel aktivite kalbinizi
                        sağlıklı tutmak, kemiklerinizi ve kaslarınızı güçlü
                        tutmak için önemlidir, ancak her şeyden önce eğlenceli
                        de olabilir. Araba kullanmaktan kaçındığınız zamanlarda
                        yürüyerek ya da bisikletle dolaşın, asansör yerine
                        merdivenleri kullanın, televizyon ya da bilgisayar
                        karşısında oturmak yerine açık hava etkinliklerini
                        tercih edin.
                      </li>
                      <li>
                        <strong>Uyumak!</strong> Gücünüzü toparlamak ve gün
                        içinde bile iyi dinlenmiş ve rahatlamış olmak için uygun
                        bir gece uykusu şarttır. Uykuya ayrılan saat sayısının
                        yanı sıra kalite de çok önemlidir. Doğru bir uyku rutini
                        oluşturmaya çalışın.
                      </li>
                      <li>
                        <strong>Tükettiğiniz besinleri çeşitlendirin!</strong>{" "}
                        Sağlığımız için farklı besinlere ihtiyacımız var ama
                        hiçbir besin bunların hepsini içermiyor. Her gün
                        yediklerinizi mümkün olduğunca değiştirmeye çalışın.
                      </li>
                      <li>
                        <strong>
                          Tam tahıllardan zengin bir diyet uygulayın!
                        </strong>{" "}
                        Yediğimiz kalorilerin yaklaşık yarısı tahıl, pirinç,
                        makarna, ekmek ve patates gibi karbonhidrat açısından
                        zengin gıdalardan, tercihen tam tahıllardan gelmelidir.
                        Her öğünde en az bir tanesine yer verin!
                      </li>
                      <li>
                        <strong>
                          Doymuş yağları doymamış yağlarla değiştirin!
                        </strong>{" "}
                        Yağlar sağlıklı beslenmede de önemlidir ve
                        organizmamızın düzgün çalışmasını sağlar. Ancak nitelik
                        ve niceliğe dikkat edin!
                      </li>
                      <li>
                        <strong>Bol miktarda meyve ve sebze yiyin!</strong>{" "}
                        Meyve ve sebzeler vitamin, mineral ve lif ihtiyacımızın
                        en önemli besinleri arasında yer alıyor. Bu yüzden her
                        gün en az 5 porsiyon meyve ve sebze tüketmelisiniz!
                      </li>
                      <li>
                        <strong>Tuz ve şeker tüketiminizi azaltın!</strong>{" "}
                        Aşırı tuz kullanımı sıklıkla hipertansiyona neden olur
                        ve kardiyovasküler hastalık riskini artırır. Şeker ise
                        çok fazla kalori sağlar ve bu nedenle ölçülü
                        tüketilmelidir. Ayrıca aşırı ve uzun süreli şeker
                        tüketimi, kan şekeri düzenleme sisteminin dengesiz
                        olmasına (glisemi) neden olabilir ve bu da diyabet
                        riskini artırır.
                      </li>
                      <li>
                        <strong>
                          Düzenli yiyin ve porsiyon miktarını kontrol edin!
                        </strong>{" "}
                        Sağlıklı beslenme için besinleri düzenli olarak
                        çeşitlendirmek ve uygun porsiyonlarda tüketmek
                        önemlidir.
                      </li>
                      <li>
                        <strong>Bolca su iç!</strong> Yetişkinlerin günde en az
                        2 litre suya ihtiyacı vardır eğer hava çok sıcaksa veya
                        fiziksel aktivite yapıyorsanız bu daha da yüksektir!
                      </li>
                      <li>
                        <strong>Formda kal!</strong> İdeal kilo her birimiz için
                        farklıdır ve cinsiyet, boy, yaş, genetik gibi birçok
                        faktöre bağlıdır. Aşırı kilo, diyabet, kalp hastalığı ve
                        kanser de dahil olmak üzere çok çeşitli hastalıkların
                        riskini artırır.
                      </li>
                    </ol>
                    <p>
                      Peki sağlıklı bir yaşam tarzınız var mı? Şimdi başlayın ve
                      yavaş yavaş değiştirin! Kademeli yaşam tarzı
                      değişikliklerinin sürdürülmesi, tek seferde uygulanan
                      büyük buluşlardan daha kolaydır. Doğru beslenme ve
                      sağlıklı yaşam tarzını keşfetme yolculuğumuza bizimle
                      devam edin!
                    </p>
                  </p>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic2;
