import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat15 from "../../images/EdMaterial/EdMat15.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic15() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 15;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            {currentLanguage === "en" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    In recent decades, the consumption of non-domestic meals has
                    increased significantly. In fact, an ever-increasing number
                    of consumers have become accustomed to eating meals outside
                    of home (or prepared outside the home!): we often have
                    breakfast at the bar on the way to work, we buy lunch in the
                    canteen below the office and we get take-away dinner for the
                    whole family. It is important to remember that the more you
                    eat out, the more important it becomes to choose wisely from
                    the menu in order to stay fit and to have an overall healthy
                    and balanced diet. To ensure the right proportion of
                    nutrients and the variety of foods, you must pay particular
                    attention to balancing your consumption outside the home
                    with domestic ones, both from a qualitative and quantitative
                    point of view.
                  </p>
                </p>

                <img
                  src={EdMat15}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Here are some tips to adopt for non-domestic meals, in the
                    different types of catering, which will help you to balance
                    your diet and that of your whole family as much as possible,
                    together with some general advice.{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>At the restaurant </strong>, choose to eat a
                      single course, with the addition of a side dish of
                      vegetables and a fruit bowl. Adapt the size of the
                      portions to your nutritional needs and do not overdo it by
                      consuming bread, breadsticks, and crackers. You should
                      order food that is not too rich in salt and seasoning fat;
                      if you can, personally dress your dishes. An excellent
                      solution is to choose a single dish, such as a rich salad
                      with tuna, dairy products, boiled eggs, or legumes,
                      accompanied by a small amount of bread and a portion of
                      fruit. Also, in this case, limit the additions of oil and
                      salt as much as possible.
                    </li>
                    <li>
                      <stong>In the pizzeria</stong>, choose the classic pizza
                      Margherita, which represents a tasty one-course dish that
                      combines the nutritional characteristics of a first course
                      with those of a second course. The ideal combination is
                      with a portion of vegetables and/or fruit to make your
                      out-of-home meal nutritionally adequate. Remember,
                      however, that the calories contained in pizza are not few,
                      nor is the salt.
                    </li>
                    <li>
                      <strong>At the bar</strong>, a sandwich can be considered
                      a small meal that can also be balanced with a few tricks.
                      Choose wholemeal bread with a reduced salt content and do
                      not overdo it with the stuffing, otherwise, you can easily
                      exceed 500 Kcal with just one sandwich. Avoid sauces and
                      condiments, add lots of vegetables, and try to limit the
                      content of cold cuts, preferring fresh cheese or fish.
                      This meal can be combined with some fruit.
                    </li>
                    <li>
                      <strong>In the canteen</strong> during the lunch break,
                      you prefer the single-course meal accompanied by a side
                      dish of vegetables and a fruit cup. If available, you can
                      opt for a single course or a large salad with cereals,
                      proteins, and vegetables, which you can combine with bread
                      and fruit. Be careful not to give in to the classic
                      "buffet syndrome", especially if at a fixed price,
                      characterized by the tendency to exaggerate in the size of
                      the courses, not giving up anything that is offered.
                    </li>
                    <li>
                      Usually, the dishes are served already seasoned, so we
                      advise you to always taste the food before adding salt.
                    </li>
                    <li>
                      To keep you always hydrated, eat with water instead of
                      sugary or alcoholic drinks. Remember that during the day
                      you should intake about 2 litres of water, an objective
                      that is very difficult to achieve if you do not drink
                      properly during meals.
                    </li>
                    <li>
                      Always keep in mind that the portions served are often
                      more abundant than necessary. Excessive amounts of food
                      will lead you to eat more than you need.
                    </li>
                    <li>
                      Even if you eat out, always remember to order fruit and
                      vegetables. The more fruit and vegetables you eat, the
                      better it is for your health.
                    </li>
                    <li>
                      Try to eat fruit instead of dessert, as this will increase
                      your opportunities to eat this food while reducing the
                      consumption of higher-calorie sweets.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "es" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    En las últimas décadas, el consumo de comidas fuera de casa
                    ha aumentado significativamente. De hecho, un número cada
                    vez mayor de consumidores se ha acostumbrado a comer fuera
                    de casa (¡o a comer comida preparada fuera de casa!): a
                    menudo desayunamos en el bar de camino al trabajo, compramos
                    el almuerzo en el comedor debajo de la oficina y pedimos
                    cena para llevar para toda la familia. Es importante
                    recordar que cuanto más comamos fuera, más importante es
                    elegir sabiamente del menú para mantenerse en forma y tener
                    una dieta general sana y equilibrada. Para garantizar la
                    proporción adecuada de nutrientes y la variedad de
                    alimentos, hay que prestar especial atención a equilibrar el
                    consumo de comida que hacemos fuera de casa con el
                    doméstico, tanto desde el punto de vista cualitativo como
                    cuantitativo.
                  </p>
                </p>

                <img
                  src={EdMat15}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    A continuación, te damos algunos consejos a adoptar para las
                    comidas no domésticas, en los diferentes tipos de
                    restauración, que te ayudarán a equilibrar al máximo tu
                    dieta y la de toda tu familia, junto con algunos consejos
                    generales.
                  </p>
                  <ul>
                    <li>
                      <strong>En el restaurante</strong>, opta por comer un solo
                      plato, con el añadido de una guarnición de verduras y un
                      bol de fruta. Adapta el tamaño de las raciones a tus
                      necesidades nutricionales y no te excedas consumiendo pan,
                      palitos de pan y galletas saladas. Debes pedir alimentos
                      que no sean demasiado ricos en sal y grasas para
                      condimentar; en el caso que puedas puedes aliñar
                      personalmente tus platos. Una excelente solución es elegir
                      un plato único, como una rica ensalada con atún, lácteos,
                      huevos cocidos o legumbres, acompañada de una pequeña
                      cantidad de pan y una ración de fruta. Incluso en este
                      caso limita al máximo el aceite y la sal añadida.
                    </li>
                    <li>
                      <strong>En la pizzería</strong> elige la clásica pizza
                      Margherita, que representa un sabroso plato de un solo
                      plato que combina las características nutricionales de un
                      primer plato con las de un segundo plato. La combinación
                      ideal es con una ración de verdura y/o fruta, para que tu
                      comida fuera de casa sea nutricionalmente adecuada.
                      Recuerda, eso sí, que las calorías que contiene la pizza
                      no son pocas, como tampoco lo es la sal.
                    </li>
                    <li>
                      <strong>En un bar</strong> un bocadillo puede considerarse
                      una comida pequeña que además se puede equilibrar con
                      algunos trucos. Elige pan integral con un contenido
                      reducido en sal y no te excedas con el relleno, de lo
                      contrario puedes superar fácilmente las 500 Kcal con un
                      solo bocadillo. Evita salsas y condimentos, agrega muchas
                      verduras y trata de limitar el contenido de embutidos,
                      prefiriendo queso fresco o pescado. Esta comida se puede
                      combinar con algo de fruta.
                    </li>
                    <li>
                      <strong>En el comedor</strong>, durante la pausa del
                      almuerzo, es preferible un plato único acompañado de una
                      guarnición de verduras y una taza de fruta. Si está
                      disponible, puedes optar por un plato único o una ensalada
                      grande con cereales, proteínas y verduras, que puedes
                      combinar con pan y fruta. Ten cuidado de no ceder al
                      clásico "síndrome del buffet", especialmente si el
                      restaurante es de precio fijo, caracterizado por la
                      tendencia a exagerar en el tamaño de nuestros platos, sin
                      renunciar a nada de lo que nos se ofrece.
                    </li>
                    <li>
                      Normalmente los platos se sirven ya condimentados, por lo
                      que aconsejamos probar siempre la comida antes de añadir
                      sal.
                    </li>
                    <li>
                      Para mantenerte siempre hidratado, come con agua en lugar
                      de bebidas azucaradas o alcohólicas. Recuerda que durante
                      el día debes ingerir unos 2 litros de agua, objetivo muy
                      difícil de conseguir si no bebes adecuadamente durante las
                      comidas.
                    </li>
                    <li>
                      Ten siempre en cuenta que las raciones que se sirven
                      suelen ser más abundantes de lo necesario. Cantidades
                      excesivas de comida te llevarán a comer más de lo que
                      necesitas.
                    </li>
                    <li>
                      Aunque comas fuera, recuerda siempre pedir fruta y
                      verdura. Cuantas más frutas y verduras comas, mejor será
                      para tu salud.
                    </li>
                    <li>
                      Intenta comer fruta en lugar de postre, ya que esto
                      aumentará tus oportunidades de comer este alimento y al
                      mismo tiempo reducirá el consumo de dulces ricos en
                      calorías.
                    </li>
                  </ul>
                </p>
              </div>
            )}{" "}
            {currentLanguage === "tr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Son yıllarda ev dışı yemeklerin tüketimi önemli ölçüde
                    arttı. Aslında, giderek artan sayıda tüketici, ev dışında
                    yemek yemeye (veya ev dışında hazırlanan!) alıştı:
                    Kahvaltımızı genellikle işe giderken kafede yapıyoruz, öğle
                    yemeğini ofisin altındaki kantinden alıyoruz, tüm aile için
                    akşam yemeklerini paket olarak alıyoruz. Dışarıda ne kadar
                    çok yemek yerseniz, formda kalmak ve genel olarak sağlıklı
                    ve dengeli beslenmek için menüden akıllıca seçim yapmanın o
                    kadar önemli hale geldiğini unutmamak önemlidir. Besinlerin
                    doğru oranını ve yiyecek çeşitliliğini sağlamak için hem
                    nitelik hem de nicelik açısından ev dışındaki tüketiminizi
                    evde tüketilen ürünlerle dengelemeye özellikle dikkat
                    etmelisiniz.
                  </p>
                </p>

                <img
                  src={EdMat15}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Aşağıda, bazı genel tavsiyelerle birlikte, kendi diyetinizi
                    ve tüm ailenizin diyetini mümkün olduğunca dengelemenize
                    yardımcı olacak, farklı yemek hizmetleri türlerinde ev dışı
                    yemekler için uygulayabileceğiniz bazı ipuçları yer
                    almaktadır.
                  </p>
                  <ul>
                    <li>
                      <strong>Restoranda</strong>, sebze ve meyve tabağı
                      ilavesiyle tek bir tabak yemeyi tercih edin. Porsiyonların
                      büyüklüğünü beslenme ihtiyaçlarınıza göre ayarlayın.
                      Ekmek, galeta ve kraker tüketiminde aşırıya kaçmayın. Tuz,
                      baharat ve yağ açısından çok zengin olmayan yiyecekler
                      sipariş etmelisiniz. Mükemmel bir çözüm, az miktarda ekmek
                      ve bir porsiyon meyve eşliğinde, mevsim balığı, süt
                      ürünleri, haşlanmış yumurta veya baklagillerden oluşan
                      zengin bir salata gibi tek bir yemeği seçmektir. Ayrıca bu
                      durumda yağ ve tuz ilavesini mümkün olduğu kadar
                      sınırlayın.
                    </li>
                    <li>
                      <strong>Pizzacıda</strong>, birinci yemeğin beslenme
                      özelliklerini ikinci yemeğin besin özellikleriyle
                      birleştiren lezzetli, tek servisli bir yemeği temsil eden
                      klasik pizza Margherita'yı seçin. İdeal kombinasyon, ev
                      dışı yemeğinizi besin açısından yeterli hale getirmek için
                      bir porsiyon sebze ve/veya meyvedir. Ancak pizzanın
                      içerdiği kalorinin ve tuzun az olmadığını unutmayın.
                    </li>
                    <li>
                      <strong>Kafede</strong>, sandviç küçük bir öğün
                      sayılabilir ve birkaç püf noktasıyla dengelenebilir. Tuz
                      oranı azaltılmış tam tahıllı ekmeği tercih edin ve
                      içeriğinde aşırıya kaçmayın. Aksi takdirde tek bir
                      sandviçle 500 Kcal'i rahatlıkla aşabilirsiniz. Soslardan
                      ve çeşnilerden kaçının, bol miktarda sebze ekleyin ve taze
                      peynir veya balık tercih ederek söğüş etlerin içeriğini
                      sınırlamaya çalışın. Bu öğün bir miktar meyve ile
                      birleştirilebilir.
                    </li>
                    <li>
                      <strong>Öğle yemeği molasında kantinde</strong>, sebze ve
                      meyve tabağı eşliğinde tek çeşit yemeği tercih edin.
                      Mümkünse tek bir yemeği veya tahıllar, proteinler ve
                      sebzelerden oluşan, ekmek ve meyveyle
                      birleştirebileceğiniz büyük bir salatayı tercih
                      edebilirsiniz. Klasik "büfe sendromuna" teslim olmamaya
                      dikkat edin. Büfe sendromu; özellikle sabit bir fiyatla
                      sunulan, porsiyonların boyutunu abartma eğilimiyle
                      karakterize edilir.
                    </li>
                    <li>
                      Genellikle yemekler önceden baharatlı olarak servis
                      edilir, bu nedenle tuz eklemeden önce daima yemeğin tadına
                      bakmanızı tavsiye ederiz.
                    </li>
                    <li>
                      Servis edilen porsiyonların genellikle gereğinden fazla
                      olduğunu her zaman aklınızda bulundurun. Aşırı miktarda
                      yiyecek, ihtiyacınızdan daha fazlasını yemenize yol
                      açacaktır.
                    </li>
                    <li>
                      Dışarıda yemek yeseniz bile daima meyve ve sebze sipariş
                      etmeyi unutmayın. Ne kadar çok meyve ve sebze yerseniz
                      sağlığınız için o kadar iyi olur.
                    </li>
                    <li>
                      Tatlı yerine meyve yemeyi deneyin, çünkü meyve tüketimi
                      yüksek kalorili tatlı tüketimini azaltırken meyve yeme
                      fırsatlarınızı artıracaktır.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic15;
