import "./userProfilePage.css";
import SideBar from "../components/sideBar.js";
import Footer from "../components/footer.js";
import image from "../images/graphics/STH - LOGO.png";
import { Alert } from "react-bootstrap";
import backgroundImage from "../images/graphics/about.jpg";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LogoutAfterInactivity from "../components/logoutAfterInactivity";
import SideBar2 from "../components/sideBar2.js";
import TextField from "@mui/material/TextField";

function ChildProfilePage() {
  const [userProfile, setUserProfile] = useState();
  const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [groupAge, setGroupAge] = useState("");
  const [snack, setSnack] = useState("");
  const [milk, setMilk] = useState("");
  const [country, setCountry] = useState("");
  const [spain, setSpain] = useState(false);
  const [turkey, setTurkey] = useState(false);
  const [morocco, setMorocco] = useState(false);
  const [interventionGroup, setInterventionGroup] = useState("");
  const [countryLanguageCode, setCountryLanguageCode] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState();
  const { t } = useTranslation();

  const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-globe-europe-africa"
      viewBox="0 0 16 16"
    >
      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z" />
    </svg>
  );

  const globe_languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },

    {
      code: "fr",
      name: "Français",
      country_code: "fr",
    },
    {
      code: "es",
      name: "Español",
      country_code: "es",
    },

    {
      code: "tr",
      name: "Türkçe",
      country_code: "tr",
    },
  ];

  const languages = {
    en: {
      name: t("English"),
    },
    fr: {
      name: t("French"),
    },
    sp: {
      name: t("Spanish"),
    },
    tr: {
      name: t("Turkish"),
    },
  };

  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("child") ? localStorage.getItem("child") : null
  );

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      handleCuisineLanguage();
    }
  });

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      (async () => {
        setLoading(true);
        const response = await axios.get(
          "IdChildProfile/" + userProfileId + "/"
        );
        //setUser(response.data.user);
        // console.log(response.data);
        setUserProfile(response.data);
        setUsername(response.data.username);
        setSchool(response.data.child_school_name);
        setGroupAge(response.data.group_age);
        setSnack(response.data.child_snack);
        setMilk(response.data.child_milk);
        setInterventionGroup(response.data.intervention_group);
        setCountry(response.data.country);
        setCountryLanguageCode(response.data.countryLanguageCode);
        setLoading(false);
      })();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let timer;
    try {
      timer = setTimeout(() => {
        setLoading(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 200);
      //console.log(userProfile);
      const response1 = await axios.put(
        "IdChildProfile/" + userProfileId + "/",
        {
          snack: snack,
          milk: milk,
          countryLanguageCode: countryLanguageCode,
        }
      );
      navigate("/childHome");
    } catch (error) {
      // console.error("Error saving username and password.", error);
    } finally {
      clearTimeout(timer);
      setLoading(false);
    }
  };

  // Function to handle button click
  const handleCuisineLanguage = () => {
    // Perform action based on the selected country
    switch (country) {
      case "Spain":
        // Action for Spain cuisine
        setSpain(true);
        setTurkey(false);
        setMorocco(false);
        break;
      case "Turkey":
        // Action for Turkey cuisine
        setSpain(false);
        setTurkey(true);
        setMorocco(false);
        break;
      case "Morocco":
        // Action for Morocco cuisine
        setSpain(false);
        setTurkey(false);
        setMorocco(true);
        break;
    }
  };

  LogoutAfterInactivity();

  return (
    <div className="rightpart">
      {loading && (
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">{t("Loading")}...</span>
          </div>
        </div>
      )}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar2 />
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 1.5, ease: "easeOut" }}
            className="childProfile-page"
          >
            <img src={image} style={{ maxWidth: "100%", height: "auto" }} />
            <form onSubmit={handleSubmit}>
              <br></br>
              <br></br>
              <div className="user-profile-grid1">
                <div>
                  <div className="user-profile-font">{t("school_name")}</div>
                  <br></br>
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="School"
                      variant="outlined"
                      value={school}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <br></br>
                  <div className="user-profile-font">{"Group Age"}</div>
                  <br></br>
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="Age"
                      variant="outlined"
                      value={groupAge}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>

                  <div className="ruser-profile-font">{t("milk_allergy")}</div>
                  <div className="section-display">
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="milk_allergy"
                        value={milk}
                        checked={milk === true}
                        onChange={(event) => {
                          setMilk(true);
                          setChangesMade(true);
                        }}
                        className="user-font"
                      />{" "}
                      {t("yes")}
                    </label>
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="milk_allergy"
                        value={milk}
                        checked={milk === false}
                        onChange={(event) => {
                          setMilk(false);
                          setChangesMade(true);
                        }}
                        className="user-font"
                      />{" "}
                      {t("no")}
                    </label>
                  </div>
                  <div className="ruser-profile-font">{t("child_snack")}</div>
                  <div className="section-display">
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="child_snack"
                        value={snack}
                        checked={snack === true}
                        onChange={(event) => {
                          setSnack(true);
                          setChangesMade(true);
                        }}
                        className="user-font"
                        disabled
                      />{" "}
                      {t("yes")}
                    </label>
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="child_snack"
                        value={snack}
                        checked={snack === false}
                        onChange={(event) => {
                          setSnack(false);
                          setChangesMade(true);
                        }}
                        className="user-font"
                        disabled
                      />{" "}
                      {t("no")}
                    </label>
                  </div>
                </div>
                <div>
                  <div className="ruser-profile-font">
                    {t("intervention_group")}:
                  </div>
                  <div className="section-display">
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="intervention_group"
                        value={t("intervention_group_1")}
                        checked={interventionGroup === "intervention_group_1"}
                        disabled
                      />{" "}
                      {t("intervention_group_1")}{" "}
                      <em style={{ fontSize: "small" }}>
                        {t("intervention_group_1_des")}
                      </em>
                    </label>
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="intervention_group"
                        value={t("intervention_group_4")}
                        checked={interventionGroup === "intervention_group_4"}
                        disabled
                      />{" "}
                      {t("intervention_group_4")}{" "}
                      <em style={{ fontSize: "small" }}>
                        {t("intervention_group_4_des")}
                      </em>
                    </label>
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="intervention_group"
                        value={t("intervention_group_5")}
                        checked={interventionGroup === "intervention_group_5"}
                        disabled
                      />{" "}
                      {t("intervention_group_5")}{" "}
                      <em style={{ fontSize: "small" }}>
                        {t("intervention_group_5_des")}
                      </em>
                    </label>
                    <label style={{ display: "block" }}>
                      <input
                        type="radio"
                        name="intervention_group"
                        value={t("intervention_group_7")}
                        checked={interventionGroup === "intervention_group_7"}
                        disabled
                      />{" "}
                      {t("intervention_group_7")}{" "}
                      <em style={{ fontSize: "small" }}>
                        {t("intervention_group_7_des")}
                      </em>
                    </label>
                  </div>
                  <br></br>
                  <div>
                    <label className="user-profile-font">
                      {t("Choose_national_cuisine")}:
                    </label>
                  </div>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="country"
                      value="Spain"
                      checked={country === "Spain"}
                      className="user-font"
                      disabled
                    />{" "}
                    {t("Spain")}
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="country"
                      value="Turkey"
                      checked={country === "Turkey"}
                      className="user-font"
                      disabled
                    />{" "}
                    {t("Turkey")}
                  </label>
                  {/* <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="country"
                      value="Morocco"
                      checked={country === "Morocco"}
                      className="user-font"
                      disabled
                    />{" "}
                    {t("Morocco")}
                  </label> */}
                  <br></br>
                  <div>
                    <label className="user-profile-font">
                      {t("daily_plans")}:
                    </label>
                    {country && (
                      <div>
                        {spain && (
                          <label style={{ display: "block" }}>
                            <input
                              type="radio"
                              name="countryLanguage"
                              value="sp"
                              checked={countryLanguageCode === "sp"}
                              onChange={(event) => {
                                setCountryLanguageCode(event.target.value);
                                setChangesMade(true);
                              }}
                              className="user-font"
                              required
                            />{" "}
                            {languages["sp"]?.name || ""}
                          </label>
                        )}
                        {turkey && (
                          <label style={{ display: "block" }}>
                            <input
                              type="radio"
                              name="countryLanguage"
                              value="tr"
                              checked={countryLanguageCode === "tr"}
                              onChange={(event) => {
                                setCountryLanguageCode(event.target.value);
                                setChangesMade(true);
                              }}
                              className="user-font"
                              required
                            />{" "}
                            {languages["tr"]?.name || ""}
                          </label>
                        )}
                        {morocco && (
                          <label style={{ display: "block" }}>
                            <input
                              type="radio"
                              name="countryLanguage"
                              value="fr"
                              checked={countryLanguageCode === "fr"}
                              onChange={(event) => {
                                setCountryLanguageCode(event.target.value);
                                setChangesMade(true);
                              }}
                              className="user-font"
                              required
                            />{" "}
                            {languages["fr"]?.name || ""}
                          </label>
                        )}
                        <label style={{ display: "block" }}>
                          <input
                            type="radio"
                            name="countryLanguage"
                            value="en"
                            checked={countryLanguageCode === "en"}
                            onChange={(event) => {
                              setCountryLanguageCode(event.target.value);
                              setChangesMade(true);
                            }}
                            className="user-font"
                            required
                          />{" "}
                          {t("English")}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <br></br>
              <br></br>
              <br></br>
              <div>
                <Link
                  to="/childHome"
                  value="Cancel"
                  className="button"
                  style={{ float: "left", textDecoration: "none" }}
                >
                  {t("Cancel")}
                </Link>
                <input
                  type="submit"
                  value={t("Update")}
                  className={`button ${!changesMade ? "inactive-button" : ""}`}
                  style={{ float: "right" }}
                  disabled={!changesMade}
                />
              </div>
              <br></br>
            </form>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default ChildProfilePage;
