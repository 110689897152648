import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat6 from "../../images/EdMaterial/EdMat6.png";
import EdMat6_tr from "../../images/EdMaterial/Topic6_tr.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic6() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  const topicNumber = 6;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };

  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Eating seasonal products is a responsible choice that
                      represents not only a healthy and sustainable behaviour
                      but also a very important part of a correct lifestyle.
                      Consumers who look for seasonal products throughout the
                      year will be rewarded with health and savings from an
                      economic point of view. In fact, seasonal products,
                      especially fruit and vegetables, always offer us the best
                      of their properties: they are more nutritious, tastier,
                      safer and... less expensive! Plants that grow following
                      their normal life cycle are characterized by a higher
                      quantity and quality of nutrients, something that cannot
                      be guaranteed by crops that are forced to grow out of
                      their natural season. Furthermore, especially for
                      biological and biodynamic products, the choice of seasonal
                      products can guarantee the absence (or a reduced use) of
                      chemicals, which can be harmful both to our health and
                      that of our family. It is also very important to keep in
                      mind the huge environmental impact associated with
                      non-seasonal products, that in order to grow require
                      enormous heated and illuminated greenhouses with
                      significant energy costs, plenty of water and polluting
                      chemicals such as pesticides and fertilizers, not to
                      mention the energy costs associated with their transport
                      to reach us when they come from remote countries! It is
                      therefore more than evident how the consumption of
                      off-season products is unnecessarily harmful to our health
                      and to the environment in which we live... besides being
                      more expensive! So..... Why pollute? Why not save money?
                      And why risk it?{" "}
                    </p>
                    <p>
                      Follow this short guide to navigate around market stalls
                      and supermarkets, bringing to the table only the best
                      products offered by each season.
                    </p>
                  </p>
                  <img
                    src={EdMat6}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Comer productos de temporada es una elección responsable
                      que representa no sólo un comportamiento saludable y
                      sostenible sino también una parte muy importante de un
                      correcto estilo de vida. Los consumidores que busquen
                      productos de temporada durante todo el año se verán
                      recompensados con una mejor salud y ahorro desde el punto
                      de vista económico. De hecho, los productos de temporada,
                      especialmente frutas y verduras, siempre nos ofrecen lo
                      mejor de sus propiedades: son más nutritivos, más
                      sabrosos, más seguros y… ¡menos caros! Las plantas que
                      crecen siguiendo su ciclo de vida normal se caracterizan
                      por tener una mayor cantidad y calidad de nutrientes, algo
                      que no pueden garantizar los cultivos que se ven obligados
                      a crecer fuera de su estación natural. Además,
                      especialmente en el caso de los productos biológicos y
                      biodinámicos, la elección de productos de temporada puede
                      garantizar la ausencia (o un uso reducido) de productos
                      químicos, que pueden ser perjudiciales tanto para nuestra
                      salud como para la de nuestra familia. También es muy
                      importante tener en cuenta el enorme impacto
                      medioambiental asociado a los productos fuera de
                      temporada, que para crecer requieren enormes invernaderos
                      climatizados e iluminados con importantes costes
                      energéticos, abundante agua y productos químicos
                      contaminantes como pesticidas y fertilizantes, para no
                      mencionar los costes de energía asociados con su
                      transporte para llegar hasta nosotros cuando vienen de
                      países remotos. Por tanto, es más que evidente cómo el
                      consumo de productos fuera de temporada es
                      innecesariamente perjudicial para nuestra salud y para el
                      medio ambiente en el que vivimos... ¡además de ser más
                      caro! Entonces…. ¿Por qué contaminar? ¿Por qué no ahorrar
                      dinero? ¿Y por qué arriesgarse?{" "}
                    </p>
                    <p>
                      Sigue esta breve guía para navegar por los puestos de
                      mercados y supermercados, llevando a la mesa solo los
                      mejores productos que ofrece cada temporada.
                    </p>
                  </p>
                  <img
                    src={EdMat6}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Mevsimlik ürünler yemek, yalnızca sağlıklı ve
                      sürdürülebilir bir davranışı değil, aynı zamanda doğru bir
                      yaşam tarzının da çok önemli bir parçasını temsil eden
                      sorumlu bir seçimdir. Yıl boyunca sezonluk ürün arayan
                      tüketiciler, ekonomik açıdan sağlık ve tasarrufla
                      ödüllendirilecek. Aslında mevsimlik ürünler, özellikle de
                      meyve ve sebzeler bize her zaman özelliklerinin en iyisini
                      sunar: daha besleyici, daha lezzetli, daha güvenli ve...
                      daha ucuzdurlar! Normal yaşam döngülerini takip ederek
                      büyüyen bitkiler, daha yüksek miktarda ve kalitede besinle
                      karakterize edilir; bu, doğal mevsiminin dışında büyümeye
                      zorlanan mahsuller tarafından garanti edilemeyen bir
                      şeydir. Ayrıca, özellikle biyolojik ve biyodinamik
                      ürünlerde mevsimlik ürünlerin seçimi hem bizim hem de
                      ailemizin sağlığına zararlı olabilecek kimyasalların
                      bulunmamasını (veya kullanımının azaltılmasını) garanti
                      edebilir. Ayrıca, büyümek için önemli miktarda enerji
                      maliyeti olan, çok fazla ısıtılan ve aydınlatılan seralar,
                      bol miktarda su ve pestisit ve gübre gibi kirletici
                      kimyasalların gerekli olduğu, mevsimsel olmayan ürünlerle
                      bağlantılı büyük çevresel etkinin akılda tutulması da çok
                      önemlidir. Uzak ülkelerden geldiklerinde bize ulaşmaları
                      için ulaşımla ilgili enerji maliyetleri çok yüksektir! Bu
                      nedenle, sezon dışı ürünlerin tüketiminin daha pahalı
                      olmasının yanı sıra sağlığımıza ve yaşadığımız çevreye ne
                      kadar gereksiz derecede zarar verdiği çok açık!{" "}
                    </p>
                    <p>Peki…Neden kirletelim?</p>
                    <p>Neden tasarruf etmeyelim?</p>
                    <p>Neden riske girelim?</p>
                    <p>
                      Pazar tezgahlarında ve süpermarketlerde gezinmek ve her
                      mevsimde yalnızca en iyi ürünleri masaya getirmek için bu
                      kısa kılavuzu izleyin.
                    </p>
                  </p>
                  <img
                    src={EdMat6_tr}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic6;
