import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic12() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 12;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>
            <div>
              {currentLanguage === "en" && (
                <p className="TopicDescription-font">
                  <p>
                    The " standard portions of consumption" are defined as the
                    quantities of a food, normally expressed in grams,
                    identified as reference units of measurement, recognised and
                    identifiable both by nutritional operators and by the
                    population and used for the development of healthy and
                    balanced dietary plans. Standard portions must be consistent
                    with food traditions and should be of reasonable size not
                    only to ensure the health of the individual consumer but
                    also to satisfy his or her taste and appetite. A healthy and
                    balanced diet that is at the same time adequate to meet
                    energy needs, in fact, requires not only the proper choice
                    of the quality and variety of foods, but also their quantity
                    and frequency of consumption. The portions consumed are not
                    the same for everyone, but must always be tailored to age,
                    sex, height, level of physical activity and physiological
                    state.
                  </p>
                </p>
              )}
              {currentLanguage === "es" && (
                <p className="TopicDescription-font">
                  Las "porciones estándar de consumo" se definen como las
                  cantidades de un alimento, normalmente expresadas en gramos,
                  identificadas como unidades de medida de referencia,
                  reconocidas e identificables tanto por los operadores
                  nutricionales como por la población, y utilizadas para el
                  desarrollo de planes dietéticos saludables y equilibrados. Las
                  porciones estándar deben ser consistentes con las tradiciones
                  alimentarias y deben tener un tamaño razonable no sólo para
                  asegurar la salud del consumidor individual sino también para
                  satisfacer su gusto y apetito. De hecho, una dieta sana y
                  equilibrada que sea al mismo tiempo adecuada para cubrir las
                  necesidades energéticas requiere no sólo la elección adecuada
                  de la calidad y variedad de los alimentos, sino también su
                  cantidad y frecuencia de consumo. Las raciones consumidas no
                  son las mismas para todos, sino que siempre deben adaptarse a
                  la edad, sexo, altura, nivel de actividad física y estado
                  fisiológico.
                </p>
              )}{" "}
              {currentLanguage === "tr" && (
                <p className="TopicDescription-font">
                  "Standart tüketim porsiyonları", normal olarak gram cinsinden
                  ifade edilen, referans ölçüm birimleri olarak tanımlanan, hem
                  beslenme operatörleri hem de toplum tarafından tanınan ve
                  tanımlanabilen ve sağlıklı ve dengeli beslenme planlarının
                  geliştirilmesi için kullanılan bir gıdanın miktarları olarak
                  tanımlanır. Standart porsiyonlar gıda gelenekleriyle tutarlı
                  olmalı ve yalnızca bireysel tüketicinin sağlığını değil aynı
                  zamanda damak zevkini ve iştahını da tatmin edecek makul
                  büyüklükte olmalıdır. Sağlıklı ve dengeli, aynı zamanda enerji
                  ihtiyacını karşılamaya yeterli bir beslenme, aslında gıdaların
                  kalite ve çeşitliliğinin yanı sıra miktar ve tüketim
                  sıklığının da doğru seçilmesini gerektiriyor. Tüketilen
                  porsiyonlar herkes için aynı değildir ancak her zaman yaşa,
                  cinsiyete, boya, fiziksel aktivite düzeyine ve fizyolojik
                  duruma göre uyarlanmalıdır.{" "}
                </p>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic12;
