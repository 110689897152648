import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat16_1 from "../../images/EdMaterial/EdMat16_1.png";
import EdMat16_2 from "../../images/EdMaterial/EdMat16_2.png";

import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic16() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 16;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button
                className="next-arrow"
                onClick={goToNextPage}
                disabled={true}
              >
                &rarr;
              </button>
            </div>
            <br></br>
            {currentLanguage === "en" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    The right cooking method for a food is very important not
                    only for the taste but also for its safety as well as for
                    its nutritional and healthy properties. There is no one
                    ideal cooking method or temperature, they vary according to
                    the type of product. Here are some little tricks to help you
                    cook correctly, depending on the recipe, the time and the
                    tools at your disposal, as well as to do your shopping
                    without wasting time, money and especially food!{" "}
                  </p>
                </p>

                <img
                  src={EdMat16_1}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Are you sure you are doing grocery shopping in the right
                    way?
                  </p>
                  <ul>
                    <li>
                      First decide what you want to serve on the table! Don't go
                      shopping without being prepared. Before going to the
                      supermarket, try to think carefully about the number of
                      meals and the number of people for each meal you will have
                      to shop for. Create a weekly plan with the lunch and
                      dinner menu for the whole family.
                    </li>
                    <li>
                      Organise a precise shopping list while you're still at
                      home, so it's easy to see what's missing from the fridge
                      or pantry. This way you can save time and, avoid buying
                      unnecessary food, you will save even food and money.
                    </li>
                    <li>
                      When you're shopping, don't buy food just because you're
                      hungry at the moment... that's why you shouldn't go into
                      shops hungry; your brain is ready to buy compulsively!
                      Stick to your homemade shopping list.
                    </li>
                    <li>
                      Fruit and vegetables should never be missing in your
                      shopping cart. Choose fresh, possibly local, and seasonal
                      products because they are more nutritious and cheaper.
                    </li>
                    <li>
                      Never forget legumes and cereals in your shopping cart; if
                      possible, remember to choose wholegrain ones.
                    </li>
                    <li>
                      Always pay attention to expiry dates, especially for foods
                      that usually have a short shelf life.
                    </li>
                    <li>
                      Frozen foods are fine too, but always pay attention to
                      transport! You should use the appropriate bags, especially
                      in the hottest months of the year.
                    </li>
                    <li>
                      Don't forget to always read prices and labels carefully,
                      at least in the essential part, such as the list of
                      ingredients and the nutritional values before buying a
                      product.
                    </li>
                    <li>
                      Do not buy food with unnecessary packaging, to avoid
                      waste... remember to think about your planet too!
                    </li>
                    <li>
                      Try to take your time to shop! The hurry is the enemy of
                      intelligent and economical shopping.
                    </li>
                    <li>
                      Once you've finished shopping, make a final check of your
                      purchases before paying, just to see if any products have
                      been bought impulsively.
                    </li>
                    <li>
                      When you get back home, put your shopping in the fridge or
                      pantry in a smart way... so that you don't run the risk of
                      expiring any food.
                    </li>
                  </ul>

                  <p>
                    Are you sure you know how to cook in a healthy way and to
                    choose the most correct cooking method?
                  </p>
                </p>
                <img
                  src={EdMat16_2}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <ul>
                    <li>
                      Always plan the menu for each meal, trying to vary the
                      food as much as possible and also experiment with new
                      recipes.
                    </li>
                    <li>
                      Check which foods should be eaten first, such as those
                      close to the expiry date, leftover foods from the previous
                      day, or highly perishable foods, such as some vegetables
                      or fresh produce.
                    </li>
                    <li>
                      Choose the best cooking method:
                      <ul>
                        <li>
                          <strong>Boiling:</strong> It allows you not to add fat
                          in favour of spices and herbs. However, be careful to
                          use as little water as possible to avoid excessive
                          loss of vitamins and minerals.
                        </li>
                        <li>
                          <strong>Steam cooking:</strong> Nutrient losses are
                          reduced and the organoleptic characteristics of the
                          food are preserved. It does not require the use of
                          cooking fat.
                        </li>
                        <li>
                          <strong>Oven baking:</strong> Nutrient losses are low,
                          especially if the oven is preheated. With a few tricks
                          (baking paper, pans made of non-stick material) it is
                          possible to reduce the use of cooking fat.
                        </li>
                        <li>
                          <strong>Microwave cooking:</strong> Cooking times are
                          reduced as well as nutrient losses. It is also
                          possible to use very little fat during cooking.
                          However, it does not allow you to cook "large" food.
                        </li>
                        <li>
                          <strong>Frying:</strong> Certainly a cooking method
                          not to be used daily. One tip is to fry in
                          sufficiently hot oil and keep the oil temperature
                          constant at all times. Always use olive oil and avoid
                          reusing already cooked oil.
                        </li>
                        <li>
                          <strong>Grill cooking:</strong> It is important to
                          avoid direct contact between food and the flame and
                          not to eat the burnt parts, which are bad for your
                          health. It is better to use non-stick griddles and to
                          control the cooking temperature.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "es" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    El método de cocción adecuado de un alimento es muy
                    importante, no sólo por su sabor sino también por su
                    seguridad, así como por sus propiedades nutricionales y
                    saludables. No existe un método ni una temperatura de
                    cocción ideales, sino que varían según el tipo de producto.
                    A continuación, te damos algunos pequeños trucos que te
                    ayudarán a cocinar correctamente, según la receta, el tiempo
                    y los utensilios a tu disposición, así como a hacer tus
                    compras sin perder tiempo, dinero y ¡sobre todo comida!
                  </p>
                </p>

                <img
                  src={EdMat16_1}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    ¿Estás seguro de que estás haciendo las compras de la manera
                    correcta?
                  </p>
                  <ul>
                    <li>
                      ¡Primero decide qué quieres servir en la mesa! No vayas de
                      compras sin estar preparado, antes de ir al supermercado
                      intenta pensar bien el número de comidas y el número de
                      personas para cada comida que tendrás que comprar, crea un
                      plan semanal con el menú de almuerzo y cena para toda la
                      familia.
                    </li>
                    <li>
                      Organiza una lista de compras precisa mientras estés en
                      casa, para que sea fácil ver lo que falta en el
                      refrigerador o la despensa. De esta forma podrás ahorrar
                      tiempo y, evitando comprar alimentos innecesarios,
                      ahorrarás incluso comida y dinero.
                    </li>
                    <li>
                      Cuando estés de compras, no compres comida sólo porque
                      tienes hambre en ese momento ... por eso no debes entrar a
                      las tiendas con hambre, ¡tu cerebro está listo para
                      comprar compulsivamente! Cíñete a tu lista de compras
                      casera.
                    </li>
                    <li>
                      Las frutas y verduras nunca deben faltar en tu carrito de
                      la compra. Elige productos frescos, posiblemente locales y
                      de temporada porque son más nutritivos y baratos.
                    </li>
                    <li>
                      Nunca olvides en tu carrito de la compra legumbres y
                      cereales, si es posible recuerda elegir opciones
                      integrales.
                    </li>
                    <li>
                      Presta siempre atención a las fechas de caducidad,
                      especialmente en alimentos que suelen tener una vida útil
                      corta.
                    </li>
                    <li>
                      Los alimentos congelados también están bien, ¡pero siempre
                      presta atención al transporte! Conviene utilizar las
                      bolsas adecuadas, sobre todo en los meses más calurosos
                      del año.
                    </li>
                    <li>
                      No olvides leer siempre atentamente los precios y las
                      etiquetas, al menos en la parte esencial, como la lista de
                      ingredientes y los valores nutricionales, antes de comprar
                      un producto.
                    </li>
                    <li>
                      No compres alimentos con envases innecesarios, para evitar
                      desperdicios… ¡recuerda pensar también en tu planeta!
                    </li>
                    <li>
                      ¡Intenta tomarte tu tiempo para comprar! Las prisas son
                      enemigas de las compras inteligentes y económicas.
                    </li>
                    <li>
                      Una vez que hayas terminado de comprar, haz un último
                      control de tus compras antes de pagar, sólo para ver si se
                      ha comprado algún producto de forma impulsiva.
                    </li>
                    <li>
                      Cuando vuelvas a casa, guarda tu compra en la nevera o en
                      la despensa de forma inteligente... para no correr el
                      riesgo de que se te caduque ningún alimento.
                    </li>
                  </ul>

                  <p>
                    ¿Estás seguro de que sabes cocinar de forma saludable y
                    elegir el método de cocción más correcto?
                  </p>
                </p>
                <img
                  src={EdMat16_2}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <ul>
                    <li>
                      Planifica siempre el menú de cada comida, intentando
                      variar al máximo la comida y también experimenta con
                      nuevas recetas.
                    </li>
                    <li>
                      Comprueba qué alimentos se deben consumir primero, como
                      los que están próximos a su fecha de caducidad, los restos
                      del día anterior o los alimentos muy perecederos, como
                      algunas verduras o productos frescos.
                    </li>
                    <li>
                      Elige el mejor método de cocción:
                      <ul>
                        <li>
                          <strong>Hervir:</strong> permite no añadir grasas en
                          favor de especias y hierbas. Sin embargo, ten cuidado
                          de utilizar la menor cantidad de agua posible para
                          evitar una pérdida excesiva de vitaminas y minerales.
                        </li>
                        <li>
                          <strong>Cocción al vapor:</strong> se reducen las
                          pérdidas de nutrientes y se conservan las
                          características organolépticas de los alimentos. No
                          requiere el uso de grasa para cocinar.
                        </li>
                        <li>
                          <strong>Hornear en horno:</strong> las pérdidas de
                          nutrientes son bajas, especialmente si se precalienta
                          el horno. Con algunos trucos (papel de horno, sartenes
                          de material antiadherente) es posible reducir el uso
                          de grasa para cocinar.
                        </li>
                        <li>
                          <strong>Cocción en microondas:</strong> se reducen los
                          tiempos de cocción y las pérdidas de nutrientes.
                          También es posible utilizar muy poca grasa durante la
                          cocción. Sin embargo, no permite cocinar grandes
                          cantidades.
                        </li>
                        <li>
                          <strong>Freír:</strong> sin duda un método de cocción
                          que no se debe utilizar a diario. Un consejo es freír
                          en aceite suficientemente caliente y mantener
                          constante la temperatura del aceite en todo momento.
                          Utiliza siempre aceite de oliva y evita reutilizar
                          aceite ya cocido.
                        </li>
                        <li>
                          <strong>Cocción a la parrilla:</strong> es importante
                          evitar el contacto directo entre los alimentos y la
                          llama y no comer las partes quemadas, que son malas
                          para la salud. Es mejor utilizar planchas
                          antiadherentes y controlar la temperatura de cocción.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </div>
            )}{" "}
            {currentLanguage === "tr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Bir gıdanın doğru pişirme yöntemi sadece lezzeti açısından
                    değil aynı zamanda güvenliği, besleyici ve sağlıklı
                    özellikleri açısından da oldukça önemlidir. İdeal bir
                    pişirme yöntemi veya sıcaklığı yoktur; bunlar ürünün türüne
                    göre değişir. İşte yemek tarifine, zamana ve elinizdeki
                    araçlara bağlı olarak doğru yemek pişirmenize, ayrıca zaman,
                    para ve özellikle yiyecek israf etmeden alışverişinizi
                    yapmanıza yardımcı olacak bazı küçük püf noktaları!
                  </p>
                </p>

                <img
                  src={EdMat16_1}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Market alışverişini doğru şekilde yaptığınızdan emin
                    misiniz?
                  </p>
                  <ul>
                    <li>
                      Önce sofrada ne servis etmek istediğinize karar verin!
                      Hazırlık yapmadan alışverişe çıkmayın, süpermarkete
                      gitmeden önce, alışveriş yapacağınız öğün sayısını ve her
                      öğün için kişi sayısını iyice düşünmeye çalışın, ailenizle
                      birlikte öğle ve akşam yemeği menüsüyle haftalık bir plan
                      oluşturun.
                    </li>
                    <li>
                      Evden çıkmadan bir alışveriş listesi düzenleyin, böylece
                      buzdolabında veya kilerde neyin eksik olduğunu görmek
                      kolaydır. Bu şekilde zamandan tasarruf edebilir ve
                      gereksiz yiyecek satın almaktan kaçınarak yiyecek ve
                      paradan bile tasarruf edebilirsiniz.
                    </li>
                    <li>
                      Alışveriş yaparken, o an aç olduğunuz için yiyecek
                      almayın... bu yüzden mağazalara aç girmemelisiniz,
                      beyniniz kompulsif olarak satın almaya hazır! Evde
                      yaptığınız alışveriş listenize sadık kalın.
                    </li>
                    <li>
                      Meyve ve sebzeler alışveriş sepetinizde asla eksik
                      olmamalıdır. Daha besleyici ve ucuz oldukları için taze,
                      yerel ve mevsimlik ürünleri seçin.
                    </li>
                    <li>
                      Alışveriş sepetinizde baklagil ve tahılları asla
                      unutmayın, mümkünse tam tahıllı olanları tercih etmeye
                      çalışın.
                    </li>
                    <li>
                      Özellikle raf ömrü genellikle kısa olan gıdalar için son
                      kullanma tarihlerine her zaman dikkat edin.
                    </li>
                    <li>
                      Dondurulmuş gıdalar da iyidir ancak taşımaya her zaman
                      dikkat edin! Özellikle yılın en sıcak aylarında uygun
                      çantaları kullanmalısınız.
                    </li>
                    <li>
                      Bir ürünü satın almadan önce fiyatlarını ve etiketlerini,
                      en azından içindekiler listesi ve besin değerleri gibi
                      önemli kısımlarını her zaman dikkatlice okumayı unutmayın.
                    </li>
                    <li>
                      İsrafı önlemek için gereksiz ambalajlı yiyecekler
                      almayın... Gezegeninizi de düşünmeyi unutmayın!
                    </li>
                    <li>
                      Alışverişe zaman ayırmaya çalışın! Acele etmek, akıllı ve
                      ekonomik alışverişin düşmanıdır.
                    </li>
                    <li>
                      Alışverişi bitirdikten sonra, herhangi bir ürünün dürtüsel
                      olarak satın alınıp alınmadığını görmek için ödeme
                      yapmadan önce satın alımlarınızı son kez kontrol edin.
                    </li>
                    <li>
                      Eve döndüğünüzde aldıklarınızı akıllıca bir şekilde
                      buzdolabına veya kilere koyun, böylece yiyeceklerin son
                      kullanma tarihi geçme riskiyle karşı karşıya kalmazsınız.
                    </li>
                  </ul>

                  <p>
                    Sağlıklı yemek pişirmeyi ve en doğru pişirme yöntemini
                    seçmeyi bildiğinizden emin misiniz?
                  </p>
                </p>
                <img
                  src={EdMat16_2}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <ul>
                    <li>
                      Her zaman her öğün için menüyü planlayın, yiyecekleri
                      mümkün olduğunca çeşitlendirmeye çalışın ve ayrıca yeni
                      tarifler deneyin.
                    </li>
                    <li>
                      Son kullanma tarihi yaklaşanlar, önceki günden kalan
                      yiyecekler, bazı sebzeler veya çabuk bozulan taze ürünler
                      gibi yiyeceklerden hangilerinin önce tüketilmesi
                      gerektiğini kontrol edin.
                    </li>
                    <li>
                      En iyi pişirme yöntemini seçin:
                      <ul>
                        <li>
                          <strong>Haşlama:</strong> baharat ve şifalı otlar
                          yerine yağ eklememenizi sağlar. Ancak aşırı vitamin ve
                          mineral kaybını önlemek için mümkün olduğunca az su
                          kullanmaya dikkat edin.
                        </li>
                        <li>
                          <strong>Buharda pişirme:</strong> Besin kayıpları
                          azaltılır ve yiyeceğin organoleptik özellikleri
                          korunur. Yemeklik yağ kullanılmasını gerektirmez.
                        </li>
                        <li>
                          <strong>Fırında pişirme:</strong> Özellikle fırın
                          önceden ısıtıldığında besin kayıpları düşüktür. Birkaç
                          püf noktasıyla (pişirme kağıdı, yapışmaz malzemeden
                          yapılmış tavalar) yemeklik yağ kullanımını azaltmak
                          mümkündür.
                        </li>
                        <li>
                          <strong>Mikrodalgada pişirme:</strong> Pişirme
                          süreleri ve besin kayıpları azalır. Pişirme sırasında
                          çok az yağ kullanmak da mümkündür. Ancak "büyük"
                          yiyecekler pişirmenize izin vermez.
                        </li>
                        <li>
                          <strong>Kızartma:</strong> Kesinlikle günlük olarak
                          kullanılmaması gereken bir pişirme yöntemidir. Bir
                          ipucu, yeterince sıcak yağda kızartmak ve yağ
                          sıcaklığını her zaman sabit tutmaktır. Her zaman
                          zeytinyağı kullanın ve önceden pişirilmiş yağı tekrar
                          kullanmaktan kaçının.
                        </li>
                        <li>
                          <strong>Izgarada pişirme:</strong> Sağlığınız
                          açısından zararlı olan yiyecek ile alevin doğrudan
                          temasından kaçınmak ve yanmış kısımları yememek
                          önemlidir. Yapışmaz ızgara kullanmak ve pişirme
                          sıcaklığını kontrol etmek daha iyidir.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </div>
            )}
            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic16;
