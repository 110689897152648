import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import imageProteins from "../../images/EdMaterial/Topic4/EdMat4_Proteins.png";
import imageVitamins from "../../images/EdMaterial/Topic4/EdMat4_Vitamins.png";
import imageFats from "../../images/EdMaterial/Topic4/EdMat4_Fats.png";
import imageMinerals from "../../images/EdMaterial/Topic4/EdMat4_Minerals.png";
import ImageCarbohydrates from "../../images/EdMaterial/Topic4/EdMat4_Carbohydrates.png";

import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic4() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  const topicNumber = 4;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };

  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    Foodstuffs contain many different nutrients, such as
                    protein, fat, carbohydrates, vitamins, and minerals!
                    Proteins, fats, and carbohydrates are indeed not the only
                    nutrients our body needs. Vitamins and minerals are also
                    included among the substances that must be intake with food.
                    Remember that the nutrients our body needs are not all
                    contained in a single food, so we need to eat all kinds of
                    foods varying as much as possible. Find out more about the
                    different nutrients!{" "}
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Proteins: </strong>
                    Proteins are composed of carbon, hydrogen, oxygen, and
                    nitrogen; and a certain amount of sulphur is also present.
                    Proteins are made up of different bricks, called amino
                    acids, linked by special bonds (peptides). Proteins are
                    indispensable to build our organs and to ensure that all the
                    mechanisms of the body work. The right amount of protein
                    should be consumed every day, without exaggeration! Proteins
                    are mostly contained in meat, fish, eggs, and milk, yoghurt,
                    and cheese. Also very important are the vegetable proteins
                    that you can find in legumes such as beans, peas, chickpeas,
                    and lentils. In addition, proteins are energetic nutrients;
                    in fact, they can be used to obtain energy in case of
                    carbohydrate deficiency, as it typically happens when we
                    stay for a long time without eating, or when the amount of
                    protein we ingest exceeds the actual needs of the body.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Carbohydrates: </strong>
                    From a nutritional point of view, carbohydrates can be
                    classified into simple sugars and complex sugars. Among the
                    most important simple sugars (monosaccharides and
                    disaccharides) are glucose, fructose, galactose, sucrose,
                    lactose, and maltose. Among the complex sugars, the one that
                    has the greatest nutritional importance is starch, typically
                    abundant in cereals, legumes, and potatoes. They are an
                    important energy source at our disposal. In fact, they
                    represent both a ready-to-use energy resource and a useful
                    reserve of energy over time. Carbohydrates should be at the
                    basis of our diet, as they provide at least 45-50% of our
                    daily energy. This does not mean that we have to eat a lot
                    of sugar; it is always better to prefer complex
                    carbohydrates. That's why their consumption is highly
                    recommended at every meal! At breakfast, lunch, and dinner
                    you can never miss bread, pasta, and cereals, preferably
                    wholemeal! However, carbohydrates are also contained in
                    fruit and legumes.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Fats: </strong>
                    The most important fats, also called lipids, from a
                    nutritional point of view are glycerides, phospholipids, and
                    sterols. Glycerides are reserve substances for animals and
                    plants and represent the most abundant lipids in food, whose
                    main function is energetic. Phospholipids, on the other
                    hand, have an essentially structural function, representing
                    the scaffolding of biological membranes. Finally, sterols,
                    including the famous cholesterol, are present in many foods,
                    especially of animal origin. Cholesterol, in addition to its
                    structural function, is used by the body to build up certain
                    types of hormones (steroids) and is the precursor of vitamin
                    D. Furthermore, fats can be divided into three groups
                    according to their structure: saturated fats, which are
                    mainly found in red meat, cured meats, butter, eggs, and
                    whole milk; polyunsaturated fats found mainly in seed oil,
                    fish, and nuts; and finally, monounsaturated fats which are
                    rich in extra virgin olive oil! Try to always prefer
                    unsaturated fats, and use olive oil as a condiment.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitamins: </strong>
                    Vitamins represent a group of very different nutrients from
                    a chemical point of view, but they have some common
                    biochemical and physiological characteristics, in fact they
                    cannot be produced directly by the organism (except in some
                    cases and in very small quantities) and do not provide
                    energy. Since the body's demand for them is small, they are
                    called micronutrients. However, vitamins are essential for
                    growth as well as for the health of our whole organism: they
                    strengthen the nervous and immune systems, increase
                    resistance to infections and help the development of
                    cognitive capacities. Vitamins are classified according to
                    their solubility in fat-soluble (vitamins A, D, E, K) and
                    water-soluble (B vitamins and vitamin C).
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Minerals: </strong>
                    Mineral salts are fundamental substances for our organism
                    and, like vitamins, they have no energy value. Minerals are
                    also called micronutrients, as the body needs them in small
                    quantities. Minerals are classified into two categories,
                    called macroelements and microelements. The macroelements
                    (calcium, phosphorus, magnesium, sodium, potassium,
                    chlorine, and sulphur) must be ingested in greater
                    quantities than the microelements (iron, manganese, copper,
                    iodine, zinc, fluorine, selenium, and chromium). Remember,
                    however, that this distinction does not indicate a greater
                    importance of the former compared to the latter; in fact,
                    each mineral has its own specific function. All mineral
                    salts are important for our body and help to keep it healthy
                    by performing various functions, in particular a
                    bioregulatory function (they participate in regulating
                    metabolic reactions, nerve impulse transmission, heartbeat,
                    muscle contraction and in controlling the body's pH) and
                    plastic function (they participate in the formation of
                    bones, teeth, and blood).
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    ¡Los alimentos contienen muchos nutrientes diferentes, como
                    proteínas, grasas, carbohidratos, vitaminas y minerales! De
                    hecho, las proteínas, las grasas y los carbohidratos no son
                    los únicos nutrientes que nuestro cuerpo necesita. Las
                    vitaminas y minerales también se incluyen entre las
                    sustancias que deben ingerirse con los alimentos. Recuerda
                    que los nutrientes que nuestro cuerpo necesita no están
                    todos contenidos en un solo alimento, por lo que debemos
                    comer todo tipo de alimentos variando lo máximo posible.
                    ¡Descubre más sobre los diferentes nutrientes!{" "}
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Proteínas: </strong>
                    las proteínas están compuestas por carbono, hidrógeno,
                    oxígeno y nitrógeno; y también está presente una cierta
                    cantidad de azufre. Las proteínas están formadas por
                    diferentes ladrillos, llamados aminoácidos, unidos por
                    enlaces especiales (péptidos). Las proteínas son
                    indispensables para construir nuestros órganos y garantizar
                    que todos los mecanismos del cuerpo funcionen. Se debe
                    consumir la cantidad adecuada de proteínas todos los días,
                    ¡sin exagerar! Las proteínas se encuentran principalmente en
                    la carne, el pescado, los huevos, la leche, el yogur y el
                    queso. También son muy importantes las proteínas vegetales
                    que puedes encontrar en legumbres como las judías, los
                    guisantes, los garbanzos y las lentejas. Además, las
                    proteínas son nutrientes energéticos, de hecho, pueden
                    utilizarse para obtener energía en caso de deficiencia de
                    carbohidratos, como suele ocurrir cuando permanecemos mucho
                    tiempo sin comer, o cuando la cantidad de proteínas que
                    ingerimos supera las necesidades reales de nuestro cuerpo.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Hidratos de carbono: </strong>
                    desde el punto de vista nutricional, los hidratos de carbono
                    se pueden clasificar en azúcares simples y azúcares
                    complejos. Entre los azúcares simples (monosacáridos y
                    disacáridos) más importantes se encuentran la glucosa, la
                    fructosa, la galactosa, la sacarosa, la lactosa y la
                    maltosa. Entre los azúcares complejos, el que tiene mayor
                    importancia nutricional es el almidón, típicamente abundante
                    en cereales, legumbres y patatas. Son una importante fuente
                    de energía a nuestra disposición. De hecho, representan a la
                    vez un recurso energético listo para usar y una reserva de
                    energía útil a lo largo del tiempo. Los carbohidratos deben
                    estar en la base de nuestra dieta, ya que aportan al menos
                    el 45-50% de nuestra energía diaria. Esto no significa que
                    tengamos que comer mucha azúcar, siempre es mejor preferir
                    los carbohidratos complejos. ¡Por eso es muy recomendable su
                    consumo en cada comida! En el desayuno, comida y cena nunca
                    puede faltar el pan, la pasta y los cereales,
                    ¡preferiblemente integrales! Sin embargo, las frutas y las
                    legumbres también contienen carbohidratos.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Grasas: </strong>
                    las grasas más importantes, también llamadas lípidos, desde
                    el punto de vista nutricional son los glicéridos, los
                    fosfolípidos y los esteroles. Los glicéridos son sustancias
                    de reserva para animales y plantas y representan los lípidos
                    más abundantes en los alimentos, cuya principal función es
                    energética. Los fosfolípidos, por otro lado, tienen una
                    función esencialmente estructural, representando el
                    andamiaje de las membranas biológicas. Por último, los
                    esteroles, entre ellos el famoso colesterol, están presentes
                    en muchos alimentos, especialmente de origen animal. El
                    colesterol, además de su función estructural, es utilizado
                    por el organismo para formar cierto tipo de hormonas
                    (esteroides) y es el precursor de la vitamina D. Además, las
                    grasas se pueden dividir en 3 grupos según su estructura:
                    grasas saturadas, que se encuentran principalmente en carnes
                    rojas y embutidos, mantequilla, huevos y leche entera;
                    grasas poliinsaturadas que se encuentran principalmente en
                    el aceite de semillas, el pescado y los frutos secos; y, por
                    último, las grasas monoinsaturadas que son ricas, por
                    ejemplo, en el aceite de oliva virgen extra. Procura
                    preferir siempre las grasas insaturadas y utiliza aceite de
                    oliva como condimento.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitaminas: </strong>
                    las vitaminas representan un grupo de nutrientes muy
                    diferentes desde el punto de vista químico, pero tienen
                    algunas características bioquímicas y fisiológicas comunes,
                    de hecho, no pueden ser producidas directamente por el
                    organismo (excepto en algunos casos y en cantidades muy
                    pequeñas) y no proporciona energía. Dado que la demanda del
                    cuerpo es pequeña, se denominan micronutrientes. Sin
                    embargo, las vitaminas son esenciales tanto para el
                    crecimiento como para la salud de todo nuestro organismo:
                    fortalecen los sistemas nervioso e inmunológico, aumentan la
                    resistencia a las infecciones y ayudan al desarrollo de las
                    capacidades cognitivas. Las vitaminas se clasifican según su
                    solubilidad en liposolubles (vitaminas A, D, E, K) e
                    hidrosolubles (vitaminas del grupo B y vitamina C).
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Minerales: </strong>
                    las sales minerales son sustancias fundamentales para
                    nuestro organismo y, al igual que las vitaminas, no tienen
                    valor energético. Los minerales también se denominan
                    micronutrientes, ya que el cuerpo los necesita en pequeñas
                    cantidades. Los minerales se clasifican en dos categorías,
                    llamados macroelementos y microelementos. Los macroelementos
                    (calcio, fósforo, magnesio, sodio, potasio, cloro y azufre)
                    deben ingerirse en mayores cantidades que los microelementos
                    (hierro, manganeso, cobre, yodo, zinc, flúor, selenio y
                    cromo). Recuerde, sin embargo, que esta distinción no indica
                    una mayor importancia del primero respecto del segundo, de
                    hecho, cada mineral tiene su función específica. Todas las
                    sales minerales son importantes para nuestro organismo y
                    ayudan a mantenerlo sano realizando diversas funciones, en
                    particular una función biorreguladora (participan en la
                    regulación de las reacciones metabólicas, la transmisión de
                    los impulsos nerviosos, los latidos del corazón, la
                    contracción muscular y en el control del pH del cuerpo) y la
                    función plástica. (participan en la formación de huesos,
                    dientes y sangre).
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    Gıda maddeleri protein, yağ, karbonhidrat, vitamin ve
                    mineral gibi birçok farklı besin maddesi içerir! Proteinler,
                    yağlar ve karbonhidratlar aslında vücudumuzun ihtiyaç
                    duyduğu tek besin değildir. Besinlerle alınması gereken
                    maddeler arasında vitamin ve mineraller de yer almaktadır.
                    Vücudumuzun ihtiyaç duyduğu besin öğelerinin tamamının tek
                    bir besinde bulunmadığını, bu nedenle her türlü besini
                    mümkün olduğunca farklı şekilde tüketmemiz gerektiğini
                    unutmayın. Farklı besinler hakkında daha fazla bilgi edinin!{" "}
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Proteinler: </strong>
                    Proteinler karbon, hidrojen, oksijen ve nitrojenden oluşur
                    ve belli miktarda kükürt de mevcuttur. Proteinler, özel
                    bağlarla (peptitler) birbirine bağlanan, amino asit adı
                    verilen farklı yapılardan oluşur. Proteinler organlarımızın
                    inşası ve vücudun tüm mekanizmalarının çalışması için
                    vazgeçilmezdir. Her gün doğru miktarda protein abartılmadan
                    tüketilmelidir! Proteinler çoğunlukla et, balık, yumurta ve
                    süt, yoğurt ve peynirde bulunur. Fasulye, bezelye, nohut ve
                    mercimek gibi baklagillerde bulabileceğiniz bitkisel
                    proteinler de çok önemlidir. Ek olarak, proteinler enerji
                    veren besinlerdir, aslında karbonhidrat eksikliği durumunda
                    enerji elde etmek için kullanılabilirler; genellikle uzun
                    süre yemek yemeden kaldığımızda veya aldığımız protein
                    miktarı vücudumuzun gerçek ihtiyacını aştığında meydana
                    gelir.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Karbonhidratlar: </strong>
                    Beslenme açısından karbonhidratlar basit şekerler ve
                    kompleks şekerler olarak sınıflandırılabilir. En önemli
                    basit şekerler (monosakkaritler ve disakkaritler) arasında
                    glikoz, fruktoz, galaktoz, sükroz, laktoz ve maltoz bulunur.
                    Kompleks şekerler arasında besin açısından en büyük öneme
                    sahip olanı, genellikle tahıllarda, baklagillerde ve
                    patateste bol miktarda bulunan nişastadır. Elimizde olan
                    önemli bir enerji kaynağıdırlar. Aslında hem kullanıma hazır
                    bir enerji kaynağını hem de zaman içinde faydalı bir enerji
                    rezervini temsil ederler. Karbonhidratlar günlük enerjimizin
                    en az %45-50'sini sağladığı için beslenmemizin temelinde yer
                    almalıdır. Bu çok fazla şeker yememiz gerektiği anlamına
                    gelmiyor, kompleks karbonhidratları tercih etmek her zaman
                    daha iyidir. Bu nedenle her öğünde tüketilmesi şiddetle
                    tavsiye edilir! Kahvaltı, öğle ve akşam yemeklerinde ekmek,
                    makarna ve tahılları, tercihen kepekli (tam tahıllı)
                    ürünleri asla eksik etmemelisiniz! Ancak meyve ve
                    baklagillerde de karbonhidrat bulunur.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Yağlar: </strong>
                    Beslenme açısından lipit olarak da adlandırılan en önemli
                    yağlar gliseritler, fosfolipitler ve sterollerdir.
                    Gliseritler hayvanlar ve bitkiler için rezerv maddelerdir ve
                    ana işlevi enerjik olan gıdalarda en çok bulunan lipitleri
                    temsil eder. Öte yandan fosfolipidler, biyolojik
                    membranların iskeletini temsil eden esas itibarıyla yapısal
                    bir işleve sahiptir. Son olarak, kolesterol de dahil olmak
                    üzere steroller, özellikle hayvansal kökenli birçok gıdada
                    mevcuttur. Kolesterol, yapısal fonksiyonunun yanı sıra vücut
                    tarafından belirli hormon türlerinin (steroidler) yapımında
                    kullanılır ve D vitamininin öncüsüdür. Ayrıca yağlar
                    yapılarına göre 3 gruba ayrılabilir: doymuş yağlar esas
                    olarak kırmızı ette ve kurutulmuş etlerde, tereyağında,
                    yumurtada ve tam yağlı sütte bulunur; çoklu doymamış yağlar
                    esas olarak tohum yağı, balık ve kuru yemişlerde bulunur ve
                    son olarak tekli doymamış yağlar sızma zeytinyağında
                    bulunur! Her zaman doymamış yağları tercih etmeye çalışın ve
                    çeşni olarak zeytinyağını kullanın.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitaminler: </strong>
                    Vitaminler, kimyasal açıdan çok farklı besin gruplarını
                    temsil eder, ancak bazı ortak biyokimyasal ve fizyolojik
                    özelliklere sahiptirler, aslında organizma tarafından
                    doğrudan üretilemezler (bazı durumlar ve çok küçük miktarlar
                    hariç) ve enerji sağlamazlar. Vücudun bunlara olan talebi az
                    olduğundan bunlara mikro besinler denir. Bununla birlikte,
                    vitaminler tüm organizmamızın sağlığı için olduğu kadar
                    büyüme için de gereklidir: sinir ve bağışıklık sistemlerini
                    güçlendirir, enfeksiyonlara karşı direnci arttırır ve
                    bilişsel kapasitelerin gelişmesine yardımcı olurlar.
                    Vitaminler, yağda çözünen (A, D, E, K vitaminleri) ve suda
                    çözünen (B vitaminleri ve C vitamini) çözünürlüklerine göre
                    sınıflandırılır.
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Mineraller: </strong>
                    Mineral tuzları organizmamız için temel maddelerdir ve
                    vitaminler gibi enerji değeri yoktur. Minerallere vücudun
                    küçük miktarlarda ihtiyaç duyması nedeniyle mikro besinler
                    de denir. Mineraller makroelementler ve mikroelementler adı
                    verilen iki kategoriye ayrılır. Makro elementler (kalsiyum,
                    fosfor, magnezyum, sodyum, potasyum, klor ve kükürt), mikro
                    elementlerden (demir, manganez, bakır, iyot, çinko, flor,
                    selenyum ve krom) daha fazla miktarlarda alınmalıdır. Ancak
                    unutmayın ki bu ayrım, birincisinin ikincisinden daha önemli
                    olduğunu göstermez; aslında her mineralin kendine özgü bir
                    işlevi vardır. Tüm mineral tuzlar vücudumuz için önemlidir
                    ve başta biyolojik düzenleme işlevi (metabolik
                    reaksiyonların düzenlenmesinde, sinir uyarılarının
                    iletiminde, kalp atışı, kas kasılmasında ve vücudun pH'ının
                    kontrolünde rol oynarlar) ve yapısal işlev (kemik, diş ve
                    kan oluşumuna katılırlar) olmak üzere çeşitli işlevleri
                    yerine getirerek vücudumuzun sağlıklı kalmasına yardımcı
                    olur.
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic4;
