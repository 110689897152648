import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import ImageTopic0 from "../../images/EdMaterial/EdMat0.png";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

function EdMaterialTopic0() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 0;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />

          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button
                className="prev-arrow"
                onClick={goToPreviousPage}
                disabled={true}
              >
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>
            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      It often happens that the word "diet" is used
                      inappropriately! Diet is too often intended as a solution
                      when there is a need to lose weight. However, being on a
                      diet does not mean adopting slimming or low-calorie
                      regimes in order to lose weight as quickly as possible,
                      putting aside our eating habits and drastically reducing
                      the amount of food we consume.{" "}
                    </p>
                  </p>
                  <img
                    src={ImageTopic0}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <p>
                      The word "diet" has a much wider meaning, it should rather
                      stand for a healthy and balanced lifestyle, destined to
                      last over time. To fully understand the meaning of this
                      term we can refer to its origin... remembering that it
                      comes from the ancient Greek term "dìaita" and means
                      regime, style, standard of living. Therefore the ancient
                      Greeks with the concept of diet already referred to a
                      correct, healthy and balanced alimentation aimed at
                      satisfying both the physiological needs of the organism
                      and the psychological and relational aspects through the
                      gratification of the senses, respect for the tradition of
                      the territory and the rhythms of daily life. Diet should
                      not be seen (as it happens a lot and too often!) as a
                      sacrifice, but as a means to live better and to take care
                      of oneself, finding a well-being that lasts over time. The
                      first requirement of a diet is certainly to provide the
                      necessary nutrients and energy supply in a well-balanced
                      and distributed manner throughout the day, but the diet
                      should also ensure psycho-physical wellbeing and health,
                      delay aging and prevent numerous pathologies.
                    </p>
                    <p>
                      {" "}
                      Remember also that there is no diet valid in all
                      situations and for all people because individual
                      variability is enormous, not only in terms of age, sex,
                      sports and work activity but also in terms of the
                      characteristics of each person that can also have a
                      significant effect on the metabolism. The diet should
                      therefore be tailored specifically to each of us.{" "}
                    </p>
                    <p>
                      {" "}
                      Today begins with us a long journey to discover the simple
                      rules of nutrition that are the basis of a healthy and
                      correct lifestyle. Follow us and you will find a lot of
                      new information to take care of your child's wellbeing and
                      that of the whole family!{" "}
                    </p>
                  </p>
                </div>
              )}
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      ¡A menudo sucede que la palabra "dieta" se usa de manera
                      inapropiada! Con demasiada frecuencia, la dieta se
                      considera una solución cuando es necesario perder peso.
                      Sin embargo, estar a dieta no significa adoptar regímenes
                      adelgazantes o hipocalóricos con el fin de perder peso lo
                      más rápido posible, dejando de lado nuestros hábitos
                      alimentarios y reduciendo drásticamente la cantidad de
                      alimentos que consumimos.
                    </p>{" "}
                  </p>
                  <img
                    src={ImageTopic0}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <p>
                      La palabra "dieta" tiene un significado mucho más amplio,
                      debería referirse más bien a un estilo de vida sano y
                      equilibrado, destinado a perdurar en el tiempo. Para
                      comprender plenamente el significado de este término
                      podemos referirnos a su origen... recordando que proviene
                      del antiguo término griego " dìaita " y significa régimen,
                      estilo, nivel de vida.{" "}
                    </p>
                    <p>
                      Por ello los antiguos griegos con el concepto de dieta ya
                      se referían a una alimentación correcta, sana y
                      equilibrada encaminada a satisfacer tanto las necesidades
                      fisiológicas del organismo como los aspectos psicológicos
                      y relacionales a través de la gratificación de los
                      sentidos, el respeto a la tradición del territorio y a los
                      ritmos de la vida diaria. La alimentación no debe verse
                      (¡como ocurre mucho y con demasiada frecuencia!) como un
                      sacrificio, sino como un medio para vivir mejor y
                      cuidarse, encontrando un bienestar que perdure en el
                      tiempo. El primer requisito de una dieta es, sin duda,
                      proporcionar de forma equilibrada y distribuida a lo largo
                      del día los nutrientes y el aporte energético necesarios,
                      pero la dieta también debe garantizar el bienestar y la
                      salud psicofísica, retrasar el envejecimiento y prevenir
                      numerosas patologías.
                    </p>
                    <p>
                      Recuerda también que no existe una dieta válida en todas
                      las situaciones y para todas las personas porque la
                      variabilidad individual es enorme, no sólo en cuanto a
                      edad, sexo, actividad deportiva y laboral sino también en
                      cuanto a las características de cada persona que también
                      pueden tener un importante efecto sobre el metabolismo.
                      Por lo tanto, la dieta debe adaptarse específicamente a
                      cada uno de nosotros.{" "}
                    </p>
                    <p>
                      {" "}
                      Recuerda también que no existe una dieta válida en todas
                      las situaciones y para todas las personas porque la
                      variabilidad individual es enorme, no sólo en cuanto a
                      edad, sexo, actividad deportiva y laboral sino también en
                      cuanto a las características de cada persona que también
                      pueden tener un importante efecto sobre el metabolismo.
                      Por lo tanto, la dieta debe adaptarse específicamente a
                      cada uno de nosotros.
                    </p>
                  </p>
                </div>
              )}
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Çoğu zaman "diyet" kelimesinin uygunsuz bir şekilde
                      kullanıldığı görülür! Kilo verme ihtiyacı duyulduğunda
                      diyet çoğu zaman bir çözüm olarak düşünülür. Ancak diyet
                      yapmak, mümkün olduğu kadar çabuk kilo vermek için
                      zayıflama veya düşük kalorili rejimler benimsemek, yeme
                      alışkanlıklarımızı bir kenara bırakmak ve tükettiğimiz
                      yiyecek miktarını büyük ölçüde azaltmak anlamına gelmez.
                    </p>{" "}
                  </p>
                  <img
                    src={ImageTopic0}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <p>
                      "Diyet" kelimesinin çok daha geniş bir anlamı vardır; daha
                      ziyade zamanla kalıcı olacak sağlıklı ve dengeli bir yaşam
                      tarzını temsil etmelidir. Bu terimin anlamını tam olarak
                      anlayabilmek için kökenine bakabiliriz. Eski Yunanca’da
                      "dìaita" teriminden gelir ve rejim, tarz, yaşam standardı
                      anlamlarını ifade eder. Bu nedenle eski Yunanlılar,
                      beslenme kavramıyla zaten organizmanın fizyolojik
                      ihtiyaçlarının yanı sıra duyuların tatmini, bölgenin
                      geleneklerine ve günlük yaşam ritimlerine saygı ve
                      kültürel değerler yoluyla psikolojik ve ilişkisel yönlerin
                      de karşılanmasını amaçlayan doğru, sağlıklı ve dengeli bir
                      beslenmeden söz ediyorlardı. Diyet (çok sık ve çok sık
                      olduğu gibi) bir fedakârlık olarak değil, daha iyi
                      yaşamanın, kendine bakmanın ve zamanla kalıcı bir refaha
                      ulaşmanın bir yolu olarak görülmelidir. Diyetin ilk şartı
                      elbette gerekli besin ve enerji tedarikinin gün içine
                      dengeli ve dağıtılmış bir şekilde sağlanmasıdır, ancak
                      diyet aynı zamanda psiko-fiziksel iyilik ve sağlığı da
                      sağlamalı, yaşlanmayı geciktirmeli ve birçok patolojiyi
                      önlemelidir. Ayrıca, her durumda ve tüm insanlar için
                      geçerli bir diyetin olmadığını unutmayın; çünkü bireysel
                      değişkenlik, yalnızca yaş, cinsiyet, spor ve iş aktivitesi
                      açısından değil, aynı zamanda her bireyin özellikleri
                      açısından da çok büyük olup, aynı zamanda bunun
                      metabolizma üzerinde de önemli bir etkisi olabilir. Bu
                      nedenle diyet her birimize özel olarak uyarlanmalıdır.
                    </p>
                    <p>
                      Bugün bizimle, sağlıklı ve doğru bir yaşam tarzının temeli
                      olan basit beslenme kurallarını keşfetmeye yönelik uzun
                      bir yolculuk başlıyor. Bizi takip ettiğinizde çocuğunuzun
                      ve tüm ailenizin sağlığını gözetecek birçok yeni bilgi
                      bulacaksınız!
                    </p>
                  </p>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic0;
