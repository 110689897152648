import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./adultsForm.css";
import { useNavigate } from "react-router-dom";
import AdultsForm from "./adultsForm";
import axios from "axios";
import jwt_decode from "jwt-decode";

function ChildCharacteristics({
  page,
  setPage,
  formData,
  setFormData,
  lockSchool,
}) {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const spainSchools = [
    // Tarragona
    "Col.legi Sant Pau, Tarragona",
    "Escola Arquitecte Pujol, Tarragona Els Pallaresos",
    "Escola la Parellada, Tarragona Santa Oliva",
    "Escola Alberich i Casas, Reus",
    "Escola Puigcerver, Reus",
    "Escola General Prim, Reus",
    "Escola Mare de Déu de la Roca, Mont-Roig del Camp",
    "Escola Martí Poch, l'Espluga de Francolí",
    "Escola Ramón Sugrañes, Almoster",
    "Escola els Baltis - Baltasar Segú, Valls",
    "Socrates Educa International School",
    "Escola Guillem Fortuny, Cambrils",
    "Escola Mas Clariana, Cambrils",
    // Barcelona
    "Escola Vedruna, Barcelona Gràcia",
    "Escola Rius i Taulet, Barcelona Gràcia",
    "Escola Reina Violant, Barcelona Gràcia",
    "Institut Escola Projecte, Barcelona Sarrià-Sant Gervasi",
    "Escola Orlandai, Barcelona Sarrià-Sant Gervasi",
    "Escola Els Arcs, Barcelona Sarrià-Sant Gervasi",
    "Escola Josep Guinovart, Barcelona Castelldefels",
    "Escola Thau, Barcelona Les Corts",
    "Escola Poblenou, Barcelona Sant Martí",
    "Centre d'Estudis Montseny, Barcelona Sant Martí",
    "Escola Gravi, Barcelona",
    "Escola La Sagrera, Barcelona",
    "IEA Oriol Martorell, Barcelona",
    "Escola Les Parellades, Barcelona",
    "Escola la Serra - Espai Obert, Sant Pere de Ribes",
    "Institut Escola Eixample, Barcelona l'Eixample",
    "Escola La Maquinista, Barcelona Sant Andreu",
    "Escola FEDAC, Barcelona Monistrol",
    "Escola FEDAC, Barcelona Sant Vicenç de Castellet",
    "Escola Sant Vicenç, Barcelona Sant Vicenç de Castellet",
    "Col.legi Pare Enric d'Ossó, Barcelona L'Hospitalet de Llobregat",
    "Escola Torre Balldovina, Barcelona Santa Coloma de Gramenet",
    "Escola la Salle Horta, Barcelona Horta-Guinardó",
    "Escola Pins del Vallès, Sant Cugat del Vallés",
    "Escola Turó de Guiera, Cerdanyola del Vallés",
    "Agrupació Escolar Anselm Clavé, Mollet del Vallés",
    "Escola Elisa Badía, Barberà del Vallés",
    "Escola Vedruna, Vilafranca del Penedès",
    "Escola Garcia Fossas, Igualada",
    "Escola Argentona, Argentona",
  ];

  const turkeySchools = [
    // BURSA OSMANGAZİ CITY
    "SEHİT JANDARMA ER SAMET AKDENİZ PRİMARY SCHOOL",
    "23 NİSAN SECONDARY SCHOOL",
    // BURSA KARACABEY CITY
    "İSTİKLAL SECONDARY SCHOOL",
    "BORSA İSTANBUL VOCATIONAL TECHNICAL AND ANATOLIAN HIGH SCHOOL",
  ];

  const moroccoSchools = [""];

  const lockSchoolOpt = [formData.school_name];

  useEffect(() => {
    console.log(formData);
    if (
      formData.school_name !== "" &&
      formData.group_age !== "" &&
      formData.child_milk !== ""
    ) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  });

  // const handleSchoolSelection = (school) => {
  //   setFormData({ ...formData, school_name: school });
  //   setSchoolSelection(true);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fetchData = async () => {
      if (formData.profile === "child") {
        try {
          const response = await axios.post(
            `${user.user_id}/createChildProfile`,
            {
              formData,
            }
          );
          if (response.status === 200) {
            navigate("/accounts");
          }
        } catch {}
      }
    };

    fetchData();
  };

  // const handleChange = (input) => {
  //   setSchoolList([]);
  //   setSchoolSelection(false);
  //   if (input === "") {
  //     setSchoolList([]);
  //   } else {
  //     regions.forEach((region) => {
  //       if (region.toLowerCase().includes(input.toLowerCase())) {
  //         setSchoolList((prevList) => [...prevList, region]);
  //       }
  //     });
  //   }
  // };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="ruser-profile-font">{t("school_name")}</div>
        <div className="section-display">
          {/* <label className="ruser-font">{t("school_name")}:</label> */}
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={
              lockSchool
                ? lockSchoolOpt
                : formData["country"] === "Spain"
                ? spainSchools
                : formData["country"] === "Turkey"
                ? turkeySchools
                : moroccoSchools
            }
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              setFormData({ ...formData, school_name: newInputValue });
            }}
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            //   console.log(value);
            // }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Search")}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          {/* <input
            type="text"
            value={schoolSelection ? formData.school_name : searchInput}
            onChange={(event) => {
              handleChange(event.target.value);
              setSearchInput(event.target.value);
            }}
            className="upline-field"
          ></input>
          <div className="results-list">
            {!schoolSelection ? (
              schoolsList.length > 0 ? (
                schoolsList.map((school, index) => (
                  <div
                    key={index}
                    onClick={() => handleSchoolSelection(school)}
                  >
                    {school}
                  </div>
                ))
              ) : (
                <div>{searchInput.length > 0 ? t("no_schools_found") : ""}</div>
              )
            ) : null}
          </div> */}
        </div>

        {formData.country === "Spain" && (
          <>
            <div className="ruser-profile-font">{t("Group Age")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.group_age}
                onChange={(event) =>
                  setFormData({ ...formData, group_age: event.target.value })
                }
                autoWidth
                label={t("Age")}
              >
                {/* Menu Items specific to Spain */}
                <MenuItem value={"3-6"}>3-6</MenuItem>
                <MenuItem value={"7-12"}>7-11</MenuItem>
                <MenuItem value={"13-15"}>12-15</MenuItem>
                <MenuItem value={"16-17"}>16-17</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        {formData.country === "Morocco" && (
          <>
            <div className="ruser-profile-font">{t("Group Age")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.group_age}
                onChange={(event) =>
                  setFormData({ ...formData, group_age: event.target.value })
                }
                autoWidth
                label={t("Age")}
              >
                {/* Menu Items specific to Spain */}
                <MenuItem value={"3-6"}>3-6</MenuItem>
                <MenuItem value={"7-12"}>7-11</MenuItem>
                <MenuItem value={"13-15"}>12-15</MenuItem>
                <MenuItem value={"16-17"}>16-17</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        {formData.country === "Turkey" && (
          <>
            <div className="ruser-profile-font">{t("Group Age")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.group_age}
                onChange={(event) =>
                  setFormData({ ...formData, group_age: event.target.value })
                }
                autoWidth
                label={t("Age")}
              >
                {/* Menu Items specific to Turkey */}
                <MenuItem value={"3-6"}>3-6</MenuItem>
                <MenuItem value={"7-9"}>7-9</MenuItem>
                <MenuItem value={"10-17"}>10-11</MenuItem>
                <MenuItem value={"10-17"}>12-17</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        {/* <div className="ruser-profile-font">{"Group Age"}</div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "3-6"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "3-6" });
              }}
              className="user-font"
            />{" "}
            {"3-6"}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "7-12"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "7-12" });
              }}
              className="user-font"
            />{" "}
            {"7-12"}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "13-15"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "13-15" });
              }}
              className="user-font"
            />{" "}
            {"13-15"}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "16-18"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "16-18" });
              }}
              className="user-font"
            />{" "}
            {"16-18"}
          </label>
        </div> */}
        <div className="ruser-profile-font">{t("milk_allergy")}</div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="milk_allergy"
              value={formData.child_milk}
              checked={formData.child_milk === true}
              onChange={(event) => {
                setFormData({ ...formData, child_milk: true });
              }}
              className="user-font"
            />{" "}
            {t("yes")}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="milk_allergy"
              value={formData.child_milk}
              checked={formData.child_milk === false}
              onChange={(event) => {
                setFormData({ ...formData, child_milk: false });
              }}
              className="user-font"
            />{" "}
            {t("no")}
          </label>
        </div>
        {/* <div className="ruser-profile-font">{t("child_snack")}</div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="child_snack"
              value={formData.child_snack}
              checked={formData.child_snack === true}
              onChange={(event) => {
                setFormData({ ...formData, child_snack: true });
              }}
              className="user-font"
            />{" "}
            {t("yes")} <em style={{ fontSize: "small" }}>{t("yes_des")}</em>
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="child_snack"
              value={formData.child_snack}
              checked={formData.child_snack === false}
              onChange={(event) => {
                setFormData({ ...formData, child_snack: false });
              }}
              className="user-font"
            />{" "}
            {t("no")} <em style={{ fontSize: "small" }}>{t("no_des")}</em>
          </label>
        </div> */}
        <div className="nav-bar">
          <button
            className="form-button"
            onClick={() => {
              setPage(1);
            }}
          >
            {t("Prev")}
          </button>
          <button
            className={activeButton ? "form-button" : "form-inactive-button"}
            type="submit"
          >
            {t("Submit")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChildCharacteristics;
