import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "./adultsForm.css";
import { color } from "framer-motion";

function Country({ page, setPage, formData, setFormData, lockOptions }) {
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const navigate = useNavigate();

  const [spain, setSpain] = useState(false);
  const [turkey, setTurkey] = useState(false);
  const [morocco, setMorocco] = useState(false);
  const [countryLanguageCode, setCountryLanguageCode] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const { t } = useTranslation();

  const languages = {
    en: {
      name: t("English"),
    },
    fr: {
      name: t("French"),
    },
    sp: {
      name: t("Spanish"),
    },
    tr: {
      name: t("Turkish"),
    },
  };

  useEffect(() => {
    //console.log(formData);
    switch (formData.country) {
      case "Spain":
        // Action for Spain cuisine
        setSpain(true);
        setTurkey(false);
        setMorocco(false);
        break;
      case "Turkey":
        // Action for Turkey cuisine
        setSpain(false);
        setTurkey(true);
        setMorocco(false);
        break;
      case "Morocco":
        // Action for Morocco cuisine
        setSpain(false);
        setTurkey(false);
        setMorocco(true);
        break;
      default:
        setSpain(false);
        setTurkey(false);
        setMorocco(false);
    }
    if (
      formData.country !== "" &&
      formData.countryLanguageCode !== "" &&
      formData.intervention_guestion_1 !== "" &&
      formData.intervention_guestion_2 !== ""
    ) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData["profile"] === "adult") {
      setPage(page + 1);
    }
    if (formData["profile"] === "child") {
      setPage(4);
    }
  };

  // // Function to handle button click
  // const handleCuisineLanguage = (formData.country) => {
  //   console.log(country);
  //   // Perform action based on the selected country
  //   switch (country) {
  //     case "Spain":
  //       // Action for Spain cuisine
  //       setSpain(true);
  //       setTurkey(false);
  //       setMorocco(false);
  //       break;
  //     case "Turkey":
  //       // Action for Turkey cuisine
  //       setSpain(false);
  //       setTurkey(true);
  //       setMorocco(false);
  //       break;
  //     case "Morocco":
  //       // Action for Morocco cuisine
  //       setSpain(false);
  //       setTurkey(false);
  //       setMorocco(true);
  //       break;
  //   }
  // };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <br></br>
        {!lockOptions && (
          <div className="attention">{t("country_update_message")}</div>
        )}
        {formData.profile === "adult" && (
          <>
            <div className="ruser-profile-font">
              {t("intervention_question_1")}
            </div>
            <div className="section-display">
              <label style={{ display: "block" }}>
                <input
                  type="radio"
                  name="intervention_question_1"
                  value={formData.intervention_question_1}
                  checked={formData.intervention_question_1 === "yes"}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      intervention_question_1: "yes",
                    })
                  }
                  disabled={lockOptions}
                />{" "}
                {t("yes")}
              </label>
              <label style={{ display: "block" }}>
                <input
                  type="radio"
                  name="intervention_question_1"
                  value={formData.intervention_question_1}
                  checked={formData.intervention_question_1 === "no"}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      intervention_question_1: "no",
                    })
                  }
                  disabled={lockOptions}
                />{" "}
                {t("no")}{" "}
              </label>
            </div>
            <div className="ruser-profile-font">
              {t("intervention_question_2")}
            </div>
            <div className="section-display">
              <label style={{ display: "block" }}>
                <input
                  type="radio"
                  name="intervention_question_2"
                  value={formData.intervention_question_2}
                  checked={formData.intervention_question_2 === "yes"}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      intervention_question_2: "yes",
                    })
                  }
                  disabled={lockOptions}
                />{" "}
                {t("yes")}
              </label>
              <label style={{ display: "block" }}>
                <input
                  type="radio"
                  name="intervention_guestion_2"
                  value={formData.intervention_question_2}
                  checked={formData.intervention_question_2 === "no"}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      intervention_question_2: "no",
                    })
                  }
                  disabled={lockOptions}
                />{" "}
                {t("no")}{" "}
              </label>
            </div>
          </>
        )}

        <div className="ruser-profile-font">
          {t("Choose_national_cuisine")}:
        </div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="country"
              value={formData.country}
              checked={formData.country === "Spain"}
              // onChange={(event) => {
              //   const userConfirmed = window.confirm(
              //     t("country_update_message")
              //   );
              //   if (userConfirmed) {
              //     setFormData({ ...formData, country: "Spain" });
              //   }
              // }}
              onChange={(event) => {
                setFormData({ ...formData, country: "Spain" });
              }}
              disabled={lockOptions}
              // onClick={(event) => handleCuisineLanguage(event.target.value)}
              className="user-font"
            />{" "}
            {t("Spain")}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="country"
              value={formData.country}
              checked={formData.country === "Turkey"}
              // onChange={(event) => {
              //   const userConfirmed = window.confirm(
              //     t("country_update_message")
              //   );
              //   if (userConfirmed) {
              //     setFormData({ ...formData, country: "Turkey" });
              //   }
              // }}
              onChange={(event) => {
                setFormData({ ...formData, country: "Turkey" });
              }}
              disabled={lockOptions}
              // onClick={(event) => handleCuisineLanguage(event.target.value)}
              className="user-font"
            />{" "}
            {t("Turkiye")}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="country"
              value={formData.country}
              checked={formData.country === "Morocco"}
              // onChange={(event) => {
              //   const userConfirmed = window.confirm(
              //     t("country_update_message")
              //   );
              //   if (userConfirmed) {
              //     setFormData({ ...formData, country: "Morocco" });
              //   }
              // }}
              onChange={(event) => {
                setFormData({ ...formData, country: "Morocco" });
              }}
              disabled={lockOptions}
              // onClick={(event) => handleCuisineLanguage(event.target.value)}
              className="user-font"
            />{" "}
            {t("Morocco")}
          </label>
        </div>
        <div>
          {formData.country && (
            <div>
              <div className="ruser-profile-font">{t("daily_plans")}:</div>
              <div className="section-display">
                {spain && (
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="countryLanguage"
                      value={formData.countryLanguageCode}
                      checked={formData.countryLanguageCode === "sp"}
                      onChange={(event) => {
                        setFormData({ ...formData, countryLanguageCode: "sp" });
                      }}
                      className="user-font"
                    />{" "}
                    {languages["sp"]?.name || ""}
                  </label>
                )}
                {turkey && (
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="countryLanguage"
                      value={formData.countryLanguageCode}
                      checked={formData.countryLanguageCode === "tr"}
                      onChange={(event) => {
                        setFormData({ ...formData, countryLanguageCode: "tr" });
                      }}
                      className="user-font"
                    />{" "}
                    {languages["tr"]?.name || ""}
                  </label>
                )}
                {morocco && (
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="countryLanguage"
                      value={formData.countryLanguageCode}
                      checked={formData.countryLanguageCode === "fr"}
                      onChange={(event) => {
                        setFormData({ ...formData, countryLanguageCode: "fr" });
                      }}
                      className="user-font"
                    />{" "}
                    {languages["fr"]?.name || ""}
                  </label>
                )}
                <label style={{ display: "block" }}>
                  <input
                    type="radio"
                    name="countryLanguage"
                    value={formData.countryLanguageCode}
                    checked={formData.countryLanguageCode === "en"}
                    onChange={(event) => {
                      setFormData({ ...formData, countryLanguageCode: "en" });
                    }}
                    className="user-font"
                  />{" "}
                  {t("English")}
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="nav-bar">
          <button
            className="form-button"
            onClick={() => {
              if (formData["profile"] === "adult") {
                setPage(page - 1);
              }
              if (formData["profile"] === "child") {
                setPage(page - 1);
              }
            }}
          >
            {t("Prev")}
          </button>
          <button
            className={activeButton ? "form-button" : "form-inactive-button"}
            type="submit"
          >
            {t("Next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Country;
