import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "./adultsForm.css";

function Allergies({ page, setPage, formData, setFormData }) {
  const { t } = useTranslation();

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(formData);
    const fetchData = async () => {
      if (formData.profile === "adult") {
        try {
          const response = await axios.post(
            `${user.user_id}/createUserProfile`,
            {
              formData,
            }
          );
          if (response.status === 200) {
            // console.log(response.data.username);
            try {
              const response2 = await axios.post(
                "userProfileFirstHistory/" + user.user_id + "/",
                {
                  formData,
                }
              );
              if (response2.status === 200) {
                navigate("/accounts");
              }
            } catch {}
          }
        } catch {}
      }
    };

    fetchData();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <br></br>
          {formData.country == "Turkey" && (
            <div className="attention">{t("tr_milk_allergy")}</div>
          )}
          <div className="ruser-profile-font">{t("Allergies")}</div>
          <div className="section-display">
            <div className="checkbox-options">
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.dairy}
                    onChange={(event) =>
                      setFormData({ ...formData, dairy: !formData.dairy })
                    }
                    disabled={formData.country == "Turkey"}
                  />{" "}
                  {t("Dairy")}
                </label>
              </div>
              {/* <div>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.eggs}
                    onChange={(event) =>
                      setFormData({ ...formData, eggs: !formData.eggs })
                    }
                  />{" "}
                  {t("Eggs")}
                </label>
              </div> */}
              {/* <div>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.fish}
                    onChange={(event) =>
                      setFormData({ ...formData, fish: !formData.fish })
                    }
                  />{" "}
                  {t("Fish/Seafood")}
                </label>
              </div> */}
              {/* <div>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.nuts}
                    onChange={(event) =>
                      setFormData({ ...formData, nuts: !formData.nuts })
                    }
                  />{" "}
                  {t("Nuts")}
                </label>
              </div> */}
            </div>
          </div>
        </div>

        {/* <div className="ruser-profile-font">{t("Dietary_Choices")}</div>
        <div className="section-display">
          <div className="checkbox-options">
            <label>
              <input
                type="checkbox"
                checked={formData.halal}
                onChange={(event) =>
                  setFormData({ ...formData, halal: !formData.halal })
                }
              />{" "}
              {t("Halal")}
            </label>
          </div>
        </div> */}

        <div className="nav-bar">
          <button
            className="form-button"
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {t("Prev")}
          </button>
          <button className="form-button" type="submit">
            {t("Submit")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Allergies;
