import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import imageButter from "../../images/EdMaterial/Topic5/EdMat5_Butter.png";
import imageCereals from "../../images/EdMaterial/Topic5/EdMat5_Cereals.png";
import imageCheese from "../../images/EdMaterial/Topic5/EdMat5_Cheese.png";
import imageEggs from "../../images/EdMaterial/Topic5/EdMat5_Eggs.png";
import imageFish from "../../images/EdMaterial/Topic5/EdMat5_Fish.png";
import imageFruits from "../../images/EdMaterial/Topic5/EdMat5_Fruits.png";
import imageHerbs from "../../images/EdMaterial/Topic5/EdMat5_Herbs.png";
import imageLegumes from "../../images/EdMaterial/Topic5/EdMat5_Legumes.png";
import imageMeat from "../../images/EdMaterial/Topic5/EdMat5_Meat.png";
import imageMilk from "../../images/EdMaterial/Topic5/EdMat5_Milk.png";
import imageNuts from "../../images/EdMaterial/Topic5/EdMat5_Nuts.png";
import imageOil from "../../images/EdMaterial/Topic5/EdMat5_Oil.png";
import imagePotato from "../../images/EdMaterial/Topic5/EdMat5_Potato.png";
import imageSpices from "../../images/EdMaterial/Topic5/EdMat5_Spices.png";
import imageVegetables from "../../images/EdMaterial/Topic5/EdMat5_Vegetables.png";
import imageWater from "../../images/EdMaterial/Topic5/EdMat5_Water.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function EdMaterialTopic5() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  const topicNumber = 5;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };

  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };

  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    Varying the diet means choosing different foods, building a
                    complete, balanced and healthy eating pattern at 360
                    degrees, which means able to provide numerous benefits both
                    physically and psychologically, through flavours, colours
                    and smells, avoiding monotony and above all preventing
                    nutritional deficiencies and imbalances. In fact, in order
                    to function at its best and produce the energy needed to
                    perform all its functions, our body needs different
                    nutrients that are provided by food. In fact, food must
                    ensure us both the right amount of energy, and this is
                    mainly provided by fats and carbohydrates and, to a lesser
                    extent, by proteins, as well as nutrients indispensable to
                    the body, such as certain types of amino acids and fatty
                    acids (which for this reason are called "essential"),
                    vitamins, minerals, water and fibre. However, we must always
                    bear in mind that there is no food that can be defined as
                    "complete", i.e. that contains all the necessary substances
                    and in the right quantity and is therefore able to satisfy
                    our nutritional needs on its own. This is why a healthy and
                    balanced diet must combine different foods with different
                    nutritional characteristics. It is essential to vary the
                    sources of nutrients as much as possible, just as it is
                    equally essential to do it correctly, choosing different
                    foods every day within the same food group but not including
                    more foods from the same group in the same meal, unless the
                    portions are properly adjusted. A great help in choosing
                    which foods to eat is represented by the food groups into
                    which similar foods are aggregated on the basis of the
                    nutrients they are the main source of. Let's find out
                    together which food groups and how to eat them:{" "}
                  </p>
                  <p className="TopicDescription-font">
                    <strong>GROUP 1: CEREALS, DERIVATIVES AND TUBERS</strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    In this food group you can find bread, pasta, rice,
                    couscous, but also many other grains such as oats, barley,
                    spelt, corn, millet, quinoa, ... They can be prepared in
                    many different and tasty ways. Cereals primarily contain
                    starch, the complex carbohydrate that must be the basis of
                    our diet. It is recommended to consume cereals and their
                    integral derivatives, as they are richer in dietary fibre
                    and certain nutrients (such as magnesium, phosphorous, iron,
                    etc.). Tubers, on the other hand, include potatoes. However,
                    tubers should be eaten in moderation because of their high
                    carbohydrate content and the consumption of fried potatoes
                    in particular should be restricted!
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageCereals} />
                    <img src={imagePotato} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GROUP 2: FRESH FRUIT </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    The foods of this group, thanks to their great variety,
                    allow the widest choice in every season, and they should
                    always be present on our table, even as snacks. They are in
                    fact foods rich in water, fibre, vitamins, minerals and
                    phenolic compounds, and are low in calories. We should try
                    to consume 2-3 portions of them every day, preferring
                    seasonal and typical products of our territory, trying to
                    change the colour of the fruits we eat every time. It is
                    advisable, instead, to limit the consumption of preserved
                    and candied fruit because it is rich in sugar. Also the
                    consumption of dried fruit should be limited to special
                    occasions!{" "}
                  </p>
                  <img
                    src={imageFruits}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 3: NUTS AND OILY FRUIT </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Unlike fresh fruit, rich in water, the main component of
                    nuts and oily fruit are fats. Within this food group are
                    nuts, such as walnuts, almonds, hazelnuts, pistachios,
                    peanuts and cashews, while avocados, coconuts and olives are
                    examples of oily fruit. Foods belonging to this food group
                    can be consumed even often but in smaller portions because
                    of the high lipid content and always remember that they do
                    not replace the portions of fresh fruit that we should eat
                    every day!{" "}
                  </p>
                  <img
                    src={imageNuts}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 4: VEGETABLES </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    As in the case of fresh fruit, vegetables are extremely
                    varied foods with different nutritional properties! They are
                    foods rich in water, fibre, vitamins, minerals and phenolic
                    compounds, and are low in calories. Vegetables should never
                    be missing at lunch and dinner but can also be eaten as a
                    snack, for example carrots or fennel are easy to carry and
                    very tasty! As said for fruit, we should try to prefer local
                    and seasonal products, trying to vary the colour of the
                    products we eat each time. On the other hand, it is
                    advisable to limit the consumption of preserved vegetables
                    as they are rich in salt and/or fat.{" "}
                  </p>
                  <img
                    src={imageVegetables}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 5: MEAT </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Within the meat group we find white meat, such as chicken,
                    turkey and rabbit, red meat, such as veal, beef, pork,
                    horse, lamb and sheep, and processed meat, such as cold
                    cuts, salami and sausages. Meat is mainly a source of
                    protein and some micronutrients such as iron and B vitamins.
                    Among the meats, white meat and lean, non-preserved meat are
                    preferable. The consumption of red, fatty and processed
                    meats (such as sausages) should be reduced both in frequency
                    and quantity.{" "}
                  </p>
                  <img
                    src={imageMeat}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 6: FISH </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    In addition to meat, many other food groups are excellent
                    sources of protein. These include fish. The food group of
                    fish is composed not only of fish itself, but also of
                    molluscs, such as cuttlefish, squid, octopuses and squids,
                    and crustaceans, such as shrimps, prawns and lobsters. Among
                    the various types of fish it is advisable to eat preferably
                    blue fish, small- to medium-sized fish such as anchovies,
                    mackerel, sardines and herrings, or fish with a high content
                    of Omega 3 polyunsaturated fatty acids, such as salmon. On
                    the contrary, preserved or transformed fish should be eaten
                    as little as possible because of the high fat and salt
                    content.
                  </p>
                  <img
                    src={imageFish}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 7: EGGS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Eggs provide protein, but also fat, vitamins and minerals.
                    For eggs, an ideal consumption for healthy people is that of
                    an egg twice a week. However, it is important to reduce
                    saturated fats and limit cholesterol in the diet and thus
                    also consider eggs as ingredients in recipes (e.g. egg
                    pasta, biscuits, cakes, etc.).{" "}
                  </p>
                  <img
                    src={imageEggs}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 8: MILK AND DAIRY PRODUCTS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    The most consumed milk is usually cow's milk, but there are
                    also milk obtained from goat, sheep, or donkey. From the
                    milk processing you can then obtain different products such
                    as yoghurt, ricotta, fresh and mature cheeses. Like milk,
                    its derivatives are also excellent sources of protein and
                    calcium but can also have high values of fat and salt, as in
                    the case of mature cheeses. For daily use, therefore,
                    semi-skimmed milk and yoghurt and fresh and less fatty
                    cheeses are preferred.
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageMilk} />
                    <img src={imageCheese} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GROUP 9: LEGUMES </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Within the group of legumes there are peas, chickpeas,
                    beans, lentils, broad beans and soya beans. Although they
                    are of vegetable origin, legumes, in addition to significant
                    amounts of starch and dietary fibre, provide significant
                    amounts of protein, iron and other oligoelements that are
                    typical of meat, fish and eggs. Legumes are a good
                    substitute for foods of animal origin in terms of protein
                    content, but it is a good rule to always combine them with
                    pasta or cereals to improve the protein profile.
                  </p>
                  <img
                    src={imageLegumes}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 10: SEASONING FATS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    The consumption of food belonging to this group must be
                    moderate, because fats are a concentrated source of energy.
                    Compared to those of animal origin, such as butter, cream,
                    lard, etc., olive oil, especially extra virgin olive oil,
                    should be the main seasoning fat to be used because of its
                    high nutritional quality, which has been associated with a
                    positive effect on blood fat levels and the cardiovascular
                    system. It is therefore good to prefer oils of vegetable
                    origin, especially extra virgin olive oil, but without ever
                    exceeding in quantity.{" "}
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageOil} />
                    <img src={imageButter} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GROUP 11: BEVERAGES </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Within the beverages group you will find all those products
                    that are drinkable during the day. The most important and
                    fundamental beverage for our organism is water, the only
                    essential drink that should be consumed during meals and
                    beyond. You should remember to drink often during the day
                    and not wait to feel thirsty in order to drink, especially
                    when it is hot or when you do a lot of physical activity.
                  </p>
                  <img
                    src={imageWater}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GROUP 12: SPICES AND HERBS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Within this food group you will find the most varied
                    products in terms of colour, shape, aroma, texture, etc..
                    Knowing all the spices and aromatic herbs is very difficult,
                    some of them are more popular, such as basil, parsley,
                    rosemary, sage, oregano and chilly pepper, while others are
                    more particular and exotic, such as turmeric, cinnamon,
                    curry and ginger. Have fun discovering how to use and match
                    them in your dishes, they are an excellent ally to give
                    flavour to our recipes making them special, while reducing
                    salt!{" "}
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageSpices} />
                    <img src={imageHerbs} />
                  </div>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    Variar la dieta significa elegir diferentes alimentos,
                    construir un patrón alimentario completo, equilibrado y
                    saludable en 360 grados, en otras palabras, que sea capaz de
                    aportar numerosos beneficios tanto a nivel físico como
                    psicológico, a través de sabores, colores y olores, evitando
                    la monotonía y sobre todo previniendo deficiencias y
                    desequilibrios nutricionales. De hecho, para funcionar de la
                    mejor manera y producir la energía necesaria para realizar
                    todas sus funciones, nuestro cuerpo necesita diferentes
                    nutrientes que son aportados por los alimentos. De hecho, la
                    alimentación debe asegurarnos a la vez la cantidad adecuada
                    de energía, y esta nos la aportan principalmente las grasas
                    y los hidratos de carbono y, en menor medida, las proteínas,
                    así como nutrientes indispensables para el organismo, como
                    determinados tipos de aminoácidos y ácidos grasos (que por
                    ello se denominan “esenciales”), vitaminas, minerales, agua
                    y fibra. Sin embargo, siempre debemos tener en cuenta que no
                    existe ningún alimento que pueda definirse como "completo",
                    es decir, que contenga todas las sustancias necesarias y en
                    la cantidad adecuada y, por tanto, sea capaz de satisfacer
                    por sí solo nuestras necesidades nutricionales. Es por esto
                    por lo que una dieta sana y equilibrada debe combinar
                    diferentes alimentos con diferentes características
                    nutricionales. Es fundamental variar al máximo las fuentes
                    de nutrientes, así como es igualmente fundamental hacerlo
                    correctamente, eligiendo cada día diferentes alimentos
                    dentro de un mismo grupo de alimentos, pero no incluyendo
                    más alimentos del mismo grupo en una misma comida, a menos
                    que las porciones estén correctamente ajustadas. Una gran
                    ayuda a la hora de elegir qué alimentos comer son los grupos
                    de alimentos, en los que se agrupan alimentos similares en
                    función de los nutrientes de los que son la fuente
                    principal. Descubramos juntos qué grupos de alimentos
                    existen y cómo comerlos:{" "}
                  </p>
                  <p className="TopicDescription-font">
                    <strong>GRUPO 1: CEREALES, DERIVADOS Y TUBÉRCULOS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    En este grupo de alimentos puedes encontrar pan, pasta,
                    arroz, cuscús, pero también muchos otros cereales como
                    avena, cebada, espelta, maíz, mijo, quinoa, ... Se pueden
                    preparar de muchas maneras diferentes y sabrosas. Los
                    cereales contienen principalmente almidón, el carbohidrato
                    complejo que debe ser la base de nuestra dieta. Se
                    recomienda consumir cereales y sus derivados integrales, ya
                    que son más ricos en fibra dietética y determinados
                    nutrientes (como magnesio, fósforo, hierro, etc.). Los
                    tubérculos, por otro lado, incluyen las patatas. Sin
                    embargo, los tubérculos deben consumirse con moderación
                    debido a su alto contenido en carbohidratos y, en
                    particular, debe restringirse el consumo de patatas fritas.
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageCereals} />
                    <img src={imagePotato} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GRUPO 2: FRUTA FRESCA </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Los alimentos de este grupo, gracias a su gran variedad,
                    permiten la más amplia elección en cada época del año, y
                    deben estar siempre presentes en nuestra mesa, incluso como
                    snacks. En realidad, son alimentos ricos en agua, fibra,
                    vitaminas, minerales y compuestos fenólicos, y bajos en
                    calorías. Debemos intentar consumir 2-3 raciones de ellos
                    cada día, prefiriendo productos de temporada y típicos de
                    nuestro territorio, intentando cambiar el color de las
                    frutas que comemos en cada ocasión. Es aconsejable, en
                    cambio, limitar el consumo de frutas en conserva y
                    confitadas porque son ricas en azúcar. ¡Además el consumo de
                    frutas deshidratadas debe limitarse a ocasiones especiales!{" "}
                  </p>
                  <img
                    src={imageFruits}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 3: FRUTOS SECOS Y FRUTAS OLEOSAS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    A diferencia de la fruta fresca, rica en agua, el componente
                    principal de los frutos secos y las frutas grasas son las
                    grasas. Dentro de este grupo de alimentos se encuentran los
                    frutos secos, como las nueces, las almendras, las avellanas,
                    los pistachos, los cacahuetes y los anacardos, mientras que
                    los aguacates, los cocos y las aceitunas son ejemplos de
                    frutas oleosas. Los alimentos pertenecientes a este grupo de
                    alimentos se pueden consumir incluso con frecuencia, pero en
                    porciones más pequeñas debido al alto contenido en lípidos y
                    ¡recordemos siempre que no reemplazan las porciones de fruta
                    fresca que debemos comer todos los días!{" "}
                  </p>
                  <img
                    src={imageNuts}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 4: VERDURAS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Al igual que la fruta fresca, las verduras son alimentos muy
                    variados y con diferentes propiedades nutricionales. Son
                    alimentos ricos en agua, fibra, vitaminas, minerales y
                    compuestos fenólicos, y bajos en calorías. Las verduras
                    nunca deben faltar en el almuerzo y la cena, pero también se
                    pueden tomar como snack, por ejemplo, las zanahorias o el
                    hinojo son fáciles de llevar y ¡muy ricas! Al igual que con
                    la fruta, debemos intentar preferir productos locales y de
                    temporada, intentando variar el color de los productos que
                    comemos en cada ocasión. Por otro lado, es aconsejable
                    limitar el consumo de verduras en conserva ya que son ricas
                    en sal y/o grasas.{" "}
                  </p>
                  <img
                    src={imageVegetables}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 5: CARNE </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Dentro del grupo de las carnes encontramos las carnes
                    blancas, como el pollo, el pavo y el conejo, las carnes
                    rojas, como la ternera, el cerdo, el caballo, el cordero y
                    la oveja, y las carnes procesadas, como los embutidos, el
                    salami y las salchichas. La carne es principalmente una
                    fuente de proteínas y de algunos micronutrientes como el
                    hierro y la vitamina B. Entre las carnes, son preferibles
                    las carnes blancas y las carnes magras y sin conservas. Se
                    debe reducir el consumo de carnes rojas, grasas y procesadas
                    (como embutidos) tanto en frecuencia como en cantidad.{" "}
                  </p>
                  <img
                    src={imageMeat}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 6: PESCADO </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Además de la carne, muchos otros grupos de alimentos son
                    excelentes fuentes de proteínas. Estos incluyen pescado. El
                    grupo alimenticio de los peces está compuesto no sólo por el
                    pescado en sí, sino también por moluscos, como sepias,
                    calamares, pulpos y calamares, y crustáceos, como gambas,
                    langostinos y langostas. Entre los distintos tipos de
                    pescado se aconseja comer preferentemente pescado azul,
                    pescado de tamaño pequeño y mediano como anchoas, caballa,
                    sardinas y arenques, o pescado con un alto contenido en
                    ácidos grasos poliinsaturados Omega 3, como el salmón. Por
                    el contrario, el pescado en conserva o procesado debe
                    consumirse lo menos posible debido a su alto contenido en
                    grasas y sal.
                  </p>
                  <img
                    src={imageFish}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 7: HUEVOS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Los huevos aportan proteínas, pero también grasas, vitaminas
                    y minerales. En el caso del huevo, un consumo ideal para
                    personas sanas es el de un huevo dos veces por semana. Sin
                    embargo, es importante reducir las grasas saturadas y
                    limitar colesterol en la dieta y, por tanto, también se
                    consideran los huevos como ingredientes en las recetas (p.
                    ej., pasta al huevo, galletas, pasteles, etc.).{" "}
                  </p>
                  <img
                    src={imageEggs}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 8: LECHE Y PRODUCTOS LÁCTEOS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    La leche más consumida suele ser la de vaca, pero también
                    hay leche obtenida de cabra, oveja o burra. Del
                    procesamiento de la leche se pueden obtener diferentes
                    productos como yogur, ricota, quesos frescos y curados. Al
                    igual que la leche, sus derivados también son excelentes
                    fuentes de proteínas y calcio, pero también pueden tener
                    altos valores de grasas y sal, como en el caso de los quesos
                    curados. Para el uso diario, por tanto, se prefieren las
                    leches y yogures semidesnatados y los quesos frescos y menos
                    grasos.
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageMilk} />
                    <img src={imageCheese} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GRUPO 9: LEGUMINOSAS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Dentro del grupo de las legumbres se encuentran los
                    guisantes, garbanzos, judías, lentejas, habas y soja. Aunque
                    son de origen vegetal, las legumbres, además de importantes
                    cantidades de almidón y fibra dietética, aportan importantes
                    cantidades de proteínas, hierro y otros oligoelementos
                    propios de la carne, el pescado y los huevos. Las legumbres
                    son un buen sustituto de los alimentos de origen animal en
                    cuanto a contenido proteico, pero es una buena norma
                    combinarlas siempre con pasta o cereales para mejorar el
                    perfil proteico.
                  </p>
                  <img
                    src={imageLegumes}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 10: GRASAS PARA CONDIMENTAR </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    El consumo de alimentos pertenecientes a este grupo debe ser
                    moderado, porque las grasas son una fuente concentrada de
                    energía. Frente a los de origen animal, como mantequilla,
                    nata, manteca de cerdo, etc., el aceite de oliva,
                    especialmente el virgen extra, debe ser la principal grasa
                    condimentadora a utilizar por su alta calidad nutricional,
                    que se ha asociado con un efecto positivo sobre los niveles
                    de lípidos en sangre y el sistema cardiovascular. Por tanto,
                    es bueno preferir los aceites de origen vegetal,
                    especialmente el aceite de oliva virgen extra, pero sin
                    excederse nunca en cantidad.{" "}
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageOil} />
                    <img src={imageButter} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GRUPO 11: BEBIDAS </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Dentro del grupo de bebidas encontrarás todos aquellos
                    productos que son bebibles durante el día. La bebida más
                    importante y fundamental para nuestro organismo es el agua,
                    la única bebida imprescindible que se debe consumir durante
                    las comidas y fuera de ellas. Debes recordar beber
                    frecuentemente durante el día y no esperar a tener sed para
                    beber, especialmente cuando hace calor o cuando realizas
                    mucha actividad física.
                  </p>
                  <img
                    src={imageWater}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUPO 12: ESPECIAS Y HIERBAS</strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Dentro de este grupo de alimentos encontrarás los productos
                    más variados en cuanto a color, forma, aroma, textura, etc.
                    Conocer todas las especias y hierbas aromáticas es muy
                    difícil, algunas de ellas son más populares, como la
                    albahaca, el perejil, el romero, la salvia, el orégano o
                    guindilla, mientras que otras son más particulares y
                    exóticas, como la cúrcuma, la canela, el curry o el
                    jengibre. Diviértete descubriendo cómo utilizarlos y
                    combinarlos en tus platos, son un excelente aliado para
                    darle sabor a nuestras recetas haciéndolas especiales ¡y
                    reduciendo la sal!{" "}
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageSpices} />
                    <img src={imageHerbs} />
                  </div>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    Diyeti çeşitlendirmek, farklı yiyecekleri seçmek, 360 derece
                    tam, dengeli ve sağlıklı bir beslenme modeli oluşturmak
                    anlamına gelir; bu da tatlar, renkler ve kokular yoluyla hem
                    fiziksel hem de psikolojik olarak çok sayıda fayda
                    sağlayabilmek, monotonluktan kaçınmak ve hepsinden önemlisi
                    beslenme eksikliklerini ve dengesizliklerini önlemek
                    anlamına gelir. Aslında vücudumuzun en iyi şekilde
                    çalışabilmesi ve tüm işlevlerini yerine getirmek için
                    ihtiyaç duyduğu enerjiyi üretebilmesi için besinlerin
                    sağladığı farklı besin ögelerine ihtiyacı vardır. Aslında,
                    gıda bize doğru miktarda enerji sağlamalıdır ve bu esas
                    olarak yağlar ve karbonhidratlar tarafından ve daha az
                    ölçüde proteinlerin yanı sıra belirli amino asit türleri ve
                    yağ asitleri (bu nedenle "esansiyel" olarak
                    adlandırılırlar), vitaminler, mineraller, su ve lif gibi
                    vücut için vazgeçilmez olan besinler tarafından sağlanır.
                    Ancak "tam" olarak tanımlanabilecek, yani gerekli tüm
                    maddeleri doğru miktarda içeren ve dolayısıyla beslenme
                    ihtiyacımızı tek başına karşılayabilen hiçbir gıdanın
                    olmadığını her zaman aklımızda tutmalıyız. Bu nedenle
                    sağlıklı ve dengeli bir beslenme, farklı besin özelliklerine
                    sahip farklı gıdaları bir araya getirmelidir. Besin
                    kaynaklarını mümkün olduğunca çeşitlendirmek çok önemlidir,
                    bunu doğru şekilde yapmak da aynı derecede önemlidir; Her
                    gün aynı besin grubundan farklı besinler tercih edilebilir
                    ancak porsiyonlar uygun şekilde ayarlanmadığı sürece aynı
                    gruptan daha fazla besine aynı öğünde yer verilmemelidir.
                    Hangi gıdaların yenileceğinin seçilmesinde, benzer gıdaların
                    ana kaynağı oldukları besin değerlerine göre toplandığı gıda
                    grupları tarafından temsil edilmesi büyük bir yardım sağlar.
                    Hangi besin gruplarını ve nasıl yememiz gerektiğini hep
                    birlikte öğrenelim:{" "}
                  </p>
                  <p className="TopicDescription-font">
                    <strong>GRUP 1: TAHILLAR, TÜREVLERİ VE YUMRULAR</strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Bu besin grubunda ekmek, makarna, pirinç, kuskusun yanı sıra
                    yulaf, arpa, kılçıksız buğday, mısır, darı, kinoa gibi
                    birçok başka tahıl da bulabilirsiniz. Pek çok farklı ve
                    lezzetli şekilde hazırlanabilirler. Tahıllar öncelikle
                    diyetimizin temeli olması gereken karmaşık karbonhidrat olan
                    nişastayı içerir. Tahıllar ve bunların tamamlayıcı
                    türevlerinin diyet lifi ve bazı besin maddeleri (magnezyum,
                    fosfor, demir vb.) açısından daha zengin olması nedeniyle
                    tüketilmesi tavsiye edilir. Yumrular ise patatesleri içerir.
                    Ancak yumrular yüksek karbonhidrat içeriği nedeniyle ölçülü
                    tüketilmeli ve özellikle patates kızartmasının tüketimi
                    kısıtlanmalıdır!
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageCereals} />
                    <img src={imagePotato} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GRUP 2: TAZE MEYVELER </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Bu gruptaki yiyecekler, çok çeşitli olmaları sayesinde her
                    mevsim en geniş seçeneğe olanak tanır ve atıştırmalık olarak
                    da olsa sofralarımızda her zaman bulunmalıdır. Aslında su,
                    lif, vitamin, mineral ve fenolik bileşikler açısından
                    zengin, kalorileri düşük besinlerdir. Her gün 2-3 porsiyon
                    tüketmeye çalışmalı, mevsimine göre ve yöremize özgü
                    ürünleri tercih etmeli, yediğimiz meyvelerin rengini her
                    seferinde değiştirmeye çalışmalıyız. Konserve ve şekerlenmiş
                    meyvelerin tüketiminin sınırlandırılması tavsiye edilir
                    çünkü şeker bakımından zengindir. Ayrıca kuru meyve tüketimi
                    ise sınırlı olmalı, porsiyon miktarına dikkat ederek
                    tüketilmelidir!{" "}
                  </p>
                  <img
                    src={imageFruits}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 3: KURU YEMİŞLER</strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Su açısından zengin olan taze meyvelerin aksine,
                    kuruyemişlerin ve yağlı meyvelerin ana bileşeni yağlardır.
                    Bu besin grubunda ceviz, badem, fındık, antep fıstığı, yer
                    fıstığı ve kaju gibi sert kabuklu yemişler yer alırken
                    avokado, hindistan cevizi ve zeytin yağlı meyvelere
                    örnektir. Bu besin grubuna ait besinler, yüksek lipit
                    içeriğinden dolayı sık sık ama daha küçük porsiyonlarda
                    tüketilebilir.{" "}
                  </p>
                  <img
                    src={imageNuts}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 4: SEBZELER </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Taze meyvelerde olduğu gibi sebzeler de farklı besin
                    özelliklerine sahip son derece çeşitli besinlerdir! Su, lif,
                    vitamin, mineral ve fenolik bileşikler açısından zengin,
                    kalorisi düşük besinlerdir. Sebzeler öğle ve akşam
                    yemeklerinde asla eksik edilmemeli ancak atıştırmalık olarak
                    da yenilebilir, örneğin havuç veya rezene taşıması kolay ve
                    çok lezzetlidir! Meyvelerde de söylediğimiz gibi yerel ve
                    mevsimlik ürünleri tercih etmeye çalışmalı, yediğimiz
                    ürünlerin rengini her seferinde çeşitlendirmeye
                    çalışmalıyız. Öte yandan tuz ve/veya yağ bakımından zengin
                    oldukları için konserve sebzelerin tüketiminin
                    sınırlandırılması tavsiye edilir.{" "}
                  </p>
                  <img
                    src={imageVegetables}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 5: ET </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Et grubunda tavuk, hindi ve tavşan gibi beyaz etler; dana
                    eti, kuzu eti ve koyun gibi kırmızı etler ve söğüş, salam ve
                    sosis gibi işlenmiş etler bulunur. Et esas olarak protein,
                    demir ve B vitaminleri gibi bazı mikro besinlerin
                    kaynağıdır. Etler arasında beyaz et ve yağsız,
                    konservelenmemiş et tercih edilir. Kırmızı, yağlı ve
                    işlenmiş etlerin (sosis gibi) tüketimi hem sıklık hem de
                    miktar olarak azaltılmalıdır.{" "}
                  </p>
                  <img
                    src={imageMeat}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 6: BALIK</strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Etin yanı sıra diğer birçok besin grubu da mükemmel protein
                    kaynaklarıdır. Bunlara balık da dahildir. Balıkların besin
                    grubu sadece balıklardan değil aynı zamanda mürekkep balığı,
                    kalamar, ahtapot ve kalamar gibi yumuşakçalar ile karides ve
                    ıstakoz gibi kabuklulardan da oluşur. Çeşitli balık türleri
                    arasında tercihen lüfer, hamsi, uskumru, sardalya ve ringa
                    balığı gibi küçük ila orta boy balıklar veya somon gibi
                    yüksek Omega 3 çoklu doymamış yağ asitleri içeriğine sahip
                    balıkların tüketilmesi tavsiye edilir. Tam tersine, konserve
                    edilmiş veya dönüştürülmüş balıklar, yüksek yağ ve tuz
                    içeriğinden dolayı mümkün olduğunca az tüketilmelidir.
                  </p>
                  <img
                    src={imageFish}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 7: YUMURTA </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Yumurta proteinin yanı sıra yağ, vitamin ve mineral de
                    sağlar. Yumurta konusunda sağlıklı insanlar için ideal
                    tüketim haftada iki kez yumurta tüketimidir. Bununla
                    birlikte, diyette doymuş yağların azaltılması ve
                    kolesterolün sınırlandırılması ve dolayısıyla tariflerde
                    içerik maddesi olarak dikkate alınması önemlidir{" "}
                  </p>
                  <img
                    src={imageEggs}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 8: SÜT VE SÜT ÜRÜNLERİ </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    En çok tüketilen süt genellikle inek sütü olmakla birlikte
                    keçi, koyun ve eşekten elde edilen sütler de vardır. Sütün
                    işlenmesinden yoğurt, ricotta, taze ve olgun peynirler gibi
                    farklı ürünler elde edebilirsiniz. Süt gibi türevleri de
                    mükemmel protein ve kalsiyum kaynaklarıdır ancak olgun
                    peynirlerde olduğu gibi yüksek yağ ve tuz değerlerine de
                    sahip olabilirler. Bu nedenle günlük kullanımda yarım yağlı
                    süt ve yoğurt ile taze ve az yağlı peynirler tercih
                    edilmektedir.
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageMilk} />
                    <img src={imageCheese} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GRUP 9: BAKLİYATLAR </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Baklagiller grubunda bezelye, nohut, fasulye, mercimek,
                    bakla ve soya fasulyesi yer alır. Bitkisel kökenli
                    olmalarına rağmen baklagiller, önemli miktarda nişasta ve
                    diyet lifinin yanı sıra et, balık ve yumurtaya özgü önemli
                    miktarda protein, demir ve diğer oligoelementleri de sağlar.
                    Baklagiller, protein içeriği açısından hayvansal kökenli
                    gıdalara iyi bir alternatiftir ancak protein profilini
                    iyileştirmek için bunları her zaman makarna veya tahıllarla
                    birleştirmek iyi bir kuraldır.
                  </p>
                  <img
                    src={imageLegumes}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 10: YAĞLAR </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Bu gruba ait gıdaların tüketimi orta düzeyde olmalıdır çünkü
                    yağlar konsantre bir enerji kaynağıdır. Tereyağı, krema vb.
                    gibi hayvansal kökenli olanlarla karşılaştırıldığında
                    zeytinyağı, özellikle de sızma zeytinyağı, kan yağ
                    seviyeleri ve kardiyovasküler sistem üzerindeki olumlu
                    etkisi ile ilişkilendirilen yüksek besin kalitesi nedeniyle
                    kullanılacak ana yağ olmalıdır. Bu nedenle bitkisel kökenli
                    yağları, özellikle sızma zeytinyağını, ancak miktarını asla
                    aşmadan tercih etmek iyidir.{" "}
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageOil} />
                    <img src={imageButter} />
                  </div>
                  <p className="TopicDescription-font">
                    <strong>GRUP 11: İÇECEKLER </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    İçecek grubunda gün içerisinde içilebilen tüm ürünleri
                    bulacaksınız. Organizmamız için en önemli ve temel içecek,
                    sudur. Gün içinde sık sık su içmeyi unutmamalı, özellikle
                    sıcak havalarda ya da çok fazla fiziksel aktivite
                    yaptığınızda su içmek için susamayı beklememelisiniz.
                  </p>
                  <img
                    src={imageWater}
                    style={{ maxWidth: "12%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <strong>GRUP 12: BAHARATLAR VE BİTKİLER </strong>
                  </p>{" "}
                  <p className="TopicDescription-font">
                    Bu besin grubunda renk, şekil, aroma, doku vb. açılardan en
                    çeşitli ürünleri bulacaksınız. Tüm baharatları ve aromatik
                    bitkileri bilmek çok zordur, bazıları daha popülerdir,
                    örneğin fesleğen, maydanoz, biberiye, adaçayı, kekik ve acı
                    biber gibi; bazıları ise zerdeçal, tarçın, köri ve zencefil
                    gibi daha özel ve egzotiktir. Bunları yemeklerinizde nasıl
                    kullanacağınızı ve eşleştireceğinizi keşfederken eğlenin;
                    bunlar, tariflerimize lezzet katarak onları özel kılan ve
                    aynı zamanda tuzu azaltan mükemmel iş birlikçilerdir!{" "}
                  </p>
                  <div className="image-rowTopic5">
                    <img src={imageSpices} />
                    <img src={imageHerbs} />
                  </div>
                  <br></br>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic5;
