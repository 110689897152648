import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat13 from "../../images/EdMaterial/EdMat13.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic13() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 13;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      False beliefs, bad ideas and advice about weigh can lead
                      people to make mistakes in their eating habits and
                      lifestyle choices every day. Following a few simple tips
                      and suggestions will make it easier to avoid many mistakes
                      and eat more consciously and suitably.
                    </p>
                  </p>
                  <img
                    src={EdMat13}
                    style={{ maxWidth: "20%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <ul>
                      <li>
                        Skipping meals (especially breakfast!) does not help you
                        in losing weight... quite the opposite! It is important
                        to eat 5 meals a day, including 3 main meals and 2 light
                        snacks, one in the middle of the morning and one in the
                        afternoon, always paying attention to the quantity and
                        quality of the food you eat.
                      </li>
                      <li>
                        Eating fast and at very different times of the day is a
                        very bad habit! Eating quickly is not healthy for
                        digestion and does not help control your weight. It is
                        important to take the time and concentration needed for
                        each meal, avoiding eating standing up in front of a
                        computer or TV, for example.
                      </li>
                      <li>
                        An excess of salt is not just a matter of taste! Do not
                        salt and generally season your food before tasting it.
                        This bad habit leads the organism to receive much higher
                        amounts of sodium than it needs, increasing blood
                        pressure and the risk of diseases of the blood vessels,
                        heart, kidneys and even osteoporosis.
                      </li>
                      <li>
                        "Do-it-yourself" diets can be very dangerous. You must
                        always rely on experienced hands, while improvised and
                        "invented" diets often lead to both qualitative and
                        quantitative mistakes. In fact, not only calories
                        matter, even more important are the principles of a good
                        diet: the energy and nutritional needs vary according to
                        age, gender, and lifestyle. Before excluding certain
                        foods or completely changing your alimentation, it is
                        always better to consult a specialist; dieting is not
                        just a matter of weight, it is actually a matter of
                        health.
                      </li>
                      <li>
                        Eating disorders are not a choice or attitudes that can
                        be corrected with goodwill alone. These problems are
                        often beyond the control of those who suffer from them,
                        hence the intervention of a specialist is highly
                        recommended. When dealing with these difficulties, the
                        important thing is to keep calm, not to blame oneself,
                        and to ask for help from experienced and qualified
                        people.
                      </li>
                      <li>
                        Water is really important and can also be drunk during
                        meals! There is a strong tendency to drink less than one
                        should, this reduced water intake is a consequence of
                        the hectic days but often also of the bad idea that
                        drinking during meals is unhealthy. Try to drink
                        regularly during the day, anticipating your sense of
                        thirst.
                      </li>
                      <li>
                        If we do some physical activity, we don't have to eat
                        more than usual. Good physical activity, rather than
                        slimming down, should serve to keep and tone the lean
                        mass and to normalize blood parameters such as blood
                        sugar and cholesterol.
                      </li>
                      <li>
                        Weight loss is not useless... it is not necessarily true
                        that sooner or later we will recover all our weight.
                        Ignoring or underestimating the consequences of excess
                        weight is wrong, while it would be appropriate and
                        advisable to tackle the problem. The important thing,
                        however, is to avoid drastic and "do-it-yourself" diets,
                        but to learn how to eat correctly and appropriately
                        according to personal needs.
                      </li>
                      <li>
                        Even if you are on a diet, all foods must be included!
                        It is important to ensure that, even if you are dieting,
                        you still introduce all the foods while limiting the
                        quantities appropriately. Specifically eliminating
                        certain foods, especially without the supervision of an
                        expert, is useless. To lose weight, what makes the
                        difference is rather to reduce overall calorie intake as
                        well as increase the level of physical activity.
                      </li>
                      <li>
                        Don't eat out of meals! Whether it's a candy, a sweet, a
                        salty snack, a sweetened drink, or an alcoholic drink...
                        it's still an energy surplus, often underestimated,
                        which if repeated regularly can cause significant
                        effects over time.
                      </li>
                      <li>
                        It is not appropriate to have a very restrictive
                        attitude towards food. Throat sins are allowed from time
                        to time..... Indeed, they are often a valuable aid in
                        dealing with low-calorie diets.
                      </li>
                      <li>
                        Taking supplements on your own initiative is not a good
                        idea! Supplements do not replace a healthy and balanced
                        diet, but they can eventually complement a normal diet,
                        which must always be the main means by which we take
                        nutrients.
                      </li>
                    </ul>
                  </p>
                </div>
              )}
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Falsas creencias, malas ideas y consejos sobre el peso
                      pueden llevar a las personas a cometer errores en sus
                      hábitos alimentarios y estilos de vida cada día. Seguir
                      unos sencillos consejos y sugerencias hará que sea más
                      fácil evitar muchos errores y comer de forma más
                      consciente y adecuada.
                    </p>
                  </p>
                  <img
                    src={EdMat13}
                    style={{ maxWidth: "20%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <ul>
                      <li>
                        Saltarse comidas (¡especialmente el desayuno!) no ayuda
                        a perder peso... ¡todo lo contrario! Es importante
                        realizar 5 comidas al día, incluyendo 3 comidas
                        principales y 2 refrigerios ligeros, uno a media mañana
                        y otro a media tarde, siempre prestando atención a la
                        cantidad y calidad de los alimentos que se ingieren.
                      </li>
                      <li>
                        ¡Comer rápido y en momentos muy diferentes del día es un
                        muy mal hábito! Comer rápido no es saludable para la
                        digestión y no ayuda a controlar el peso. Es importante
                        tomarse el tiempo y la concentración necesarios para
                        cada comida, evitando comer de pie frente al ordenador o
                        la televisión, por ejemplo.
                      </li>
                      <li>
                        ¡El exceso de sal no es sólo cuestión de gustos! No
                        añadas sal a los platos y, en general, condimenta la
                        comida antes de probarla. Este mal hábito lleva al
                        organismo a recibir cantidades de sodio mucho mayores de
                        las que necesita, aumentando la presión arterial y el
                        riesgo de enfermedades de los vasos sanguíneos, del
                        corazón, de los riñones e incluso de osteoporosis.
                      </li>
                      <li>
                        Las dietas de “hazlo tú mismo” (del inglés, do it
                        yourself) pueden ser muy peligrosas. Siempre hay que
                        confiar en manos experimentadas, mientras que las dietas
                        improvisadas e "inventadas" a menudo conducen a errores
                        tanto cualitativos como cuantitativos. De hecho, no sólo
                        importan las calorías, más importantes aún son los
                        principios de una buena alimentación: las necesidades
                        energéticas y nutricionales varían según la edad, el
                        sexo y el estilo de vida. Antes de excluir ciertos
                        alimentos o cambiar completamente tu alimentación,
                        siempre es mejor consultar a un especialista. Hacer
                        dieta no es sólo una cuestión de peso, sino también una
                        cuestión de salud.
                      </li>
                      <li>
                        Los trastornos alimentarios no son una elección ni una
                        actitud que pueda corregirse únicamente con buena
                        voluntad. Estos problemas muchas veces escapan al
                        control de quien los padece, de ahí que sea muy
                        recomendable la intervención de un especialista. Ante
                        estas dificultades lo importante es mantener la calma,
                        no culparse y pedir ayuda a personas experimentadas y
                        cualificadas.
                      </li>
                      <li>
                        ¡El agua es muy importante y también se puede beber
                        durante las comidas! Existe una fuerte tendencia a beber
                        menos de lo debido, esta reducción en la ingesta de agua
                        es consecuencia de los días agitados pero muchas veces
                        también de la mala idea de que beber durante las comidas
                        no es saludable. Intenta beber regularmente durante el
                        día, anticipándote a tu sensación de sed.
                      </li>
                      <li>
                        Si realizamos alguna actividad física, no hace falta que
                        comamos más de lo habitual. Una buena actividad física,
                        más que para adelgazar, debe servir para mantener y
                        tonificar la masa magra y normalizar parámetros
                        sanguíneos como el azúcar y el colesterol en sangre.
                      </li>
                      <li>
                        Perder peso no es inútil... no es necesariamente cierto
                        que tarde o temprano recuperaremos todo nuestro peso.
                        Ignorar o subestimar las consecuencias del exceso de
                        peso es un error, aunque sería apropiado y aconsejable
                        abordar el problema. Lo importante, sin embargo, es
                        evitar dietas drásticas y de "hazlo tú mismo", sino
                        aprender a comer correcta y adecuadamente según las
                        necesidades personales.
                      </li>
                      <li>
                        Incluso si estás a dieta, ¡debes incluir todos los
                        alimentos! Es importante asegurarse de que, incluso si
                        estas a dieta, introduzcas todos los alimentos limitando
                        las cantidades adecuadamente. De nada sirve eliminar de
                        forma específica determinados alimentos, sobre todo sin
                        la supervisión de un experto. Para perder peso, lo que
                        marca la diferencia es más bien reducir la ingesta total
                        de calorías, así como aumentar el nivel de actividad
                        física.
                      </li>
                      <li>
                        ¡No comas fuera de las comidas! Ya sea un caramelo, un
                        dulce, un snack salado, una bebida azucarada o una
                        bebida alcohólica... no deja de ser un excedente de
                        energía, a menudo subestimado, que si se repite
                        regularmente puede provocar efectos importantes con el
                        tiempo.
                      </li>
                      <li>
                        No es apropiado tener una actitud muy restrictiva hacia
                        la comida. Pequeños antojos se pueden permitir de vez en
                        cuando... De hecho, suelen ser una valiosa ayuda a la
                        hora de afrontar dietas hipocalóricas.
                      </li>
                      <li>
                        ¡Tomar suplementos por iniciativa propia no es una buena
                        idea! Los suplementos no sustituyen a una dieta sana y
                        equilibrada, pero eventualmente pueden complementar una
                        dieta normal, que debe ser siempre el principal medio
                        por el que ingerimos nuestros nutrientes.
                      </li>
                    </ul>
                  </p>
                </div>
              )}{" "}
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Kiloyla ilgili yanlış inanışlar, kötü fikirler ve
                      tavsiyeler, insanların her gün beslenme alışkanlıklarında
                      ve yaşam tarzı seçimlerinde hatalar yapmasına neden
                      olabiliyor.
                    </p>
                  </p>
                  <img
                    src={EdMat13}
                    style={{ maxWidth: "20%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    Birkaç basit ipucu ve öneriye uymak birçok hatadan
                    kaçınmanızı, daha bilinçli ve doğru beslenmenizi
                    kolaylaştıracaktır.
                  </p>
                  <p className="TopicDescription-font">
                    <ul>
                      <li>
                        Öğünleri (özellikle kahvaltıyı) atlamak kilo vermenize
                        yardımcı olmaz... tam tersi! Yediğiniz yemeğin miktarına
                        ve kalitesine her zaman dikkat etmek; size uygun öğün
                        planı ile beslenmek önemlidir.
                      </li>
                      <li>
                        Hızlı ve günün çok farklı saatlerinde yemek yemek çok
                        kötü bir alışkanlıktır! Hızlı yemek sindirim açısından
                        sağlıklı değildir ve kilonuzu kontrol etmenize yardımcı
                        olmaz. Her öğün için gereken zamanı ve konsantrasyonu
                        ayırmak, örneğin bilgisayar veya televizyon karşısında
                        ayakta yemek yemekten kaçınmak önemlidir.
                      </li>
                      <li>
                        Aşırı tuz sadece bir zevk meselesi değildir! Yemeğinizi
                        tatmadan önce tuzlamayın. Bu kötü alışkanlık,
                        organizmanın ihtiyacından çok daha fazla sodyum
                        almasına, kan basıncının artmasına, damar, kalp, böbrek
                        hastalıkları ve hatta osteoporoz riskinin artmasına
                        neden olur.
                      </li>
                      <li>
                        "Kendin yap" diyetleri çok tehlikeli olabilir. Doğaçlama
                        ve "icat edilmiş" diyetler sıklıkla hem niteliksel hem
                        de niceliksel hatalara yol açarken, her zaman deneyimli
                        ellere güvenmelisiniz. Aslında sadece kalori önemli
                        değildir, daha da önemlisi iyi bir beslenmenin
                        ilkeleridir: Enerji ve beslenme ihtiyaçları yaşa,
                        cinsiyete ve yaşam tarzına göre değişir. Belirli
                        yiyecekleri hariç tutmadan veya beslenmenizi tamamen
                        değiştirmeden önce bir uzmana danışmak her zaman daha
                        iyidir. Diyet yapmak sadece kilo meselesi değil, aslında
                        sağlık meselesidir.
                      </li>
                      <li>
                        Yeme bozuklukları yalnızca iyi niyetle düzeltilebilecek
                        bir tercih ya da tutum değildir. Bu sorunlar çoğu zaman
                        kişinin kontrolü dışındadır. Bu nedenle bir uzmanın
                        müdahalesi şiddetle tavsiye edilir. Bu zorluklarla
                        uğraşırken önemli olan sakin olmak, kendini suçlamamak,
                        tecrübeli ve ehil kişilerden yardım istemektir.
                      </li>
                      <li>
                        Günlük gerekli miktarda su içmek çok önemlidir. Susuzluk
                        hissinizi tahmin ederek gün içerisinde düzenli olarak
                        içmeye çalışın.
                      </li>
                      <li>
                        Biraz fiziksel aktivite yaparsak normalden daha fazla
                        yemek yememize gerek kalmaz. İyi fiziksel aktivite,
                        zayıflamak yerine yağsız kütleyi korumaya ve
                        şekillendirmeye, kan şekeri ve kolesterol gibi kan
                        parametrelerini normalleştirmeye hizmet etmelidir.
                      </li>
                      <li>
                        Kilo vermek yararsız değildir... Er ya da geç tüm
                        kilolarımızı geri kazanacağımız doğru değildir. Aşırı
                        kilonun sonuçlarını göz ardı etmek veya hafife almak
                        yanlıştır, ancak sorunun üstesinden gelmek uygun ve
                        tavsiye edilebilir olacaktır. Ancak önemli olan, sert ve
                        "kendin yap" diyetlerinden kaçınmak, kişisel ihtiyaçlara
                        göre doğru ve uygun beslenmeyi öğrenmektir.
                      </li>
                      <li>
                        Diyet yapıyor olsanız bile tüm besinlere yer
                        vermelisiniz! Diyet yapıyor olsanız bile, miktarları
                        uygun şekilde sınırlandırarak tüm yiyecekleri
                        yediğinizden emin olmak önemlidir. Özellikle bir uzmanın
                        denetimi olmadan belirli gıdaları ortadan kaldırmak
                        hiçbir işe yaramaz. Kilo vermek için fark yaratan şey,
                        genel kalori alımını azaltmak ve fiziksel aktivite
                        düzeyini arttırmaktır.
                      </li>
                      <li>
                        Öğün dışında yemek yemeyin! İster bir şeker, ister
                        tatlı, ister tuzlu bir atıştırmalık, ister şekerli bir
                        içecek veya alkollü bir içecek olsun tüketmemeye
                        çalışın. Bu durum genellikle hafife alınan bir enerji
                        fazlasıdır ve düzenli olarak tekrarlanırsa zaman içinde
                        önemli etkilere neden olabilir.
                      </li>
                      <li>
                        Yiyeceklere karşı çok kısıtlayıcı bir tutum sergilemek
                        doğru değildir. Tatlı ve tuzlu atıştırmalıklara zaman
                        zaman izin verilir.... Aslında, bunlar genellikle düşük
                        kalorili diyetlerle baş etmede değerli bir yardımcıdır.
                      </li>
                      <li>
                        Takviyeleri kendi inisiyatifinizle almak iyi bir fikir
                        değildir! Takviyeler sağlıklı ve dengeli bir beslenmenin
                        yerini almaz, ancak normal bir beslenmeyi
                        tamamlayabilirler. Besin ögesi ihtiyaçlarını her zaman
                        besin yoluyla karşılamak ana yol olmalıdır.
                      </li>
                    </ul>
                  </p>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic13;
